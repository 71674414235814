import "./TextArea.sass";

const TextArea = ({label, height = 126, ...otherProps}) => {
    return (
        <label className="text-area-label">
            <textarea
                className="text-area"
                style={{ height: `${height}px`, width: `${100}%` }}
                {...otherProps}
            />
            <span>{label || "Text Area"}</span>
        </label>
    );
};

export default TextArea;
