import { ApiService } from "../../utils/ApiService";
import {
    AUTH_FAIL,
    AUTH_START,
    AUTH_SUCCESS,
    ROLE_SUCCESS,
    LOGOUT,
} from "../actionTypes/authActionTypes";
import {errorToast} from "../../utils/toast";
import {useNavigate} from "react-router-dom";

export const authAction = (url, body) => (dispatch) => {
    dispatch(authActionStart());
    return ApiService.postEvent(url, null, body)
        .then((response) => {
            if (response?.code === "USER_AUTHORIZED_SUCCESSFULLY") {
                dispatch(authActionSuccess(response.data));
                window.location.replace("/");
                dispatch(roleAction("user"));
            } else if (
                (response?.code === "CLIENT_CREATED_SUCCESSFULLY") |
                (response?.code === "CLIENT_AUTHORIZED_SUCCESSFULLY")
            ) {
                dispatch(authActionSuccess(response.data));
                window.location.replace("/");
                dispatch(roleAction("client"));
            }
        })
        .catch((error) => {
            errorToast(error.message);
            dispatch(authActionFail(error.message));
        });
};

export const roleAction = (data) => ({
    type: ROLE_SUCCESS,
    payload: data,
});

export const authActionSuccess = (data) => ({
    type: AUTH_SUCCESS,
    payload: data,
});

export const authActionStart = () => {
    return {
        type: AUTH_START,
    };
};

export const logoutAction = () => {
    return {
        type: LOGOUT,
    };
};

export const authActionFail = (error = null) => {
    return {
        type: AUTH_FAIL,
        payload: error,
    };
};
