import StatusTag from "./components/StatusTag";
import CustomPagination from "./components/Pagination";
import React from "react";

const API_HOST = process.env.REACT_APP_API_HOST || "http://localhost:4000";
const API_VERSION = "/v1";
const API_BASE_URL = API_HOST + API_VERSION;
export const DEFAULT_LANGUAGE = "en";
const TABLE_CONFIG = {
    sx: {
        height: "90vh",
        minHeight: "350px",
    },
    components: {
        UITable: <StatusTag />,
        Pagination: CustomPagination,
    },
    checkboxSelection: false,
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    paginationMode: "server",
};

export default { API_BASE_URL, API_HOST, TABLE_CONFIG };
