import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import { DataGrid } from "@mui/x-data-grid";
import { Box, colors, Typography } from "@mui/material";
import { ApiService } from "../../../utils/ApiService";
import {errorToast, successToast} from "../../../utils/toast";
import { updateTotalAction } from "../../../redux/reducers/paginationControllerReducer";

import Modal from "react-modal";
import ModalWindow from "../../../components/ModalWindow";
import StatusTag from "../../../components/StatusTag";
import Action from "../../../components/Action/index.jsx";
import BreadCrumbs from "../../../components/BreadCrumbs/index.jsx";
import Filter from "../../../components/Filter/index.jsx";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import Preloader from "../../../components/Preloader";

import "../../../assets/fonts/epos-icons/style.css";
import "./styles.sass";

Modal.setAppElement("#root");

const PageCategoryTable = () => {
    const filters = {
        isCategoriesCategory: true,
        isStatus: true,
    };
    const navigate = useNavigate();
    const { state } = useLocation();
    const { businessID } = useParams();
    const { token } = useSelector((state) => state.authReducer);
    const dispatch = useDispatch();
    const controller = useSelector(
        (state) => state.paginationControllerReducer,
    );

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [deleteManyModalIsOpen, setDeleteManyModalIsOpen] = useState(false);
    const [id, setId] = useState();
    const [searchInputValue, setSearchInputValue] = useState("");
    const [checkedCategories, setCheckedCategories] = useState([]);
    const storedUserData = useSelector((state) => state.authReducer);
    const isClient = storedUserData.role === "client";

    let urlBase = `${config.API_BASE_URL}/businesses/${
      state?.business._id || businessID
    }/product-categories?`;
    const [url, setUrl] = useState(urlBase);

    const { data, loading, error } = useApiHook(url);

    const category = data?.data?.docs;

    useEffect(() => {
        if(searchInputValue)
            setUrl(`${config.API_BASE_URL}/businesses/${
              state?.business._id || businessID
            }/product-categories/search/category?query=${searchInputValue}`);
        else setUrl(urlBase);
    }, [searchInputValue]);

    useEffect(() => {
        if (data) {
            dispatch(updateTotalAction(parseInt(data?.data?.totalDocs) || 0));
        }
    }, [data]);
    useEffect(() => {
        setUrl(
            `${urlBase}&page=${controller.page + 1}&limit=${
                controller.pageSize
            }`,
        );
    }, [controller.page, controller.pageSize]);
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error}</div>;
    let categories = [];

    if (category)
        category?.map((item) => {
            let category = {
                title: item.title?.ru || "—",
                description: item.description?.ru || "—",
                parentCategory: item.parentCategory
                    ? item.parentCategory.title?.ru
                    : "-",
                childrenNumber: item.childCategories?.length || 0,
                productsNumber: item.products?.length || 0,
                id: item._id,
                slug: item.slug || "",
                status: item.status,
            };
            categories.push(category);
        });

    const openModals = (id) => {
        setModalIsOpen(true);
        setId(id);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const openDeleteManyModal = () => {
        if (checkedCategories.length > 0)
            setDeleteManyModalIsOpen(true);
        else
            errorToast(__("Please check some category to delete"));
    };

    const closeDeleteManyModal = () => {
        setDeleteManyModalIsOpen(false);
    };

    const removeCategory = () => {
        ApiService.deleteEvent(`/businesses/${state?.business?._id || businessID}/product-categories/${id}`, token)
            .then((res) => {
                if (res.status === 200) {
                    window.location.reload();
                }
            })
            .catch((error) => {
                errorToast(error);
            });
    };

    const handleDeleteCategories = () => {
        const idsArr = checkedCategories.map(category => ({
            _id: category.id
        }));

        axios({
            method: "post",
            url: `${config.API_BASE_URL}/businesses/${state.business._id || businessID}/product-categories/delete/all`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            data: idsArr
        }).then(() => {
            successToast(__("Successfully deleted"));
            window.location.reload();
        })
          .catch((error) => {
              errorToast(error.message);
          });
    };

    const buttons = [
        {
            text: __("Delete"),
            onClick: openDeleteManyModal,
            classNames: "outline-error"
        },
        {
            text: __("Add Product Category"),
            link: `/businesses/${
              state?.business.slug ||
              state?.business._id ||
              businessID
            }/categories/create`,
            state: {
                business: state?.business,
                categories: data?.data?.docs,
            },
            classNames: "outline",
        }
    ];

    const columns = [
        {
            field: "title",
            headerName: __("Title"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                        onClick={() => {
                            navigate(
                                `/businesses/${
                                    state?.business?.slug ||
                                    state?.business?._id ||
                                    businessID
                                }/categories/${row.slug || row.id}`,
                                {
                                    state: {
                                        business: {
                                            id:
                                                state?.business?._id ||
                                                businessID,
                                            slug:
                                                state?.business?.slug ||
                                                businessID,
                                        },
                                        category: {
                                            id: row.id,
                                            slug: row.slug,
                                        },
                                    },
                                },
                            );
                        }}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={600}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {row.title}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "description",
            headerName: __("Description"),
            className: "table-phone",
            flex: 1,
            renderCell: ({ row: { description } }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.grey[500]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {description}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "parentCategory",
            headerName: __("Parent Category"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={600}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {row.parentCategory}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "productsNumber",
            headerName: __("Number of Products"),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={600}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {row.productsNumber}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "childrenNumber",
            headerName: __("Child Categories"),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {row.childrenNumber}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: __("Status"),
            type: "string",
            flex: 1,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <StatusTag
                            className={`ui-status sm ${
                                props.value === "blocked"
                                    ? "question"
                                    : "active"
                            }`}
                            text={props.value}
                        />
                    </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Action
                            onClick={() =>
                                navigate(
                                    `/businesses/${
                                        state?.business?.slug ||
                                        state?.business?._id ||
                                        businessID
                                    }/categories/${row.slug || row.id}/edit`,
                                    {
                                        state: {
                                            category: {
                                                id: row.id,
                                                slug: row.slug,
                                            },
                                            categories: data?.data?.docs,
                                            business: {
                                                id:
                                                    state?.business._id ||
                                                    businessID,
                                                slug:
                                                    state?.business.slug ||
                                                    businessID,
                                            },
                                        },
                                    },
                                )
                            }
                            onClickView={() =>
                                navigate(
                                    `/businesses/${
                                        state?.business?.slug ||
                                        state?.business?._id ||
                                        businessID
                                    }/categories/${row.slug || row.id}`,
                                    {
                                        state: {
                                            category: {
                                                id: row.id,
                                                slug: row.slug,
                                            },
                                            categories: data?.data?.docs,
                                            business: {
                                                id:
                                                    state?.business._id ||
                                                    businessID,
                                                slug:
                                                    state?.business.slug ||
                                                    businessID,
                                            },
                                        },
                                    },
                                )
                            }
                            openModal={() => openModals(row?.id)}
                        />
                    </Box>
                );
            },
        },
    ];

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Businesses", link: "/businesses" },
                    { label: "Categories" },
                ]}
            />
            <div className={`page page-table-branch ${isClient ? "grid" : ""}`}>
                <Box>
                    <Filter
                        buttons={buttons}
                        description={__("Categories")}
                        filters={filters}
                        search={searchInputValue}
                        setSearch={setSearchInputValue}
                    />
                    <Box
                        sx={{
                            "&": {
                                margin: "0",
                                height: "90vh",
                                minHeight: "350px",
                            },
                            "& .name-column--cell": {
                                color: colors.green[300],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                            },
                        }}
                    >
                        <DataGrid
                            rows={categories}
                            columns={columns}
                            rowCount={controller.total}
                            page={controller.page}
                            pageSize={controller.pageSize}
                            {...config.TABLE_CONFIG}
                            checkboxSelection
                            onSelectionModelChange={(ids) => {
                                const selectedIDs = new Set(ids);
                                const selectedRowData = categories.filter((row) => selectedIDs.has(row.id.toString()));
                                setCheckedCategories(selectedRowData);
                            }}
                        />
                    </Box>
                </Box>
                <Modal
                    className="delete-modal"
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                >
                    <div className="modal-bottom">
                        <ModalWindow
                            title={__("Are you sure?")}
                            text={__(
                              "You are trying to delete a Products Category. When deleting a category all products " +
                              "within will be permanently deleted too. Are you sure you want to proceed?",
                            )}
                            onClick={closeModal}
                            onClickDelete={removeCategory}
                        />
                    </div>
                </Modal>
                <Modal
                  className="delete-modal"
                  isOpen={deleteManyModalIsOpen}
                  onRequestClose={closeDeleteManyModal}
                >
                    <div className="modal-bottom">
                        <ModalWindow
                          title={__("Are you sure?")}
                          text={__(
                            "You are trying to delete a Products Category. When deleting a category all products " +
                            "within will be permanently deleted too. Are you sure you want to proceed?",
                          )}
                          onClick={closeDeleteManyModal}
                          onClickDelete={handleDeleteCategories}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default PageCategoryTable;
