import { useEffect, useState } from "react";
import CheckBox from "../../../CheckBox";
import { connect, useDispatch, useSelector } from "react-redux";
import { setCheckedAC, setUncheckedAC, setPriceRangeAC, setExpirationAC, setStatusesAC, setUserRolesAC } from "../../../../redux/actions/filtersAction";
import { IMaskInput } from "react-imask";
import { NumericFormat } from "react-number-format";
import { translate } from "../../../../translations/translate";
import useApiHook from "../../../../hooks/useApiHook";
import moment from "moment";
import config from "../../../../config";
import "./styles.sass";

const FilterOptions = (props) => {
    const dispatch = useDispatch();
    const rolesUrl = props?.page === "users" ? `${config.API_BASE_URL}/users-roles` : null;
    // Construct the appropriate URL based on props.page
    const statusesUrl = props?.page
    ? props?.page === "businesses" || props?.page === "branches"
        ? `${config.API_BASE_URL}/statuses/${props?.page?.slice(0, -2)}`
        : props?.page === "fiscal"
        ? `${config.API_BASE_URL}/statuses/fiscal`
        : `${config.API_BASE_URL}/statuses/${props?.page?.slice(0, -1)}`
    : "";

    const [error, setError] = useState({
        from: false,
        to: false
    });

    // Call the hook
    const { data: statuses } = useApiHook(statusesUrl);

    const {data: roles} = useApiHook(rolesUrl);

    useEffect(() => {
        if (statuses?.data?.length) {
            dispatch(props.setStatusesAC(statuses.data));
        }
    }, [statuses, dispatch]);

    useEffect(() => {
        if (roles?.data?.docs?.length) {
            dispatch(props.setUserRolesAC(roles.data.docs));
        }
    }, [roles, dispatch]);

    // Language
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const validateDate = (inputDate) => {
        // Regular expression to validate the date format as YYYY-MM-DD
        const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
        return !regex.test(inputDate) ||    // If the date format is incorrect
            !moment(inputDate, "YYYY-MM-DD", true).isValid() ||     // If the date is not a valid calendar date
            moment(inputDate, "YYYY-MM-DD").isBefore(moment("2020-01-01", "YYYY-MM-DD")); // If the date is before 01-01-2020
    };

    const handleDateChange = (type, inputDate) => {
        // condition when user clear date input
        if (inputDate.length === 0) {
            setError((prevState) => ({ ...prevState, [type]: false }));
            if (type === "from") {
                dispatch(props.setExpirationAC("", props.state.expiresAt.to));
            } else {
                dispatch(props.setExpirationAC(props.state.expiresAt.from, ""));
            }
            return;
        }
        // validations related date input
        const isError = validateDate(inputDate);
        setError((prevState) => ({ ...prevState, [type]: isError }));
        if (!isError) {
            const { from, to } = props.state.expiresAt;
            if (type === "from") {
                dispatch(props.setExpirationAC(inputDate, to));
            } else {
                dispatch(props.setExpirationAC(from, inputDate));
            }
        }
    };

    const dateMask = [
        {
            mask: "0000-00-00",
        },
    ];

    const selectAll = function () {
        props.state[props.filter]
            .filter(filter => filter.isChecked === true || filter.isChecked === false)
            .forEach(filter => {
                props.setCheckedAC(props.filter, filter.name);
            });
    };

    const clearAll = function () {
        props.state[props.filter]
            .filter(filter => filter.isChecked === true || filter.isChecked === false)
            .forEach(filter => {
                props.setUncheckedAC(props.filter, filter.name);
            });
    };

    if (props.filter === "expiresAt" || props.filter === "priceRange") {
        return (
            <div className="layout__column">
                {
                    props.filter === "expiresAt"
                        ? (
                            <div className="layout__3">
                                <IMaskInput
                                    className={`input-filter ${error.from ? "error" : ""}`}
                                    mask={dateMask}
                                    placeholder="2023-01-14"
                                    onAccept={(value) => {
                                        handleDateChange("from", value);
                                    }}
                                />
                                <p>-</p>
                                <IMaskInput
                                    className={`input-filter ${error.to ? "error" : ""}`}
                                    mask={dateMask}
                                    placeholder="2024-02-29"
                                    onChange={(e) => handleDateChange("to", e.target.value)}
                                />
                            </div>
                        )
                        : (
                            <div className="layout__3">
                                <NumericFormat
                                    value={props?.state?.priceRange?.from || null}
                                    className={"input-filter"}
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        dispatch(props.setPriceRangeAC(+value.slice(0, value.indexOf(".")), props.state.priceRange.to));
                                    }}
                                    thousandSeparator={" "}
                                    decimalSeparator={","}
                                    inputMode={"numeric"}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                />
                                <p>-</p>
                                <NumericFormat
                                    value={props?.state?.priceRange?.to || null}
                                    className={"input-filter"}
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        dispatch(props.setPriceRangeAC(props.state.priceRange.from, +value.slice(0, value.indexOf("."))));
                                    }}
                                    thousandSeparator={" "}
                                    decimalSeparator={","}
                                    inputMode={"numeric"}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                />
                            </div>
                        )
                }

            </div>
        );
    }

    return (
        <div className="layout__column">
            <div className="layout__2 with__border">
                <p className="text_filter" onClick={selectAll}>{__("Select All")}</p>
                <p className="text_filter" onClick={clearAll}>{__("Clear All")}</p>
            </div>
            {props.state[props.filter]?.some(filter => filter.isChecked) && (
                <div className="layout__column layout__column-options with__border">
                    {props.state[props.filter].filter(filter => filter.isChecked).map((filter, i) => {
                        return (
                            <CheckBox
                                onChange={() => {
                                    props.setUncheckedAC(props.filter, filter.name);
                                }}
                                key={`option-checked-${props.filter}-${filter.name}-${i}`}
                                htmlFor={`option-checked-${props.filter}-${filter.name}-${i}`}
                                inputType="checkbox"
                                id={`option-checked-${props.filter}-${filter.name}-${i}`}
                                name={props.filter}
                                value={filter.name}
                                titleClass="checkbox-title"
                                defaultChecked={filter.isChecked}
                                title={__(filter.name)}
                                className={"with__margin"}
                            />
                        );
                    })}
                </div>
            )}
            {props.state[props.filter]?.some(filter => !filter.isChecked) && (
                <div className="layout__column layout__column-options">
                    {props.state[props.filter].filter(filter => !filter.isChecked).map((filter, i) => {
                        return (
                            <CheckBox
                                onChange={() => {
                                    props.setCheckedAC(props.filter, filter.name);
                                }}
                                key={`option-unchecked-${props.filter}-${filter.name}-${i}`}
                                htmlFor={`option-unchecked-${props.filter}-${filter.name}-${i}`}
                                inputType="checkbox"
                                titleClass="checkbox-title"
                                id={`option-unchecked-${props.filter}-${filter.name}-${i}`}
                                name={props.filter}
                                value={filter.name}
                                defaultChecked={filter.isChecked}
                                title={__(filter.name)}
                                className={"with__margin"}
                            />
                        );
                    })}
                </div>
            )}

        </div>
    );
};
const mapStateToProps = (state) => {
    return (
        {
            state: state.filter
        }
    );
};
export default
connect(mapStateToProps, { setCheckedAC, setUncheckedAC, setPriceRangeAC, setExpirationAC, setStatusesAC, setUserRolesAC })
(FilterOptions);
