const UPDATE_PAGE = "UPDATE_PAGE";
const UPDATE_TOTAL = "UPDATE_TOTAL";
const UPDATE_PAGE_SIZE = "UPDATE_PAGE_SIZE";
const RESET_CONTROLLER = "RESET_CONTROLLER";
const UPDATE_IS_LOADING = "UPDATE_IS_LOADING";

const defaultState = {
    isLoading: false,
    total: 0,
    page: 0,
    pageSize: 10,
};

export function paginationControllerReducer(state = defaultState, action) {
    switch (action.type) {
        case UPDATE_PAGE:
            return { ...state, page: action.payload };

        case UPDATE_TOTAL:
            return { ...state, total: action.payload };

        case UPDATE_PAGE_SIZE:
            return { ...state, pageSize: action.payload };

        case UPDATE_IS_LOADING:
            return { ...state, isLoading: action.payload };

        case RESET_CONTROLLER:
            return defaultState;

        default:
            return state;
    }
}

export const updatePageAction = (payload) => ({ type: UPDATE_PAGE, payload });

export const updateTotalAction = (payload) => ({ type: UPDATE_TOTAL, payload });

export const updatePageSizeAction = (payload) => ({
    type: UPDATE_PAGE_SIZE,
    payload,
});

export const updateIsLoadingAction = (payload) => ({
    type: UPDATE_IS_LOADING,
    payload,
});

export const resetControllerAction = () => ({ type: RESET_CONTROLLER });
