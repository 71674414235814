import config from "../config";
import { translate } from "../translations/translate";

export const translateAllTables = function(language) {

    const __ = (text) => translate(language, text);

    // ADDING LOCALE TEXT TO TABLE CONFIG TO TRANSLATE "NO ROWS"
    config.TABLE_CONFIG.localeText = {
        noRowsLabel: __("No rows"), 
    };
};