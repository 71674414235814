import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  GridFooterContainer,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import TablePagination from "@mui/material/TablePagination";
import { updatePageAction, updatePageSizeAction, resetControllerAction } from "../../redux/reducers/paginationControllerReducer";
import "./styles.sass";
import { translate } from "../../translations/translate";


const CustomPagination = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const controller = useSelector(state => state.paginationControllerReducer);

  useEffect(() => {
    dispatch(resetControllerAction());

    return () => {
      dispatch(resetControllerAction());
    };
  }, [location]);

  const lang = useSelector((state) => state.languageReducer.lang);
  const __ = (key) => translate(lang, key);

  return (
    <GridFooterContainer style={{ flexDirection: "row-reverse" }} >
      <Pagination
        shape="rounded"
        count={pageCount}
        page={controller.page + 1}
        onChange={(e, value) => dispatch(updatePageAction(value - 1))}
      />
      {/* <GridPagination
          rowsperpagetext= {__("Rows per page")}
          onRowsPerPageChange={
          e => dispatch(updatePageSizeAction(e.target.value))
        }
      /> */}
      <TablePagination
          component="div"
          count={controller.total}
          page={controller.page}
          onPageChange={(e, value) => dispatch(updatePageAction(value))}
          rowsPerPage={controller.pageSize}
          onRowsPerPageChange={(e) => dispatch(updatePageSizeAction(e.target.value))}
          labelRowsPerPage={__("Rows per page")}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
        />
    </GridFooterContainer>
  );
};

export default CustomPagination;
