import React, {useState} from "react";
import PageHeader from "../../../components/PageHeader";
import avatar from "../../../assets/images/avatar.png";
import {useSelector} from "react-redux";
import {translate} from "../../../translations/translate";
import {Box, colors, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import StatusTag from "../../../components/StatusTag";
import "../../../assets/fonts/epos-icons/style.css";
import "./styles.sass";
import config from "../../../config";
import useApiHook from "../../../hooks/useApiHook";
import Preloader from "../../../components/Preloader";
import {getParsedDate} from "../../../utils/getParsedDate";
import {NumericFormat} from "react-number-format";
import UserLinkCard from "../../../components/UserLinkCard";
import QRCodeGenerator from "../../../components/GenQR/gen-qr";
import {errorToast} from "../../../utils/toast";

const PageChequeView = ({...props}) => {
    const { token } = useSelector((state) => state.authReducer);
    const lang = useSelector(state => state.languageReducer.lang);
    const __ = key => translate(lang, key);
    const params = useParams();
    const navigate = useNavigate();
    const {state} = useLocation();
    const businessID = params.id;
    let receipt, products = [], children = [], base = [];
    const url = `${config.API_BASE_URL}/businesses/${businessID}/cheques/${state?.receipt?._id || params.receiptID}`;
    const {data, loading, error} = useApiHook(url);

    const [isExporting, setIsExporting] = useState(false);

    if (data?.data?.docs)
        receipt = data.data.docs[0];

    if (receipt?.cart?.products)
        receipt.cart?.products.map((item) => {
            let product = {
                id: item._id,
                title: item.productID?.title?.ru || "—",
                icps: item.productID?.productTasnif?.icps || "-",
                price: item?.price / 100,
                discount: item?.discount / 100,
                quantity: item?.quantity,
                totalPrice: item?.totalPrice / 100,
            };
            products.push(product);
        });

    if (receipt?.children)
        receipt.children.map((item) => {
            let receiptSingle = {
                id: item._id,
                receiptNumber: item.chequeNumber + "-" + item.chequeFrom,
                status: item.status,
                cashdeskID: item.cashdeskID?._id,
                cashdeskUniqueID: item.cashdeskID?.uniqueID,
                branchID: item.cashdeskID?.branchID?._id,
                branchSlug: item.cashdeskID?.branchID?.slug,
                branchName: item.cashdeskID?.branchID?.name,
                businessID: item.businessID?._id,
                businessSlug: item.businessID?.slug,
                businessName: item.businessID?.name,
                ownerID: item.owner?._id,
                ownerName: item.owner?.name ? (receipt.owner?.name?.first + " " + receipt.owner?.name?.last[0] + ".") : "-",
                ownerSlug: item.owner?.slug,
                ownerAvatar: item.owner?.avatar?.url,
                totalAmount: item.received?.total,
                createdAt: getParsedDate(item.dates?.createdAt, true),
                shiftNumber: item.shiftNumber
            };
            children.push(receiptSingle);
        });

    if (receipt?.baseCheque) {
        receipt.baseCheque.map(temp => {
            let receiptSingle = {
                id: temp._id,
                receiptNumber: temp.chequeNumber,
                status: temp.status,
                cashdeskID: temp.cashdeskID?._id,
                cashdeskUniqueID: temp.cashdeskID?.uniqueID,
                branchID: temp.cashdeskID?.branchID?._id,
                branchSlug: temp.cashdeskID?.branchID?.slug,
                branchName: temp.cashdeskID?.branchID?.name,
                businessID: temp.businessID?._id,
                businessSlug: temp.businessID?.slug,
                businessName: temp.businessID?.name,
                ownerID: temp.owner?._id,
                ownerName: temp.owner?.name ? (temp.owner?.name?.first + " " + temp.owner?.name?.last[0] + ".") : "-",
                ownerSlug: temp.owner?.slug,
                ownerAvatar: temp.owner?.avatar?.url,
                totalAmount: temp.received?.total,
                createdAt: getParsedDate(temp.dates?.createdAt, true),
                shiftNumber: temp.shiftNumber
            };
            base.push(receiptSingle);
        });
    }

    const productColumns = [
        {
            field: "title",
            headerName: __("Title"),
            flex: 2,
            cellClassName: "mane-column--cell",
            renderCell: ({row}) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Link to={`/businesses/${businessID}/products/${row._id}`}>
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {row.title}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            field: "icps",
            headerName: __("ICPS"),
            flex: 2,
            cellClassName: "mane-column--cell",
            renderCell: ({row: {icps}}) => {
                return (
                    <Box display="flex" justifyContent="center">
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={400}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {icps}
                            </Typography>
                    </Box>
                );
            },
        },
        {
            field: "price",
            headerName: __("Price"),
            flex: 0,
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <NumericFormat
                            displayType="text"
                            thousandSeparator={" "}
                            decimalSeparator={","}
                            value={row.price}
                            decimalScale={2}
                            fixedDecimalScale={true}
                        />
                    </Box>
                );
            },
        },
        {
            field: "quantity",
            headerName: __("Quantity"),
            flex: 0,
            renderCell: ({ row: { quantity } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {quantity}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "discount",
            headerName: __("Discount"),
            flex: 0,
            renderCell: ({ row }) => {
                return ( row.discount !== 0 ?
                    (<Box display="flex" justifyContent="center">
                        <NumericFormat
                            displayType="text"
                            thousandSeparator={" "}
                            decimalSeparator={","}
                            value={row.discount}
                            decimalScale={2}
                            fixedDecimalScale={true}
                        />
                    </Box>) :
                        (<Box display="flex" justifyContent="center">
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={400}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                -
                            </Typography>
                        </Box>)

                );
            },
        },
        {
            field: "total",
            headerName: __("Total"),
            flex: 0,
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <NumericFormat
                            displayType="text"
                            thousandSeparator={" "}
                            decimalSeparator={","}
                            value={row.totalPrice}
                            decimalScale={2}
                            fixedDecimalScale={true}
                        />
                    </Box>
                );
            },
        }
    ];

    const childrenColumns = [
        {
            field: "receiptNumber",
            headerName: __("Receipt #"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    row.businessID && row.businessSlug ?
                        (<Box display="flex" justifyContent="center"
                        onClick={()=> navigate(`/businesses/${row.businessSlug}/receipts/${row.receiptNumber}`, {receipt: {_id: row.id}})}>
                            <Box>
                                <Typography
                                    fontFamily="Manrope, sans-serif"
                                    fontWeight={600}
                                    fontSize={14}
                                    color={colors.common["black"]}
                                >
                                    {"#" + row.receiptNumber  || "—"}
                                </Typography>
                            </Box>
                        </Box>)
                        :
                        (
                            <Box display="flex" justifyContent="center">
                                <Typography
                                    fontFamily="Manrope, sans-serif"
                                    fontWeight={400}
                                    fontSize={14}
                                    color={colors.common["black"]}
                                >
                                    {"#" + row.receiptNumber || "—"}
                                </Typography>
                            </Box>
                        )
                );
            },
        },
        {
            field: "shift",
            headerName: __("Shift"),
            flex: 1,
            renderCell: ({ row: { shiftNumber } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                        >
                            {shiftNumber || "—"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "ownerName",
            headerName: __("Cashier"),
            type: "string",
            flex: 1.9,
            renderCell: ({row}) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <UserLinkCard
                            onClick={() => {
                                navigate(`/clients/${row.ownerSlug || row.ownerID}`);
                            }}
                            avatar={avatar}
                            name={row.ownerName}
                        />
                    </Box>
                );
            },
        },
        {
            field: "createdAt",
            headerName: __("Created At"),
            flex: 1.5,
            renderCell: ({ row: { createdAt } }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.grey[500]}
                        >
                            {createdAt || "—"}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: __("Status"),
            type: "string",
            flex: 1.3,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <StatusTag
                            className={`ui-status sm ${
                                props.value === "blocked"
                                    ? "question"
                                    : "active"
                            }`}
                            text={props.value}
                        />
                    </Box>
                );
            },
        },
    ];

    const exportToPdf = async () => {
        setIsExporting(true);
        try {
            const response = await fetch(`${config.API_BASE_URL}/businesses/${receipt?.businessID?._id}/cheques/export/pdf/${receipt?._id}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error("Failed to download PDF");
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            // Создаем ссылку для скачивания файла
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Receipt.pdf");
            document.body.appendChild(link);
            link.click();
            // Очищаем созданный URL
            window.URL.revokeObjectURL(url);
        } catch (e) {
            errorToast(e);
            console.error(e);
        } finally {
            setIsExporting(false);
        }
    };

    if (loading) {
        return <div><Preloader/></div>;
    }

    if (error) {
        return <div>{error.message}</div>;
    }

    return (
        <div {...props} className="page component-page-cheque-view">
            <form className="page-inner">
                <PageHeader
                    name={__("Receipt #") + `${receipt?.chequeNumber}${receipt?.chequeFrom ? "-" + receipt?.chequeFrom : ""}`}
                    text={__("Back")} link="/cheque/table"
                    buttons={[{
                        name: __("Export to PDF"),
                        onClick: exportToPdf,
                        className: "outline",
                        disabled: isExporting
                    }]}
                />
                <div className="cards">
                    <div className="left-side-cards">
                        {products && products.length > 0 &&
                            <Box className={"table-card"}>
                                <div className="component-box-card">
                                    <div className="card-info">
                                        <div className="card-text">
                                            <p className="card-title">{__("Products")}</p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <Box
                                    m="40px 0 0"
                                    height="80vh"
                                    // minHeight="300px"
                                    sx={{
                                        "& .name-column--cell": {
                                            color: colors.green[300],
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            borderTop: "none",
                                        },
                                    }}
                                >
                                    <DataGrid
                                        rows={products}
                                        columns={productColumns}
                                        components={{
                                            UITable: <StatusTag />,
                                        }}
                                        checkboxSelection={false}
                                        rowsPerPageOptions={[
                                            5, 10, 20, 100,
                                        ]}
                                        paginationMode="client"
                                    />
                                </Box>
                            </Box>
                        }
                        {children && children.length > 0 &&
                            <Box className={"table-card"}>
                                <div className="component-box-card">
                                    <div className="card-info">
                                        {/* <div className="card-icon">*/}
                                        {/*    <i className="icon-document-file"/>*/}
                                        {/* </div>*/}
                                        <div className="card-text">
                                            <p className="card-title">{__("Child receipts")}</p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <Box
                                    sx={{
                                        "&": {
                                            margin: "40px 0 0",
                                            // height: "65vh",
                                            minHeight: "350px",
                                        },
                                        "& .name-column--cell": {
                                            color: colors.green[300],
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            borderTop: "none",
                                        },
                                    }}
                                >
                                    <DataGrid
                                        rows={children}
                                        columns={childrenColumns}
                                        {...config.TABLE_CONFIG}
                                        paginationMode="client"
                                    />
                                </Box>
                            </Box>
                        }
                        {base && base.length > 0 &&
                            <Box className={"table-card"}>
                                <div className="component-box-card">
                                    <div className="card-info">
                                        <div className="card-text">
                                            <p className="card-title">{__("Refunded receipt")}</p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <Box
                                    sx={{
                                        "&": {
                                            margin: "40px 0 0",
                                            // height: "65vh",
                                            height: "200px",
                                        },
                                        "& .name-column--cell": {
                                            color: colors.green[300],
                                        },
                                        "& .MuiDataGrid-footerContainer": {
                                            borderTop: "none",
                                        },
                                    }}
                                >
                                    <DataGrid
                                        rows={base}
                                        columns={childrenColumns}
                                        {...config.TABLE_CONFIG}
                                        paginationMode="client"
                                        sx={{
                                            "&": {
                                                minHeight: "200px",
                                                height: "200px",
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                        }
                    </div>
                    <div className="right-side-cards">
                        <div className="print-view-container">
                            <div className="print-view">
                                <div className="title-block">
                                    <Typography
                                        fontFamily="Manrope, sans-serif"
                                        fontWeight={700}
                                        fontSize={20}
                                        color={colors.common["black"]}
                                        textOverflow={"ellipsis"}
                                        overflow={"hidden"}
                                        whiteSpace={"nowrap"}
                                    >
                                        {receipt?.businessID?.name}
                                    </Typography>
                                </div>
                                <div className="subtitle-block">
                                    <Typography
                                        fontFamily="Manrope, sans-serif"
                                        fontWeight={500}
                                        fontSize={18}
                                        color={colors.common["black"]}
                                        textOverflow={"ellipsis"}
                                        overflow={"hidden"}
                                        whiteSpace={"nowrap"}
                                    >
                                        {
                                            receipt?.cashdeskID?.branchID?.address?.city + ", " +
                                            receipt?.cashdeskID?.branchID?.address?.street + ", " +
                                            receipt?.cashdeskID?.branchID?.address?.house
                                        }
                                    </Typography>
                                </div>
                                <div className="content-block">
                                    {receipt?.businessID?.tin &&
                                        <div className="item">
                                            <p className="text key w-700">STIR:</p>
                                            <p className="text value w-700">{receipt?.businessID?.tin}</p>
                                        </div>
                                    }
                                    {receipt?.businessID?.pinfl &&
                                        <div className="item">
                                            <p className="text key w-700">PINFL:</p>
                                            <p className="text value w-700">{receipt?.businessID?.pinfl}</p>
                                        </div>
                                    }
                                    <div className="item">
                                        <p className="text key">Sana:</p>
                                        <p className="text value">{getParsedDate(receipt?.dates?.createdAt)}</p>
                                    </div>
                                    <div className="item">
                                        <p className="text key">Vaqt:</p>
                                        <p className="text value">{getParsedDate(receipt?.dates?.createdAt, true, true)}</p>
                                    </div>
                                    <div className="item">
                                        <p className="text key">Chek raqami:</p>
                                        <p className="text value">{`#${receipt?.chequeNumber}`}</p>
                                    </div>
                                    <div className="item">
                                        <p className="text key">Kassir:</p>
                                        <p className="text value">{`${receipt?.owner?.name?.first[0]}. ${receipt?.owner?.name?.last}`}</p>
                                    </div>
                                    <div className="item">
                                        <p className="text key">Smena:</p>
                                        <p className="text value">{`#${receipt?.shiftNumber}`}</p>
                                    </div>

                                    <div className="item">
                                        <p className="text key w-700">JAMI:</p>
                                        <p className="text value w-700">{
                                            <NumericFormat
                                                displayType="text"
                                                thousandSeparator={" "}
                                                decimalSeparator={","}
                                                value={receipt?.baseCheque ? receipt?.cart?.refund?.total?.amount / 100 : receipt?.received?.total / 100}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        }
                                        </p>
                                    </div>
                                    <div className="item">
                                        <p className="text key">Sh.J. QQS {receipt?.cart?.VAT?.percentage}%:</p>
                                        <p className="text value">{
                                            <NumericFormat
                                                displayType="text"
                                                thousandSeparator={" "}
                                                decimalSeparator={","}
                                                value={receipt?.cart?.VAT?.amount / 100}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                            />
                                        }
                                        </p>
                                    </div>
                                    <div className="divider"></div>
                                    {!receipt.baseCheque &&
                                        <Box>
                                            <div className="item">
                                                <p className="text key w-700">To&#39;lov shakli:</p>
                                            </div>
                                            <div className="item">
                                                <p className="text key">Naqd:</p>
                                                <p className="text value">{
                                                    <NumericFormat
                                                        displayType="text"
                                                        thousandSeparator={" "}
                                                        decimalSeparator={","}
                                                        value={receipt?.received?.cash / 100}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                    />
                                                }
                                                </p>
                                            </div>
                                            <div className="item">
                                                <p className="text key">Karta:</p>
                                                <p className="text value">{
                                                    <NumericFormat
                                                        displayType="text"
                                                        thousandSeparator={" "}
                                                        decimalSeparator={","}
                                                        value={receipt?.received?.card / 100}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                    />
                                                }
                                                </p>
                                            </div>
                                            <div className="divider"></div>
                                        </Box>
                                    }
                                    <div className="item">
                                        <p className="text key w-700">Chek turi:</p>
                                        <p className="text value">{__(`${receipt?.status}`)}</p>
                                    </div>
                                    <div className="divider"></div>
                                    <div className="item">
                                        <p className="text key w-700">Fiscal malumot</p>
                                    </div>
                                    <div className="item">
                                        <p className="text key">Cashdesk S/N:</p>
                                        <p className="text value">{receipt?.cashdeskID?.uniqueID}</p>
                                    </div>
                                    <div className="item">
                                        <p className="text key">FM Raqami:</p>
                                        <p className="text value">{receipt?.fiscal?.moduleNumber}</p>
                                    </div>
                                    <div className="item">
                                        <p className="text key">Fiscal belgisi:</p>
                                        <p className="text value">
                                            {
                                                receipt?.fiscal?.sign
                                                    ? receipt.fiscal.sign.split("").toSpliced(4, 4, "****")
                                                    : ""
                                            }
                                        </p>
                                    </div>
                                    <div className="item">
                                        <p className="text key w-700"></p>
                                        <p className="text value w-700"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PageChequeView;
