import React, { useState } from "react";
import { useSelector, connect } from "react-redux";
import { translate } from "../../../translations/translate";
import Languages from "../../global/Languages";
import Profile from "../../Profile";
import "./styles.sass";
import Notification from "../../Notification";
import { setOpenAC, setClosedAC } from "../../../redux/actions/sidebarActions";

const HeaderNavigation = (props) => {
    const handleClick = () => {
        props.isOpen && props.setClosedAC();
        !props.isOpen && props.setOpenAC();
    };

    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const [notification, setNotification] = useState(false);
    const handleNotification = () => {
        setNotification(!notification);
    };
    document.body.addEventListener("click", (e) => {
        if (
            e.target.className != "component-notification-switcher-handler" &&
            notification
        )
            handleNotification();
    });
    return (
        <div className="component component-header-navigation">
            <div className="header-items">
                <div className="component-burger">
                    <div
                        className={
                            props.isOpen
                                ? "burger-active"
                                : "navigation-burger "
                        }
                        onClick={handleClick}
                    >
                        <span className="burger-item" />
                        <span className="burger-item" />
                        <span className="burger-item" />
                        <span className="burger-item" />
                    </div>
                </div>
            </div>
            <div className="header-items">
                <div className="header-question">
                    <button className="header-question-button">
                        <i className="icon-question" />
                    </button>
                </div>
                <Notification />
                <div className="header-lang">
                    <Languages />
                </div>
                <Profile />
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        isOpen: state.sidebar.isOpen,
    };
};
export default connect(mapStateToProps, { setOpenAC, setClosedAC })(
    HeaderNavigation,
);
