import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import langEn from "../../../assets/images/engLanguage.svg";
import langRu from "../../../assets/images/ruLanguage.svg";
import langUz from "../../../assets/images/uzbLanguage.svg";
import chevronDown from "../../../assets/images/arrowDown.svg";
import { changeLanguageAction } from "../../../redux/reducers/languageReducer";
import "./styles.sass";
import Preloader from "../../Preloader";

export var language;

const Languages = () => {
    const dispatch = useDispatch();
    const lan = useSelector(state => state.languageReducer.lang);
    language = lan;
    const [lang, setLang] = useState(false);

    const Languages = [
        {
            key: "en-EN",
            label: "English",
            icon: langEn,
        },
        {
            key: "ru-RU",
            label: "Русский",
            icon: langRu,
        },
        {
            key: "uz-UZ",
            label: "O`zbekcha",
            icon: langUz,
        },
    ];
    const [currentLanguage, setcurrentLanguage] = useState(false);

    const handleLanguageChange = (event) => {
        const newLanguage = Languages.find((language) => {
            return (
                language.key ===
                event.currentTarget.getAttribute("data-language")
            );
        });

        if(event.currentTarget.getAttribute("data-language") === "en-EN") {
            dispatch(changeLanguageAction("en"));
            localStorage.setItem("lang", "en");
        } 
        else if(event.currentTarget.getAttribute("data-language") === "ru-RU") {
            dispatch(changeLanguageAction("ru"));
            localStorage.setItem("lang", "ru");
        } 
        else {
            dispatch(changeLanguageAction("uz"));
            localStorage.setItem("lang", "uz");
        }
        
        setcurrentLanguage(newLanguage);
    };

    const handleLang = () => {
        setLang(!lang);
    };

    document.body.addEventListener("click", (e) => {
        if (e.target?.className != "component-switcher-handler" && lang)
            handleLang();
    });

    useEffect(() => {
        const lan = localStorage.getItem("lang");
        const current = Languages.find(lang => lang.key.slice(0, 2) === lan);
        if(!lan || lan === "false") {
            localStorage.setItem("lang", lang);
            setcurrentLanguage(Languages[0]);
        }
        else {
            dispatch(changeLanguageAction(lan));
            setcurrentLanguage(current);
        }
    }, []);
    if(!currentLanguage) return <Preloader />;
  return (
    <div>
        <div className="component-header-language">
            <div className="component-switcher">
                <div className="component-switcher-handler" onClick={handleLang}></div>
                <div className="component-items-bar">
                    <img src={currentLanguage.icon} alt={currentLanguage.label} />
                    <p className="component-item-name">{currentLanguage.label}</p>
                </div>
                <div className="lang-icon">
                    <i className="icon-chevron"></i>
                </div>
                {lang ? (
                    <div className="component-switcher-items">
                        {Languages.map((language) => (
                            <div className="switcher-bar" key={language.key}>
                                {currentLanguage.key !== language.key && (
                                    <div
                                        key={language.key}
                                        className="component-items-bar"
                                        onClick={handleLanguageChange}
                                        data-language={language.key}
                                    >
                                        <img
                                            src={language.icon}
                                            alt={language.label}
                                        />
                                        <p className="component-item-name">
                                            {language.label}
                                        </p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </div>
    </div>
    );
};

export default Languages;
