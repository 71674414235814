import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { IMaskInput } from "react-imask";
import { translate } from "../../../translations/translate";
import Button from "../../../components/global/Button";
import Header from "../../../components/LayoutBasic/Header";
import "./styles.sass";
import { ApiService } from "../../../utils/ApiService";
import { successToast } from "../../../utils/toast";

const PageResetPassword = ({ ...props }) => {
    const PhoneMask = "+998(00)000-00-00";
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    // USE STATE
    const [phone, setPhone] = useState("");

    // GETTING STATE FROM LINK
    const { state } = useLocation();

    // ROLE REQUIRED TO BE RESET
    const role = state.authLink.split("/")[1];

    // USE NAVIGATE
    const navigate = useNavigate();

    // Function to check if the phone number is valid
    const isPhoneValid = (phone) => {
        return phone.replace(/[^+\d]/g, "").length >= 12; // Adjust the length as needed
    };

    // CALLBACK FOR SENDING REQUEST FOR OTP
    const submitPhoneNumber = function (event) {
        event.preventDefault();
        if (!isPhoneValid(phone)) {
            // If the phone number is not valid, don't submit the request
            return;
        }
        const body = {
            phone: phone.replace(/[^+\d]/g, ""),
        };
        ApiService.patchEvent(`/${role}/password/recover`, null, body)
            .then((response) => {
                if (response.status === 200) {
                    successToast(__("Code sent to your phone number"));
                    navigate("/auth/newPassword", {
                        state: {
                            phone,
                            verificationToken: response.data.verificationToken,
                            role,
                        },
                    });
                }
            });
    };

    const phoneEmailMask = [
        {
            mask: PhoneMask,
        },
    ];
    return (
        <div {...props} className="page page-authorization-reset-password">
            <Header />
            <div className="component-card-reset-password">
                <div className="card-inner">
                    <h1 className="component-heading">{__("Reset Your Password")}</h1>
                    <div className="component-card-text">
                        <p className="component-text">{__("Enter your phone number and we will send you temporary code to reset your password")}</p>
                    </div>
                    <form className="card-form" action="">
                        <div className="inputs-block">
                            <IMaskInput
                                autoComplete="off"
                                className="mask-on-input card-input"
                                htmlFor="phone"
                                id="phone"
                                type="phone"
                                placeholder="+(998)99 222 33 44"
                                mask={phoneEmailMask}
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                        <Button
                            className="btn"
                            text={__("Receive code")}
                            onClick={submitPhoneNumber}
                            // Disable the button if the phone is not valid
                            disabled={!isPhoneValid(phone)}
                        />
                    </form>
                    {/* <div className="reset-forgot">
                        <Link className="reset-link" to="/">{__("Forgot your number or lost it?")}</Link>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default PageResetPassword;
