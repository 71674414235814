import {
  UPDATE_PERMITTED_CLIENTS,
  UPDATE_PERMITTED_BUSINESSES,
  REMOVE_PERMITTED_CLIENTS,
  REMOVE_PERMITTED_BUSINESSES, EMPTY_DEALER_REDUCER
} from "../actionTypes/dealerActionTypes";

const INITIAL_STATE  = {
  permittedClients: [],
  permittedBusinesses: [],
};

const isObjectExists = (arr, payload) => {
  return arr.some(item => item._id === payload._id);
};

const removeArrItem = (arr, id) => {
  return arr.filter(item => item._id !== id);
};

export const dealerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_PERMITTED_CLIENTS:
        return { ...state, permittedClients: [...state.permittedClients, action.payload] };

    case UPDATE_PERMITTED_BUSINESSES:
        return { ...state, permittedBusinesses: [...state.permittedBusinesses, action.payload] };

    case REMOVE_PERMITTED_CLIENTS:
      return { ...state, permittedClients: removeArrItem(state.permittedClients, action.payload) };

    case REMOVE_PERMITTED_BUSINESSES:
      return { ...state, permittedBusinesses: removeArrItem(state.permittedBusinesses, action.payload) };

    case EMPTY_DEALER_REDUCER:
      return INITIAL_STATE;

    default:
      return state;
  }
};