import React, { useState } from "react";
import avatar from "../../assets/images/avatar.png";
import Button from "../global/Button";
import "./styles.sass";
import { useSelector } from "react-redux";
import { translate } from "../../translations/translate";

const Notification = () => {
    
    // Language
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const [notification, setNotification] = useState(false);
    const handleNotification = () => {
        setNotification(!notification);
    };
    document.body.addEventListener("click", (e) => {
        if (e.target.className != "notifications-handler" && notification)
            handleNotification();
    });

    return (
        <div className="component-notification">
            <div className="notifications">
                <div
                    className="notifications-handler"
                    onClick={handleNotification}
                />
                <button className="notification-btn">
                    <i className="icon-notification" />
                    <span className="notifications-number">5</span>
                </button>
                {notification ? (
                    <div className="notifications-block">
                        <div className="notifications-header">
                            <div className="header-title">
                                <h6 className="title">{__("Notifications")}</h6>
                            </div>
                        </div>
                        <div className="notifications-list">
                            <div className="notification">
                                <div className="notification-body">
                                    <div className="image-container">
                                        <img
                                            className="img"
                                            src={avatar}
                                            alt="Notification image"
                                        />
                                    </div>
                                    <div className="notification-content">
                                        <div className="notification-header">
                                            <p className="heading">
                                                <a className="link" href="#">
                                                    Gulsanam
                                                </a>{" "}
                                                added file to{" "}
                                                <a className="link" href="#">
                                                    Business Documents
                                                </a>
                                            </p>
                                        </div>
                                        <div className="notification-info">
                                            <p className="info">5 {__("min ago")}</p>
                                            <p className="info">
                                                Business Documents
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="notification-text">
                                    <p className="text">
                                        Hello! My name is Gulsanam and recently
                                        I have created an account for my
                                        business. I have several questions, so
                                        can...
                                    </p>
                                </div>
                                <div className="btns-container">
                                    <Button
                                        className="btn outline"
                                        text={__("View")}
                                    />
                                </div>
                            </div>
                            <div className="notification">
                                <div className="notification-body">
                                    <div className="image-container">
                                        <img
                                            className="img"
                                            src={avatar}
                                            alt="Notification image"
                                        />
                                    </div>
                                    <div className="notification-content">
                                        <div className="notification-header">
                                            <p className="heading">
                                                <a className="link" href="#">
                                                    Gulsanam
                                                </a>{" "}
                                                added file to{" "}
                                                <a className="link" href="#">
                                                    Business Documents
                                                </a>
                                            </p>
                                        </div>
                                        <div className="notification-info">
                                            <p className="info">5 {__("min ago")}</p>
                                            <p className="info">
                                                Business Documents
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="notification-text">
                                    <p className="text">
                                        Hello! My name is Gulsanam and recently
                                        I have created an account for my
                                        business. I have several questions, so
                                        can...
                                    </p>
                                </div>
                                <div className="btns-container">
                                    <Button
                                        className="btn outline"
                                        text={__("View")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {open ? <div></div> : <div></div>}
            </div>
        </div>
    );
};

export default Notification;
