import React from "react";
import PropTypes from "prop-types";
import "../../assets/fonts/epos-icons/style.css";
import "./styles.sass";

const Toggle = (props) => {
    return (
        <div className="component-toggle">
            <label className="toggle-field-label">
                <input
                    type={props.inputType}
                    name="radio"
                    className={props.inputClassName}
                    onChange={props.onChange}
                    defaultChecked={props.isChecked}
                />
                <span className={props.spanClassName}>
                    <i className={props.icon} />
                    <i className={props.iconSecond} />
                    <p className="toggle-status-approved">
                        {props.toggleApproved}
                    </p>
                    <p className={props.toggleDisabledClassName}>
                        {props.toggleNotApproved}
                    </p>
                </span>
            </label>
        </div>
    );
};
Toggle.propTypes = {
    spanClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    icon: PropTypes.string,
    iconSecond: PropTypes.string,
    toggleApproved: PropTypes.string,
    toggleNotApproved: PropTypes.string,
    toggleDisabledClassName: PropTypes.string,
    inputType: PropTypes.string,
};

export default Toggle;
