import React from "react";
import { useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import "./styles.sass";

const PageDashboard = () => {
    const { token } = useSelector((state) => state.authReducer);
    const lang = useSelector(state => state.languageReducer.lang);
    const __ = key => translate(lang, key);

    return (
        <div className="page page-dashboard">
            <div className="page-inner-content">{__("Page Dashboard")}</div>
        </div>
    );
};

export default PageDashboard;
