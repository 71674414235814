// Product Type
export const productTypes = [
  {label: "Product", value: "product"},
  {label: "Service", value: "service"},
];

// OWNER TYPE VALUES FOR SELECT COMPONENT
export const ownerTypeValues = [
  {label: "(zero) – 'buying and selling'", value: "0"},
  {label: "1 – (one) - 'self-made'", value: "1"},
  {label: "2 (two) – 'service provider'", value: "2"},
];

// Is marked values
export const isMarkedValues = [
  {label: "Required", value: "0"},
  {label: "Not required", value: "1"},
];

// INITIALIZING ARRAY
export const VATValues = [
  {label: "No VAT", value: 0},
  // {label: "0%", value: 0},
  {label: "12%", value: 12},
];