import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";

import Modal from "react-modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {Box} from "@mui/material";

import {NumericFormat} from "react-number-format";
import CustomInput from "../CustomInput";
import SearchResultCard from "../SearchCard";
import Button from "../global/Button";
import PageProductCreate from "../../pages/Product/Create";
import SelectComponent from "../Select";
import DragAndDrop from "../../pages/DragAndDrop";
import {isMarkedValues} from "../../utils/consts";
import {translate} from "../../translations/translate";
import {errorToast, successToast, warnToast} from "../../utils/toast";
import config from "../../config";
import "./BasicTable.sass";

Modal.setAppElement("#root");

const BasicTable = (props) => {
  const {
    tableTitles,
    productsArr,
    searchResult,
    isResultEmpty,
    handleOnChange,
    handleSelectProduct,
    handleSelectLabeling,
    removeRow,
    addRow,
    modalIsOpen,
    setModalIsOpen,
    setProductsArr,
    branchID
  } = props;

  const { state } = useLocation();
  const { token } = useSelector((state) => state.authReducer);
  // Language
  const lang = useSelector((state) => state.languageReducer.lang);
  const __ = (key) => translate(lang, key);

  // States
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [isDragNDropOpen, setIsDragNDropOpen] = useState(false);
  const [importIsLoading, setImportIsLoading] = useState(false);
  const [productImportFile, setProductImportFile] = useState(null);
  const [partiallyUpload, setPartiallyUpload] = useState(null);

  // Handlers
  const closeProductModal = () => {
    setModalIsOpen(false);
  };

  const openProductModal = () => {
    setModalIsOpen(true);
  };

  const toggleImportModal = () => {
    setImportModalOpen(prevState => !prevState);
  };

  const findCountInBranch = (product) => {
    // after filtering the array, immediately select the index 0 in the following
    const branch = product.stock?.filter(branch => branch?.branchID === branchID)[0];
    return branch ? branch?.count?.available : 0;
  };

  const parseFetchedData = (productsArray) => {
    return productsArray?.map(product => ({
      id: product._id,
      article: product.article,
      name: product.name,
      barCode: product?.barcode || "",
      labeling: product?.isMarked,
      purchasePrice: product?.purchasePrice / 100 || "",
      sellingPrice: product?.price / 100,
      inStock: product?.inStock,
      amount: product?.amount
    }));
  };

  const handleImportExcel = async () => {
    setImportIsLoading(true);
    try {
      const {data} = await axios.post(`${config.API_BASE_URL}/businesses/${state.business._id}/invoice/excel`, {
          file: productImportFile
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      const newProductsArr = parseFetchedData(data?.data?.foundProducts);
      setProductsArr(newProductsArr);

      if (data.data.failed === 0) {
        setIsDragNDropOpen(false);
        successToast(__("File was successfully uploaded"));
        return;
      }

      warnToast(__("File was partially uploaded"));
      setPartiallyUpload({
        notFoundProducts: data?.data?.notFoundProducts,
        total: data.data.total,
        successful: data.data.successful,
        failed: data?.data?.failed
      });
    } catch (e) {
      errorToast(
       __("An error occurred importing products. ") + e?.response?.data?.message,
      );
    } finally {
      setImportIsLoading(false);
    }
  };

  // Effects
  useEffect( () => {
    if (productImportFile) {
      handleImportExcel();
    }
  }, [productImportFile]);

  return (
    <TableContainer
      component={Paper}
      sx={{
          height: "80vh",
          width: "fit-content",
          minWidth: "1242px",
          background: "var(--basic-white)"
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {tableTitles?.map((title) => (
              <TableCell
                key={title}
                sx={{whiteSpace: "nowrap", fontFamily: "Manrope, sans-serif"}}
              >
                {__(title)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {productsArr?.map((row, idx) => (
            <TableRow
              key={idx}
            >
              <TableCell sx={{padding: "6px", fontFamily: "Manrope, sans-serif", borderColor: "var(--neutral-100)"}}>
                <div className="smart-search-container">
                  <CustomInput
                    value={row.search}
                    onChange={(e) => handleOnChange(idx, "search", e.target.value)}
                  />
                  {/* if there are some result */}
                  {!!searchResult[idx]?.length && row.search.length > 2 && (
                    <div className="smart-search-list wider">
                      <Box
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                      >
                        {searchResult[idx].map((result, index) => (
                          <SearchResultCard
                            key={index}
                            label={result.title?.uz}
                            onClick={() => handleSelectProduct(idx, result)}
                            classNames="with-padding bordered hoverable"
                          />
                        ))}
                      </Box>
                      <Button
                        onClick={openProductModal}
                        wrapperClassname="wrapper-absolute"
                        type="button"
                      >
                        {__("Add a new product")}
                      </Button>
                    </div>
                  )}
                  {/* if nothing found */}
                  {isResultEmpty[idx] && (
                    <div className="smart-search-list wider">
                      <Box
                        display="flex"
                        justifyContent="center"
                        padding="11px 12px"
                        fontSize={14}
                      >
                        {__("Nothing found")}
                      </Box>
                      <Button
                        onClick={openProductModal}
                        wrapperClassname="wrapper-absolute"
                        type="button"
                      >
                        {__("Add a new product")}
                      </Button>
                    </div>
                  )}
                </div>
              </TableCell>
              <TableCell sx={{padding: "6px", fontFamily: "Manrope, sans-serif", borderColor: "var(--neutral-100)"}}>
                <CustomInput
                  value={row.article}
                  disabled
                />
              </TableCell>
              <TableCell sx={{padding: "6px", fontFamily: "Manrope, sans-serif", borderColor: "var(--neutral-100)", width: 200}}>
                <CustomInput
                  value={row.name}
                  disabled
                />
              </TableCell>
              <TableCell sx={{padding: "6px", fontFamily: "Manrope, sans-serif", borderColor: "var(--neutral-100)"}}>
                <CustomInput
                  value={row.barCode}
                  onChange={(e) => handleOnChange(idx, "barCode", e.target.value)}
                  disabled
                />
              </TableCell>
              <TableCell sx={{padding: "6px", fontFamily: "Manrope, sans-serif", borderColor: "var(--neutral-100)"}}>
                <SelectComponent
                  value={row.labeling ? isMarkedValues[0] : isMarkedValues[1]}
                  onChange={(e) => handleSelectLabeling(idx, e.value)}
                  options={isMarkedValues}
                  translateLabel
                />
              </TableCell>
              <TableCell sx={{padding: "6px", fontFamily: "Manrope, sans-serif", borderColor: "var(--neutral-100)"}}>
                <NumericFormat
                  value={row.purchasePrice}
                  onValueChange={({floatValue}) => {
                    handleOnChange(idx, "purchasePrice", floatValue);
                  }}
                  customInput={CustomInput}
                  thousandSeparator=" "
                  decimalSeparator="."
                  decimalScale={2}
                  fixedDecimalScale
                />
              </TableCell>
              <TableCell sx={{padding: "6px", fontFamily: "Manrope, sans-serif", borderColor: "var(--neutral-100)"}}>
                <NumericFormat
                  value={row.sellingPrice}
                  onValueChange={({floatValue}) => handleOnChange(idx, "sellingPrice", floatValue)}
                  customInput={CustomInput}
                  thousandSeparator=" "
                  decimalSeparator="."
                  decimalScale={2}
                  fixedDecimalScale
                  required
                />
              </TableCell>
              <TableCell sx={{padding: "6px", fontFamily: "Manrope, sans-serif", borderColor: "var(--neutral-100)", width: 90}}>
                <CustomInput
                  value={branchID ? findCountInBranch(row) : row.inStock}
                  disabled
                />
              </TableCell>
              <TableCell sx={{padding: "6px", fontFamily: "Manrope, sans-serif", borderColor: "var(--neutral-100)", width: 90}}>
                <NumericFormat
                  value={row.amount}
                  onValueChange={({floatValue}) => handleOnChange(idx, "amount", floatValue)}
                  thousandSeparator=" "
                  customInput={CustomInput}
                  required
                />
              </TableCell>
              <TableCell sx={{padding: "6px", fontFamily: "Manrope, sans-serif", borderColor: "var(--neutral-100)"}}>
                <Button
                  type="button"
                  wrapperClassname="button-remove"
                  onClick={() => removeRow(idx)}
                  disabled={productsArr.length === 1}
                >
                  <i className="icon-delete"></i>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="basic-table__buttons">
        <Button
          onClick={toggleImportModal}
          className="btn"
          wrapperClassname="button-add-row"
          type="button"
        >
          {__("Import")}
        </Button>
        <Button
          onClick={addRow}
          className="btn"
          wrapperClassname="button-add-row"
          type="button"
        >
          {__("New Row")}
        </Button>
      </div>

      {/* Product create modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeProductModal}
        className="product-modal-body"
        overlayClassName="product-modal-overlay"
      >
        <PageProductCreate pageAsModal closeModal={closeProductModal} />
      </Modal>

      {/* Import Modal */}
      <Modal
        className="delete-modal"
        isOpen={importModalOpen}
        onRequestClose={toggleImportModal}
      >
        {/* TODO: Refactor this block and create separate component */}
        <div className="modal-bottom">
          <div className="component-modal-window">
            <div className="modal-inner">
              <div className="modal-title">
                <h1 className="modal-window-title">
                  {__("Import of the Product")}
                </h1>
              </div>
              <div
                className="close-icon"
                onClick={toggleImportModal}
              />
              <div className="modal-body">
                <div className="window-modal-text">
                  <p className="window-text">
                    {__(
                      "Download the example of the Excel file for understanding the format of data to be imported",
                    )}
                  </p>
                </div>
                <div className="modal-file">
                  <div className="file">
                    <span className="file-icon"></span>
                    <div className="file-text">
                      <p className="text">
                        Example.xlsx
                      </p>
                      <p className="text-size">
                        10KB
                      </p>
                    </div>
                  </div>

                  <a
                    href={`${config.API_HOST}/uploads/templates/ProductsImportTemplate.xlsx`}
                    download="sample.xlsx"
                    className="download-link"
                  >
                    <i className="icon-download"></i>
                  </a>
                </div>
              </div>
              <div className="btn-container">
                <Button
                  className="btn outline sm"
                  text={__("Cancel")}
                  onClick={toggleImportModal}
                ></Button>
                <Button
                  className="btn sm"
                  text={__("Continue")}
                  onClick={() => {
                    setImportModalOpen(false);
                    setIsDragNDropOpen(true);
                  }}
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {isDragNDropOpen && (
        <DragAndDrop
          setter={(importFile) => setProductImportFile(importFile)}
          closeDragNDrop={() => setIsDragNDropOpen(false)}
          isUpload={false}
          isXLS={true}
          isLoading={importIsLoading}
          partiallyUpload={partiallyUpload}
          setPartiallyUpload={setPartiallyUpload}
        />
      )}
    </TableContainer>
  );
};

export default BasicTable;
