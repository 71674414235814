import {ApiService} from "../ApiService";

export const searchStaff = async (businessID, query, token) => {
    try{
        const response = await ApiService.getResources(`/businesses/${businessID}/staff?value=${query}`, token);
        if (!response.data || !Array.isArray(response.data)) {
            return [];
        } else {
            return response.data;
        }
    }
    catch (e){
        return [];
    }
};