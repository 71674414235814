import { SET_THEME } from "../actionTypes/themeActionTypes";

const initialState = {
    theme: "light"
};
const themeReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_THEME:
            return {...state, theme: action.theme};
        default:
            return state;
    }
};
export default themeReducer;