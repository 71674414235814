import {ApiService} from "../ApiService";

export const searchBusinesses = async (query, token) => {
  try{
    const response = await ApiService.getResources(`/businesses/search/business?query=${query}`, token);
    if (!response.data?.docs || !Array.isArray(response.data?.docs)) {
      return [];
    } else {
      return response.data.docs;
    }
  }
  catch (e){
    return [];
  }
};