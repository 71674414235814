import React, {useState, useEffect} from "react";
import {IMaskInput} from "react-imask";
import {useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router";
import {translate} from "../../../translations/translate";
import {ApiService} from "../../../utils/ApiService";
import {errorToast, successToast} from "../../../utils/toast";

import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import UIAvatar from "../../../components/Avatar";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import Preloader from "../../../components/Preloader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import DragAndDrop from "../../DragAndDrop";

import "./styles.sass";
import BasicDatePicker from "../../../components/DatePicker";
import {StyledEngineProvider} from "@mui/styled-engine-sc";
import {formatDate} from "../../../utils/formatDate";
import {useFormatMask} from "../../../hooks/useFormatMask";
import {Box} from "@mui/material";
import UserLinkCard from "../../../components/UserLinkCard";
import {useDebounce} from "../../../hooks/useDebounce";
import {searchDealer} from "../../../utils/globalAPIRequests/searchDealer";
import {getTokenData} from "../../../utils/helper";
import { capitalize } from "../../../utils/capitalize";

const PageClientEdit = () => {
    const navigate = useNavigate();

    const PhoneMask = "+998 (00) 000-00-00";
    const phoneEmailMask = [
        {
            mask: PhoneMask,
        },
    ];
    // TODO: refactor functions, useEffects, states order
    const {state} = useLocation();
    const {id} = useParams();
    const url = `${config.API_BASE_URL}/clients/${state?.client?.id || id}`;
    const {data, loading, error} = useApiHook(url);
    const [birthDate, setBirthDate] = useState();
    const [equal, setEqual] = useState(true);
    const [clientInitial, setClientInitial] = useState({});
    const [clientCurrent, setClientCurrent] = useState({});
    const [avatarUrl, setAvatarUrl] = useState("");
    const [dealerSearch, setDealerSearch] = useState("");
    const [dealerSearchResult, setDealerSearchResult] = useState([]);
    const [dealer, setDealer] = useState(null);
    const [isDragNDropOpen, setIsDragNDropOpen] = useState(false);
    const [isPassportDragNDropOpen, setIsPassportDragNDropOpen] = useState(false);
    const [passport, setPassport] = useState(null);
    const dealerDebouncedSearch = useDebounce(dealerSearch);

    const handleDragNDropOpen = () => {
        setIsDragNDropOpen(!isDragNDropOpen);
    };

    const togglePassportDragNDrop = () => {
        setIsPassportDragNDropOpen((prevState) => !prevState);
    };

    const [_, setAvatar] = useState({});

    const handleSetAvatar = (url) => {
        setAvatar(url.url);
        setClientCurrent({
            ...clientCurrent,
            avatar: url.id,
        });
    };

    const handleSetPassport = (url) => {
        setPassport({
            name: url.name,
            id: url.id
        });
    };

    const {token} = useSelector((state) => state.authReducer);
    const decodedToken = getTokenData(token);
    const isDealer = decodedToken?.role?.title === "Dealer";
    const lan = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lan, key);
    const [lang] = useState("");

    const handleDealerSearch = (e) => {
        setDealerSearch(e.target.value);
    };

    const handleClickDealer = (dealer) => {
        setDealer(dealer);
        setDealerSearch("");
        setDealerSearchResult([]);
    };

    const removeDealerHandler = () => {
        ApiService.deleteEvent(`/users/${dealer?._id}/client/${state?.client?.id || id}`, token)
            .then((res) => {
                if (res.status === 200) {
                    successToast(__("Successfully updated"));
                    navigate(`/clients/${state?.client?.id || id}`);
                }
            })
            .catch((error) => {
                errorToast(error);
            });
    };

    useEffect(() => {
        if (dealerDebouncedSearch.length > 0) {
            (async () => {
                const result = await searchDealer(dealerDebouncedSearch, token);
                setDealerSearchResult(result);
            })();
        } else {
            setDealerSearchResult([]);
        }
    }, [dealerDebouncedSearch]);

    useEffect(() => {
        if (data?.data?.docs) {
            data?.data?.docs?.map((item) => {
                setBirthDate(item?.birthDate);
                setAvatarUrl(item?.avatar?.fileInfo?.url);
                setClientInitial({
                    first: item.name?.first,
                    last: item.name?.last,
                    middle: item.name?.middle,
                    email: item.email,
                    phone: item.phone,
                    avatar: item?.avatar,
                    series: item.passport?.series,
                    number: item.passport?.number,
                    expireDate: item.passport?.expireDate,
                    displayNameFirst: item.displayName?.first,
                    displayNameMiddle: item.displayName?.middle,
                    displayNameLast: item.displayName?.last,
                    language: item.preferences?.language,
                    birthDate: item?.birthDate,
                    dealerId: item?.dealerID
                });
                setClientCurrent({
                    first: item.name?.first,
                    last: item.name?.last,
                    middle: item.name?.middle,
                    email: item.email,
                    phone: item.phone,
                    avatar: item?.avatar,
                    series: item.passport?.series,
                    number: item.passport?.number,
                    expireDate: item.passport?.expireDate,
                    displayNameFirst: item.displayName?.first,
                    displayNameMiddle: item.displayName?.middle,
                    displayNameLast: item.displayName?.last,
                    language: item.preferences?.language,
                    passportDoc: item?.passportDoc
                });

                if (item?.passportDoc) {
                    handleSetPassport({
                        id: item.passportDoc?._id,
                        name: item.passportDoc?.fileInfo?.originalName
                    });
                }
            });
            setDealer(data.data.docs[0].dealerID);
        }
    }, [data]);

    useEffect(() => {
        if (
            clientInitial.first === clientCurrent.first &&
            clientInitial.last === clientCurrent.last &&
            clientInitial.middle === clientCurrent.middle &&
            clientInitial.email === clientCurrent.email &&
            clientInitial.phone === clientCurrent.phone &&
            clientInitial.series === clientCurrent.series &&
            clientInitial.number === clientCurrent.number &&
            clientInitial.expireDate === clientCurrent.expireDate &&
            clientInitial.displayNameFirst === clientCurrent.displayNameFirst &&
            clientInitial.displayNameMiddle ===
            clientCurrent.displayNameMiddle &&
            clientInitial.displayNameLast === clientCurrent.displayNameLast &&
            clientInitial.language === clientCurrent.language &&
            clientInitial.avatar === clientCurrent.avatar &&
            clientInitial.birthDate === birthDate &&
            clientInitial.dealerId?._id === dealer?._id &&
            clientCurrent?.passportDoc?._id === passport?.id
        ) {
            setEqual(true);
        } else setEqual(false);
    }, [clientCurrent, birthDate, dealer, passport]);

    const onCancelClick = function () {
        navigate("/clients");
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (clientCurrent?.phone?.length < 13) {
            errorToast("Incorrect length of the phone number");
            return;
        }

        const body = {
            name: {
                first: clientCurrent?.first,
                last: clientCurrent?.last,
                middle: clientCurrent?.middle,
            },
            email: clientCurrent?.email,
            phone: clientCurrent?.phone,
            passport: {
                series: clientCurrent?.series,
                number: clientCurrent?.number,
                expireDate: clientInitial?.passport?.expireDate ? formatDate(new Date(clientInitial?.passport?.expireDate)) : "",
            },
            displayName: {
                first: clientCurrent?.displayNameFirst,
                last: clientCurrent?.displayNameMiddle,
                middle: clientCurrent?.displayNameLast,
            },
            preferences: {
                language: lang,
            },
            birthDate: birthDate ? formatDate(new Date(birthDate)) : "",
            avatar: clientCurrent.avatar,
            dealerID: dealer,
            passportDoc: passport?.id || null
        };
        ApiService.patchEvent(
            `/clients/${state?.client?.id || id}`,
            token,
            body,
        )
            .then((response) => {
                if (response.status === 200) {
                    successToast(__("Successfully updated"));
                    navigate(`/clients/${state?.client?.id || id}`);
                } else {
                    errorToast(response.message);
                }
            })

            .catch((error) => {
                errorToast(error.message);
            });
    };

    const onBirthDateChange = function (e) {
        setBirthDate(e?.$d);
    };

    const buttons = [
        {
            name: "Back",
            link: "/clients",
            state: {},
            className: "outline",
        },
    ];
    if (loading)
        return (
            <div>
                <Preloader/>
            </div>
        );
    if (error) return <div>{error}</div>;
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    {label: "Clients", link: "/clients"},
                    {label: "Edit"},
                ]}
            />

            <div className="page component-page-client-edit">
                <form onSubmit={onSubmit} className="page-inner">
                    <PageHeader name={__("Edit Client")} buttons={buttons}/>
                    <div className="cards">
                        <div className="left-side-cards">
                            {data?.data?.docs?.map((item, i) => (
                                <div key={i}>
                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-profile"/>
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__(
                                                            "Client Information",
                                                        )}
                                                    </p>
                                                    <p className="card-subtitle"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-column">
                                            <UIAvatar
                                                onClick={handleDragNDropOpen}
                                                url={avatarUrl || ""}
                                            />
                                            <div className="input-row">
                                                <div className="input-item">
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                            htmlFor="FirstName"
                                                        >
                                                            <input
                                                                value={
                                                                    clientCurrent?.first || ""
                                                                }
                                                                onChange={(e) =>
                                                                    setClientCurrent(
                                                                        {
                                                                            ...clientCurrent,
                                                                            first: capitalize(e.target.value)
                                                                        },
                                                                    )
                                                                }
                                                                placeholder={__(
                                                                    "Enter your first name",
                                                                )}
                                                                id="FirstName"
                                                                className="input"
                                                                type="text"
                                                                required
                                                                onInvalid={e => e.target.setCustomValidity(__("Enter your first name"))}
                                                                onInput={e => e.target.setCustomValidity("")}
                                                            />
                                                            <span className="input-text">
                                                                {__(
                                                                    "First Name",
                                                                )}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                            htmlFor="Last"
                                                        >
                                                            <input
                                                                value={clientCurrent?.last || ""}
                                                                onChange={(e) =>
                                                                    setClientCurrent(
                                                                        {
                                                                            ...clientCurrent,
                                                                            last: capitalize(e.target.value)
                                                                        },
                                                                    )
                                                                }
                                                                placeholder={__(
                                                                    "Enter your last name",
                                                                )}
                                                                id="Last"
                                                                className="input"
                                                                type="text"
                                                                onInvalid={e => e.target.setCustomValidity(__("Enter your last name"))}
                                                                onInput={e => e.target.setCustomValidity("")}
                                                                required
                                                            />
                                                            <span className="input-text">
                                                                {__(
                                                                    "Last Name",
                                                                )}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="input-item">
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                            htmlFor="Middle"
                                                        >
                                                            <input
                                                                value={clientCurrent?.middle || ""}
                                                                onChange={(e) =>
                                                                    setClientCurrent(
                                                                        {
                                                                            ...clientCurrent,
                                                                            middle: capitalize(e.target.value)
                                                                        },
                                                                    )
                                                                }
                                                                placeholder={__(
                                                                    "Enter your middle name",
                                                                )}
                                                                id="Middle"
                                                                className="input"
                                                                type="text"
                                                            />
                                                            <span className="input-text">
                                                                {__(
                                                                    "Middle Name",
                                                                )}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                            htmlFor="date"
                                                        >
                                                            <span className="input-text date-picker">
                                                                {__(
                                                                    "Date of Birth",
                                                                )}
                                                            </span>
                                                            <StyledEngineProvider>
                                                                <BasicDatePicker
                                                                    value={birthDate || ""}
                                                                    selectedDate={birthDate}
                                                                    setSelectedDate={setBirthDate}
                                                                    onChange={onBirthDateChange}
                                                                />
                                                            </StyledEngineProvider>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="input-item">
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                            htmlFor="Phone"
                                                        >
                                                            <IMaskInput
                                                                defaultValue={
                                                                    item.phone
                                                                }
                                                                onChange={(e) =>
                                                                    setClientCurrent(
                                                                        {
                                                                            ...clientCurrent,
                                                                            phone: useFormatMask(e
                                                                                .target
                                                                                .value),
                                                                        },
                                                                    )
                                                                }
                                                                onPaste={(e) =>
                                                                    setClientCurrent(
                                                                        {
                                                                            ...clientCurrent,
                                                                            phone: e.clipboardData.getData(
                                                                                "text",
                                                                            ),
                                                                        },
                                                                    )
                                                                }
                                                                autoComplete="off"
                                                                className="mask-on-input card-input input"
                                                                id="Phone"
                                                                type="tel"
                                                                placeholder="+(998) 99 222 33 44"
                                                                mask={
                                                                    phoneEmailMask
                                                                }
                                                                required
                                                                onInvalid={e => e.target.setCustomValidity(__("Phone"))}
                                                                onInput={e => e.target.setCustomValidity("")}
                                                            />
                                                            <span className="input-text">
                                                                {__("Phone")}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                            htmlFor="email"
                                                        >
                                                            <input
                                                                defaultValue={
                                                                    item.email
                                                                }
                                                                onChange={(e) =>
                                                                    setClientCurrent(
                                                                        {
                                                                            ...clientCurrent,
                                                                            email: e
                                                                                .target
                                                                                .value,
                                                                        },
                                                                    )
                                                                }
                                                                placeholder={__(
                                                                    "Enter your email",
                                                                )}
                                                                id="email"
                                                                className="input"
                                                                type="email"
                                                            />
                                                            <span className="input-text">
                                                                {__("Email")}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-information"/>
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__(
                                                          "Passport Information",
                                                        )}
                                                    </p>
                                                    <p className="card-subtitle"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="files-block">
                                            <div className="file-row">
                                                <div className="file-card disabled">
                                                    <p className="text">{passport?.name || ""}</p>
                                                    <p className="label">{__("Client's Passport Scan")}</p>
                                                </div>
                                                <div
                                                  className={"file-card custom"}>
                                                    <input
                                                      value={__("Client's Passport Scan")}
                                                      disabled
                                                      type="text"
                                                      className="text"
                                                    />
                                                </div>
                                                <div className="component-card-button">
                                                    {passport?.id
                                                      ? (
                                                        <button
                                                          onClick={(e) => {
                                                              e.preventDefault();
                                                              setPassport(null);
                                                          }}
                                                          className="btn squared error"
                                                        >
                                                            <i className="icon-delete"></i>
                                                        </button>
                                                      )
                                                      : (
                                                        <button
                                                          onClick={(e) => {
                                                              e.preventDefault();
                                                              togglePassportDragNDrop();
                                                          }}
                                                          className="btn squared"
                                                        >
                                                            <i className="icon-upload"></i>
                                                        </button>
                                                      )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-item">
                                            {!isDealer && (
                                              <div className="component-input smart-search-container">
                                                  <label
                                                    className="input-label"
                                                    htmlFor="Dealer"
                                                  >
                                                      <input
                                                        onChange={handleDealerSearch}
                                                        value={
                                                            dealerSearch
                                                        }
                                                        placeholder={__(
                                                          "Start typing...",
                                                        )}
                                                        id="Dealer"
                                                        className="input"
                                                        type="text"
                                                      />
                                                      <span className="input-text">
                                                            {__("Dealer Search")}
                                                        </span>
                                                  </label>
                                                  {dealerSearchResult?.length > 0 && (
                                                    <div className="smart-search-list">
                                                        {dealerSearchResult?.map((item, i) => (
                                                            <Box
                                                              display="flex"
                                                              justifyContent="center"
                                                              key={i}
                                                            >
                                                                <UserLinkCard
                                                                  avatar={
                                                                    dealer?.avatar || ""
                                                                  }
                                                                  name={`${item.name.first} ${item.name.last}`}
                                                                  classNames={"with-padding bordered hoverable"}
                                                                  onClick={() => handleClickDealer(item)}
                                                                />
                                                            </Box>
                                                          )
                                                        )}
                                                    </div>
                                                  )}
                                              </div>
                                            )}
                                            {dealer && (
                                              <div className="component-input no-input">
                                                  <div className="label-container">
                                                      <label className="input-label">
                                                        <span className="input-text">
                                                            {__("Dealer")}
                                                        </span>
                                                          <UserLinkCard
                                                            avatar={dealer?.avatar || ""}
                                                            name={`${dealer?.name?.first} ${dealer?.name?.last}`}
                                                            classNames={
                                                                "with-padding input input-view"
                                                            }
                                                          />
                                                      </label>
                                                      {!isDealer && (
                                                        <button
                                                          className="input-label__delete-btn"
                                                          type="button"
                                                          onClick={removeDealerHandler}
                                                        >
                                                            <i className="icon-delete"></i>
                                                        </button>
                                                      )}
                                                  </div>
                                              </div>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-saved"/>
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                                className="btn outline sm"
                                text={__("Cancel")}
                                onClick={onCancelClick}
                                type="button"
                            />
                            <Button
                                disabled={equal}
                                className="btn sm"
                                text={__("Save")}
                                type="submit"
                            />
                            {isDragNDropOpen && (
                                <DragAndDrop
                                    setter={handleSetAvatar}
                                    closeDragNDrop={handleDragNDropOpen}
                                    uploadType={"avatar"}
                                    docModel={"Client"}
                                    ownerID={state?.client?.id}
                                    isImage={true}
                                />
                            )}
                            {isPassportDragNDropOpen &&
                              <DragAndDrop
                                setter={handleSetPassport}
                                closeDragNDrop={togglePassportDragNDrop}
                                docModel={"User"}
                              />
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageClientEdit;
