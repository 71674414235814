export function splitPermissionsByCategory(permissions) {
    const permissionsByCategory = [];

    // Iterate over each permission
    permissions.forEach((permission) => {
      const category = permission.category;
    
      // Find the index of the category in permissionsByCategory
      const categoryIndex = permissionsByCategory.findIndex(
        (item) => item[0].category === category
      );
    
      // If the category already exists in permissionsByCategory, push the permission to the existing category array
      if (categoryIndex !== -1) {
        permissionsByCategory[categoryIndex].push(permission);
      } else {
        // Category doesn't exist, create a new array for the category and add the permission
        permissionsByCategory.push([permission]);
      }
    });
    return permissionsByCategory;
}