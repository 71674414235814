import jwt_decode from "jwt-decode";

export const parseFiscalID = (fiscalID) => {
    if (!fiscalID) fiscalID = "";
    return fiscalID
        .replace(/(\D)+/g, "")
        ?.match(/.{1,4}/g)
        ?.join(" ");
};

export const parseVirtualID = (fiscalID) => {
    if (!fiscalID) fiscalID = "";
    return fiscalID
        .replace(/(\D)+/g, "")
        ?.match(/.{1,3}/g)
        ?.join(" ");
};

export const getTokenData = (token) => {
    if (token)
        return jwt_decode(token);
};

export const combineArrays = (arr1, arr2) => {
    if (Array.isArray(arr1) && Array.isArray(arr2)) {
        return arr1.concat(arr2);
    } else throw new Error("both variables should be an array!");
};

export const replaceNewlinesWithSpaces = (text) => {
    return text.replace(/\n/g, " ");
};
