import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import {translate} from "../../translations/translate";

const SelectComponent = ({translateLabel = true, ...props}) => {
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const {theme} = useSelector((state) => state.theme);
    const [translatedValue, setTranslatedValue] = useState({});
    const [translatedOptions, setTranslatedOptions] = useState([]);
    let colourStyles;

    if(theme === "dark") {
        colourStyles = {
            control: (styles, state) => ({
                ...styles,
                backgroundColor: "#1E1F23",
                border: "2px solid #292929 !important",
                color: "#E1E1E1 !important",
                height: 44,
                boxShadow: "0 !important",
                "&:hover": {
                    borderColor: state.isFocused ? "#388392" : "#292929"
                 }
            }),
            option: (styles, { isFocused }) => {
              return {
                ...styles,
                backgroundColor: isFocused ? "#292929" : "#1E1F23",
                color: "#E1E1E1"
              };
            },
          };
    } else {
        colourStyles = {
            control: (styles) => ({
                ...styles,
                height: 44,
                border: "2px solid #E3E3E3 !important",
                boxShadow: "0 !important",
            }),
            placeholder: (styles, {isDisabled}) => ({
                ...styles,
                color: isDisabled ? "#000" : styles.color,
                fontSize: 14
            }),
        };
    }

    useEffect(() => {
        if (translateLabel) {
            setTranslatedValue({
                ...props.value,
                label: __(props?.value?.label)
            });
            setTranslatedOptions(props.options?.map(option => {
                return {
                  label: __(option?.label),
                  value: option?.value
                };
            }));
        }
    }, [props.value, props.options, lang]);

    return (
        <Select
            {...props}
            options={translateLabel ? translatedOptions : props.options}
            onChange={props.onChange}
            name={props.name}
            styles={colourStyles}
            placeholder={props.placeholder || __("Select")}
            value={translateLabel ? translatedValue : props.value}
            isDisabled={props.disabled}
        />
    );
};

export default SelectComponent;
