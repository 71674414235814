import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import routes from "./router/clientRoutes";
import userRoutes from "./router/userRoutes";
import dealerRoutes from "./router/dealerRoutes";
import ScrollToTop from "./components/ScrollToTop";
import "./assets/fonts/epos-icons/style.css";
import "./assets/sass/global.sass";
import { translateAllTables } from "./utils/translateAllTables";
import { getTokenData } from "./utils/helper";

library.add(fas, far);
const App = () => {
    const { theme } = useSelector((state) => state.theme);
    const { role, token } = useSelector((state) => state.authReducer);
    const { lang } = useSelector((state) => state.languageReducer);
    const isDealer = getTokenData(token)?.role?.title === "Dealer";

    useEffect(() => {
        document.body.className = theme;
    }, [theme]);

    useEffect(() => {
        translateAllTables(lang);
    }, [lang]);

    return (
        <div>
            <ScrollToTop />
            <Routes>
                {
                    isDealer ? (
                        <>
                            {dealerRoutes.map((route, index) => (
                              <Route
                                key={index}
                                path={route.path}
                                element={route.element}
                              >
                                  {route.children &&
                                    route.children.map(
                                      (childRoute, childRouteIndex) => (
                                        <Route
                                          key={childRouteIndex}
                                          path={
                                            route.path +
                                            childRoute.path
                                          }
                                          element={childRoute.element}
                                        />
                                      ),
                                    )}
                              </Route>
                            ))}
                        </>
                      )
                    : role === "user" ? (
                        <>
                            {userRoutes.map((route, index) => {
                              return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={route.element}
                                >
                                    {route.children &&
                                        route.children.map(
                                            (childRoute, childRouteIndex) => (
                                                <Route
                                                    key={childRouteIndex}
                                                    path={
                                                        route.path +
                                                        childRoute.path
                                                    }
                                                    element={isDealer && typeof childRoute.dealerPage !== "undefined" ? childRoute.dealerPage : childRoute.element}
                                                />
                                            ),
                                        )}
                                </Route>
                              );
                            })}
                        </>
                    ) : (
                        // role === "client" ?
                        <>
                            {routes.map((route, index) => (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={route.element}
                                >
                                    {route.children &&
                                        route.children.map(
                                            (childRoute, childRouteIndex) => (
                                                <Route
                                                    key={childRouteIndex}
                                                    path={
                                                        route.path +
                                                        childRoute.path
                                                    }
                                                    element={childRoute.element}
                                                />
                                            ),
                                        )}
                                </Route>
                            ))}
                        </>
                    )
                    // :
                    // <Route
                    //     key={false}
                    //     path={"/"}
                    //     element={<PageError500/>}
                    // ></Route>
                }
            </Routes>
        </div>
    );
};
export default App;
