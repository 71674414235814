import React from "react";
import Languages from "../../global/Languages";
import Logo from "../Logo";
import "./styles.sass";

const Header = ({ ...props }) => {
    return (
        <header {...props} className="component component-header">
            <div className="component-header-inner">
                <Logo />
                <Languages />
            </div>
        </header>
    );
};

export default Header;
