import React from "react";
import PropTypes from "prop-types";
import "./styles.sass";
import {useNavigate} from "react-router-dom";

const SearchResultCard = (props) => {
    const navigate = useNavigate();
    const clickHandler =  props.onClick;

    return(
        <div className={"component-product-card " + props.classNames}
             onClick={props.link ? navigate(props.link, props.state) : clickHandler ? clickHandler : () => {}}
        >
            <div className="content">
                <div className="text-content">
                    <p className="text">{props.label || ""}</p>
                </div>
            </div>
        </div>
    );
};

SearchResultCard.propTypes ={
    link: PropTypes.string,
    state: PropTypes.object,
    label: PropTypes.string,
    _id: PropTypes.string,
    classNames: PropTypes.string
};

export default SearchResultCard;
