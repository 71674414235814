import React, { useEffect, useState } from "react";
import { translate } from "../../../translations/translate";
import { useDispatch, useSelector } from "react-redux";
import { ApiService } from "../../../utils/ApiService";
import { errorToast, successToast } from "../../../utils/toast";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import SelectComponent from "../../../components/Select";
import Preloader from "../../../components/Preloader/index";
import "./styles.sass";
import { IMaskInput } from "react-imask";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import { splitPermissionsByCategory } from "../../../utils/splitPermissionsByCategory";
import CheckBox from "../../../components/CheckBox";

const PageRolesCreate = () => {

    // USE NAVIGATE
    const navigate = useNavigate();

    // USE LOCATION
    const { state } = useLocation();

    // IDENTIFYING TYPE OF ROLE
    const isStaffRole = state?.business ? true : false;

    // USE SELECTOR
    const { token } = useSelector((state) => state.authReducer);

    // API REQUEST TO RECEIVE PERMISSIONS
    const url = `${config.API_BASE_URL}/permissions?limit=200`;
    const { data, loading, error } = useApiHook(url);

    // LANGUAGE SWITCHER
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    // CALLBACK FUNCTIONS
    const onRoleTitleChange = (e) => {
        setRole({
            ...role,
            title: e.target.value,
        });
    };
    const onPermissionCheck = (e) => {
        setRole({
            ...role,
            permissions: [...role.permissions, e.target.value],
        });
    };
    const onCancelClick = () => {
        navigate("/roles");
    };

    // USE STATE
    const [role, setRole] = useState({
        title: "",
        permissions: [],
    });
    const [permissions, setPermissions] = useState([]);

    // USE EFFECT
    useEffect(() => {
        if (data) {
            setPermissions(data?.data?.docs);
        }
    }, [data]);

    // ON FORM SUBMIT
    const onSubmit = (e) => {
        e.preventDefault();
        const body = {
            title: role?.title,
            permissions: role?.permissions // [IDs]
        };
        ApiService.postEvent(`/${isStaffRole ? `businesses/${state?.business?._id}/staff/roles/create` : "users-roles/create"}`, token, body)
            .then((response) => {
                if (response.status === 200) {
                    successToast(__("Successfully created"));
                    navigate("/roles", isStaffRole ? { state } : {});
                }
            })
            .catch((error) => {
                errorToast(error.message);
            });
    };

    // HEADER BUTTONS
    const buttons = [
        {
            name: "Back",
            link: "/roles",
            className: "outline",
            ...isStaffRole ? ({ state }) : {},
        }
    ];

    // PERMISSIONS BY CATEGORY
    const permissionsByCategory = splitPermissionsByCategory(permissions); // [[permissions1], [permissions2]]

    // LAYOUT

    // LOADING AND ERROR
    if (loading) {
        return <div><Preloader /></div>;
    }

    if (error) {
        return <div>{error.message}</div>;
    }

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Roles", link: "/roles" },
                    { label: "Create" },
                ]}
            />
            <div className="page component-page-roles-create">
                <form onSubmit={onSubmit} className="page-inner">
                    <PageHeader
                        name={__("Create Role")}
                        buttons={buttons}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-tie"></i>
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Role Information")}
                                            </p>
                                            <p className="card-subtitle"></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="roleTitle"
                                        >
                                            <input
                                                onChange={onRoleTitleChange}
                                                id="roleTitle"
                                                className="input"
                                                type="text"
                                            />
                                            <span className="input-text">
                                                {__("Role Title")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {
                                permissionsByCategory.map((permissionCategory, index) => {
                                    if(permissionCategory[0].category === "UserModelPermissions" && isStaffRole) {
                                        return <div key={index}></div>;
                                    }
                                    return (
                                        <div className="component-box-card" key={index} >
                                            <div className="card-header">
                                                <div className="card-info">
                                                    <div className="card-icon">
                                                        <i className="icon-tie"></i>
                                                    </div>
                                                    <div className="card-text">
                                                        <p className="card-title">
                                                            {__(`${permissionCategory[0].category}`)}
                                                        </p>
                                                        <p className="card-tie"></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="permissions__flex">
                                                {
                                                    permissionCategory.map((permission, index) => (
                                                        <div className="permission" key={index}>
                                                            <CheckBox
                                                                htmlFor={`permission-${permission.category}-${index}`}
                                                                inputType="checkbox"
                                                                id={`permission-${permission.category}-${index}`}
                                                                name={`permission-${permission.category}`}
                                                                value={permission._id}
                                                                onChange={onPermissionCheck}
                                                                titleClass="checkbox-title"
                                                                title={__(permission.title)}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-unsaved" />
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                                className="btn outline sm"
                                text={__("Cancel")}
                                onClick={onCancelClick}
                                type="button"
                            />
                            <Button
                                className="btn sm"
                                text={__("Save")}
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageRolesCreate;
