import { capitalize } from "../../utils/capitalize";
import {
    RESET_FILTERS,
    SET_BUSINESS_NAME,
    SET_BUSINESS_TYPE,
    SET_CASHDESK_BRANCH,
    SET_CHECKED,
    SET_EXPIRATION,
    SET_PRICE_RANGE,
    SET_STATUSES,
    SET_UNCHECKED,
    SET_USER_ROLES,
    SET_VERSION,
} from "../actionTypes/filtersActionTypes";

const initialState = {
    branchFilter: [
        {
            name: "Name",
            isChecked: false,
        },
        {
            name: "Phone",
            isChecked: false,
        },
        {
            name: "Address",
            isChecked: false,
        }
    ],
    role: [],
    status: [],
    cashdesk: [
        {
            name: "Assigned",
            isChecked: false
        },
        {
            name: "Not Assigned",
            isChecked: false
        }
    ],
    approved: [
        {
            name: "Approved",
            isChecked: false
        },
        {
            name: "Not Approved",
            isChecked: false
        }
    ],
    businessType: [],
    staffBranch: [],
    productType: [
        {
            name: "Product",
            isChecked: false
        },
        {
            name: "Service",
            isChecked: false
        }
    ],
    productCategory: [],
    priceRange: {
        from: 2000,
        to: 2000000
    },
    categoriesCategories: [
        {
            name: "Parent",
            isChecked: false
        },
        {
            name: "Child",
            isChecked: false
        }
    ],
    businessName: [],
    cashdeskBranch: [],
    expiresAt: {
        from: null,
        to: null
    },
    cashdeskVersions : []
};
export const filtersReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CHECKED:
            state[action.filterItem].find(filter => filter.name === action.name).isChecked = true;
            return {...state};
        case SET_UNCHECKED:
            state[action.filterItem].find(filter => filter.name === action.name).isChecked = false;
            return {...state};
        case SET_PRICE_RANGE:
            return {...state,
                    priceRange: {
                        from: action.from,
                        to: action.to
                    }};
        case SET_EXPIRATION:
            return {
                ...state,
                expiresAt: {
                    from: action.from,
                    to: action.to
                }
            };
        case SET_STATUSES:
            return { ...state, status: action.statuses.map(status => {
                if(state.status.find(s => s.name === capitalize(status))) {
                    return state.status.find(s => s.name === capitalize(status));
                }
                return {name: capitalize(status), isChecked: false};
            })};
        case SET_USER_ROLES:
            return { ...state, role: action.roles.map(role => {
                if(state.role.find(r => r.name === capitalize(role.title))) {
                    return state.role.find(r => r.name === capitalize(role.title));
                }
                return {name: capitalize(role.title), isChecked: false, id: role._id};
            })};
        case SET_BUSINESS_TYPE:
            return { ...state, businessType: [action.businessType] };
        case SET_BUSINESS_NAME:
            return { ...state, businessName: [action.businessName] };
        case SET_CASHDESK_BRANCH:
            return { ...state, cashdeskBranch: [action.branchName] };
        case SET_VERSION:
            return { ...state, cashdeskVersions: action.payload};
        case RESET_FILTERS:
            return initialState;
        default:
            return state;
    }
};
