
// REACT PACKAGES
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// UTILS
import { translate } from "../../../translations/translate";
import { searchClients } from "../../../utils/globalAPIRequests/searchClients";
import config from "../../../config";
import useApiHook from "../../../hooks/useApiHook";
import getStaffRoles from "../../../utils/globalAPIRequests/getStaffRoles";
import { ApiService } from "../../../utils/ApiService";
import { errorToast, successToast } from "../../../utils/toast";
import { searchStaff } from "../../../utils/globalAPIRequests/searchStaff";
import cleanseObject from "../../../utils/cleanseObject";

// COMPONENTS
import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { Box } from "@mui/material";
import UserLinkCard from "../../../components/UserLinkCard";
import SelectComponent from "../../../components/Select";
import ModalWindow from "../../../components/ModalWindow";

// STYLES
import "./styles.sass";

const PageStaffCreate = () => {

    // CALLBACK FUNCTIONS
    const onSubmit = function (e) {
        e.preventDefault();
        const body = {
            id: staff.clientID,
            role: staff.role,
            approve: {
                type: "notification",
                value: true,
            },
        };
        const confirmBody = {
            id: staff.clientID,
            role: staff.role,
            confirmed: confirmed,
        };
        const cleansedConfirmBody = cleanseObject(confirmBody);
        let url = `/businesses/${state?.business?.id}`;
        if (state?.branch?.slug || state?.branch?._id) {
            url += `/branches/${state?.branch?._id}`;
        }
        if (state?.cashdesk?.slug || state?.cashdesk?._id) {
            url += `/cashdesks/${state?.cashdesk?._id}`;
        }
        url += "/staff/add";
        ApiService.postEvent(url, token, (state?.branch?._id || state?.cashdesk?._id) ? cleansedConfirmBody : body)
            .then(
                (response) => {
                    if (response.status === 200) {
                        successToast(__("Successfully created"));
                        navigate(`/businesses/${state?.business?.slug || state?.business?.id}/staff`, {
                            state: {
                                business: {
                                    id: state?.business?.id,
                                    slug: state?.business?.slug,
                                },
                                branch: {
                                    _id: state?.branch?._id,
                                    slug: state?.branch?.slug,
                                },
                                cashdesk: {
                                    _id: state?.cashdesk?._id,
                                    slug: state?.cashdesk?.slug,
                                },
                            },
                        });
                    }
                }
            )
            .catch((error) => {

                // CHECKING IF ACTION IS REQUIRED
                if (error.status === 307) {
                    setIsWindowOpen(true);
                }
                else {
                    errorToast(__(error.message));
                }
            });
    };

    const handleClient = (clientObj) => {
        setClient(clientObj);
        setStaff({
            ...staff,
            clientID: (state?.branch?._id || state?.cashdesk?._id) ? clientObj?.id?._id : clientObj?._id,
            role: (state?.branch?._id || state?.cashdesk?._id) && roles?.find(role => role.value === clientObj.role).value,
        });
        setClientSearch("");
        setClientSearchResults(null);
    };
    const closeModal = function () {
        setIsWindowOpen(false);
    };
    const confirmCreate = function () {
        setConfirmed(true);
        onSubmit();
    };

    // HOOKS

    // USE LOCATION
    const { state } = useLocation();

    // USE NAVIGATE
    const navigate = useNavigate();

    // GET ROLES
    let roles = getStaffRoles(state?.business?.id);
    useEffect(() => {
        roles && roles.forEach(role => role.label = __(role.label));
    }, [roles]);

    // CLIENT SMART SEARCH
    const [clientSearch, setClientSearch] = useState("");
    const [client, setClient] = useState(null);
    const [clientSearchResults, setClientSearchResults] = useState([]);

    // USE SELECTOR
    const { token } = useSelector((state) => state.authReducer);

    useEffect(() => {
        if (clientSearch.length > 0) {
            if (state?.branch?._id || state?.cashdesk?._id) {
                (async () => {
                    return await searchStaff(
                        state?.business?.id,
                        clientSearch,
                        token,
                    );
                })().then((result) => {
                    setClientSearchResults(result);
                });
            }
            else {
                (async () => {
                    return await searchClients(clientSearch, token);
                })().then((result) => {
                    setClientSearchResults(result);
                });
            }
        } else {
            setClientSearchResults(null);
        }
    }, [clientSearch]);

    // USE STATE
    const [staff, setStaff] = useState({
        clientID: null,
        role: null
    });
    const [isWindowOpen, setIsWindowOpen] = useState(false);
    const [confirmed, setConfirmed] = useState(null);

    // LANGUAGE
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    // API REQUESTS

    // GET BUSINESS
    const businessUrl = `${config.API_BASE_URL}/businesses/${state?.business?.slug || state?.business?.id}`;
    const { data: businessData, loading: businessLoading, error: businessError } = useApiHook(businessUrl);

    // GET BRANCH
    const branchUrl = `${config.API_BASE_URL}/branches/${state?.branch?.slug || state?.branch?.id}`;
    const { data: branchData, loading: branchLoading, error: branchError } = useApiHook(branchUrl);

    // GET CASHDESK
    const cashdeskUrl = `${config.API_BASE_URL}/cashdesks/${state?.cashdesk?.slug || state?.cashdesk?._id}`;
    const { data: cashdeskData, loading: cashdeskLoading, error: cashdeskError } = useApiHook(cashdeskUrl);

    // HEADER BUTTONS
    const buttons = [
        {
            name: "Back",
            link: `/businesses/${state?.business?.slug || state?.business?.id}/staff`,
            state: {
                business: {
                    id: state?.business?.id,
                    slug: state?.business?.slug,
                },
                branch: {
                    id: state?.branch?._id,
                    slug: state?.branch?.slug,
                },
                cashdesk: {
                    id: state?.cashdesk?._id,
                    slug: state?.cashdesk?.slug,
                },
            },
            className: "outline"
        }
    ];


    // LAYOUT
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Businesses", link: "/businesses" },
                    { label: businessData?.data?.docs[0].name, link: `/businesses/${state?.business?.slug || state?.business?.id}` },
                    { label: "Staff", link: `/businesses/${state?.business?.slug || state?.business?.id}/staff` },
                    { label: "Create" },
                ]}
            />

            <div className="page component-page-staff-create">
                <form onSubmit={onSubmit} className="page-inner">
                    <PageHeader
                        name={__("Create Staff Member")}
                        buttons={buttons}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Business Information")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-column">
                                    <div className="input-row">
                                        <div className="input-item">
                                            <div className="component-input smart-search-container">
                                                <label
                                                    className="input-label"
                                                >
                                                    <input
                                                        onChange={(e) => {
                                                            setClientSearch(
                                                                e.target.value,
                                                            );
                                                            setClient("");
                                                        }}
                                                        value={
                                                            client ? "" : clientSearch
                                                        }
                                                        className="input"
                                                        type="text"
                                                    />
                                                    <span className="input-text">
                                                        {__("Client Search")}
                                                    </span>
                                                </label>
                                                {clientSearchResults?.length > 0 && (
                                                    <div className="smart-search-list">
                                                        {clientSearchResults &&
                                                            clientSearchResults?.map(
                                                                (item, i) => (
                                                                    <Box
                                                                        display="flex"
                                                                        justifyContent="center"
                                                                        key={i}
                                                                    >
                                                                        <UserLinkCard
                                                                            avatar={
                                                                                client?.avatar ||
                                                                                ""
                                                                            }
                                                                            name={`${item?.name?.first || item?.id?.name?.first} ${item?.name?.last || item?.id?.name?.last}`}
                                                                            classNames={
                                                                                "with-padding bordered hoverable"
                                                                            }
                                                                            onClick={() =>
                                                                                handleClient(item)
                                                                            }
                                                                        />
                                                                    </Box>
                                                                ),
                                                            )}
                                                    </div>
                                                )}
                                            </div>
                                            {client && (
                                                <div className="component-input">
                                                    <label className="input-label">
                                                        <UserLinkCard
                                                            avatar={
                                                                client?.avatar || ""
                                                            }
                                                            name={`${client?.name?.first || client?.id?.name?.first} ${client?.name?.last || client?.id?.name?.last}`}
                                                            classNames="input input-avatar"
                                                        />
                                                        <span className="input-text top-aligned">
                                                            {__("Client")}
                                                        </span>
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="input-item">
                                            {
                                                (state?.business?.slug || state?.business?.id) && (
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                        >
                                                            <input
                                                                value={businessData?.data?.docs[0].name || ""}
                                                                className="input"
                                                                type="text"
                                                                disabled
                                                            />
                                                            <span className="input-text">
                                                                {__("Business Name")}
                                                            </span>
                                                        </label>
                                                    </div>
                                                )
                                            }
                                            {
                                                (state?.branch?.slug || state?.branch?.id) && (
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                        >
                                                            <input
                                                                value={branchData?.data?.docs[0].name}
                                                                className="input"
                                                                type="text"
                                                                disabled
                                                            />
                                                            <span className="input-text">
                                                                {__("Branch Name")}
                                                            </span>
                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="input-item">
                                            <div className="component-input">
                                                <label
                                                    className="input-label"
                                                >
                                                    <span className="input-text">
                                                        {__("Role")}
                                                    </span>
                                                    <SelectComponent
                                                        value={(state?.branch?._id || state?.cashdesk?._id) && roles?.find(role => role.value === client?.role)}
                                                        options={roles}
                                                        placeholder={__("Select")}
                                                        onChange={(e) =>
                                                            setStaff({
                                                                ...staff,
                                                                role: e.value,
                                                            })
                                                        }
                                                        disabled={state?.branch?._id || state?.cashdesk?._id}
                                                    />
                                                </label>
                                            </div>
                                            {
                                                (state?.cashdesk?.slug || state?.cashdesk?._id) && (
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                        >
                                                            <input
                                                                value={cashdeskData?.data?.docs?.[0].uniqueID}
                                                                className="input"
                                                                type="text"
                                                                disabled
                                                            />
                                                            <span className="input-text">
                                                                {__("Cashdesk ID")}
                                                            </span>

                                                        </label>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-unsaved"></i>
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                                className="btn outline sm"
                                text={__("Cancel")}
                                type="button"
                            />
                            <Button
                                className="btn sm"
                                text={__("Save")}
                                type="submit"
                            />
                        </div>
                    </div>
                    {
                        isWindowOpen && (
                            <div className="modal-bottom">
                                <ModalWindow
                                    title={__("Are you sure?")}
                                    text={__(
                                        "Are you sure you want to move this worker to another workplace",
                                    )}
                                    button={__("Approve")}
                                    onClick={closeModal}
                                    onClickDelete={confirmCreate}
                                />
                            </div>
                        )
                    }
                </form>
            </div>
        </div>
    );
};

export default PageStaffCreate;
