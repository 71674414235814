import React, {useState, useRef} from "react";
import PropTypes from "prop-types";
import { errorToast, successToast } from "../../utils/toast";
import "./styles.sass";
import { useSelector } from "react-redux";
import { translate } from "../../translations/translate";


function DragAndDrop({fileTypes, multiple = false}) {

  // LANGUAGE
  const lang = useSelector((state) => state.languageReducer.lang);
  const __ = (key) => translate(lang, key);

  const [dragActive, setDragActive] = useState(false);
  const [files, setFiles] = useState([]);
  const inputRef = useRef(null);
  
  const fileTypeChecker = (type) => {
    if(!fileTypes || fileTypes.length === 0) {
      return true;
    }
    return fileTypes.includes(type);
  };

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();

    setDragActive(false);

    const data = e.dataTransfer.files;
    const type = data[0].type.split("/")[1];
    
    if(!multiple && files.length === 1) {
      errorToast(__("Multiple files are not allowed!"));
      return;
    }
    
    if (data && data[0] && fileTypeChecker(type)) {
      setFiles(prev => [...prev, data[0]]);
      successToast(__("File was successfully uploaded"));
    }
  };
  
  // triggers when file is selected with click
  const handleChange = e => {
    e.preventDefault();
    const data = e.target.files;
    const type = data[0].type.split("/")[1];

    if(!multiple && files.length === 1) {
      errorToast(__("Multiple files are not allowed!"));
      return;
    }

    if (data && data[0] && fileTypeChecker(type)) {
      setFiles(prev => [...prev, data[0]]);
      successToast(__("File was successfully uploaded"));
    }
  };

  const deleteFile = name => setFiles(prev => prev.filter(e => e.name !== name));

  const onLabelClick = e => e.preventDefault();
  
// triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };
  
  return (
    <>
      <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
        <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" } onClick={onLabelClick}>
          <div className="drag-area-content">
            <p className="drag-area-text" >Drag and drop your files here or</p>
            <button className="upload-button" onClick={onButtonClick}>Upload file</button>
          </div> 
        </label>
        { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
      </form>

      {
        files.length > 0 &&
        <div className="uploaded-files-container">
          {files.map((e, i) => (
            <div key={i} className="uploaded-file">
              <div className="uploaded-file-info text-container" >
                <p className="uploaded-file-name" >{e.name}</p>
                <p className="uploaded-file-size" >{(e.size / 1024 / 1024).toFixed(2)}MB</p>
              </div>
              <div className="uploaded-file-info links-container" >
                <a className="uploaded-file-preview" href={`${URL.createObjectURL(e)}`} target="blank" title="Preview" >Preview</a>
                <a className="uploaded-file-download" href={`${URL.createObjectURL(e)}`} download title="Download" >Download</a>
                <button className="uploaded-file-delete" onClick={() => deleteFile(e.name.toString())} >Delete</button>
              </div>
            </div>
          ))}
        </div>
      }
    </>
  );
}

DragAndDrop.propTypes = {
  fileTypes: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.bool
};

export default DragAndDrop;
