import React from "react";
import { translate } from "../../../translations/translate";
import { useSelector } from "react-redux";
import { getParsedDate } from "../../../utils/getParsedDate";
import { useLocation, useParams } from "react-router";
import PageHeader from "../../../components/PageHeader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import config from "../../../config";
import useApiHook from "../../../hooks/useApiHook";
import Preloader from "../../../components/Preloader";
import { formatDate } from "../../../utils/formatDate";
import "./styles.sass";
import { capitalize } from "../../../utils/capitalize";

const PageCashdeskView = ({ ...props }) => {
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const { state } = useLocation();
    const { id } = useParams();
    const url = `${config.API_BASE_URL}/cashdesks/${state?.cashdesk?.id || id}`;
    const { data, loading, error } = useApiHook(url);
    const cashdesk = data?.data?.docs?.[0];

    const epsData = cashdesk?.EPS;
    const buttons = [
        ... state?.business?._id
        ? [{
            name: "Staff",
            link: `/businesses/${state?.business?.slug || state?.business?._id}/staff`,
            state: {
                business: {
                    id: state?.business?._id,
                    slug: state?.business?.slug,
                },
                cashdesk: {
                    _id: state?.cashdesk?.id,
                    slug: state?.cashdesk?.slug,
                },
            }
        }]
        : [],
        {
            name: "Back",
            link: "/cashdesks",
            state: {},
            className: "outline",
        },
        {
            name: "Edit",
            link: `/cashdesks/${state?.cashdesk?.id || id}/edit`,
            state: {
                cashdesk: {
                    id: state?.cashdesk?.id || id,
                },
            },
            className: "",
        },
        {
            name: "EPS",
            link: `/cashdesks/${cashdesk?.uniqueID || cashdesk?._id || id}/eps`,
            state: {
                cashdesk: {
                    uniqueID: cashdesk?.uniqueID,
                    _id: cashdesk?._id,
                },
                eps: epsData,
            },
            className: "outline",
        },
    ];
    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error}</div>;

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Cashdesks", link: "/cashdesks" },
                    { label: "View" },
                ]}
            />
            <div {...props} className="page component-page-cashdesk-view">
                <form className="page-inner">
                    <PageHeader name={__("View Cashdesk")} buttons={buttons} />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Cashdesk Information")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="fiscalID"
                                        >
                                            <input
                                                defaultValue={cashdesk?.fiscalModule?.fiscalID || "-"}
                                                id="fiscalID"
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Fiscal ID")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="applicationType"
                                        >
                                            <input
                                                value={cashdesk?.applicationType.length > 0 ?
                                                  __(cashdesk?.applicationType[0]?.toUpperCase() + cashdesk?.applicationType.slice(1)) : "-"}
                                                id="applicationType"
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Type")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    {/* uniqueID */}
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                          htmlFor="uniqueID"
                                        >
                                            <input
                                              defaultValue={cashdesk?.uniqueID}
                                              id="uniqueID"
                                              className="input input-disabled"
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Cashdesk ID")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="virtualID"
                                        >
                                            <input
                                                defaultValue={cashdesk?.fiscalModule?.virtualID || "-"}
                                                id="virtualID"
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Virtual ID")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Cashdesk License")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="Key"
                                        >
                                            <input
                                                defaultValue={cashdesk?.licence?.key || "-"}
                                                id="Key"
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Key")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="ExpiresAt"
                                        >
                                            <input
                                                defaultValue={cashdesk?.licence?.expiresAt
                                                    ? formatDate(cashdesk?.licence?.expiresAt)
                                                    : "-"}
                                                id="ExpiresAt"
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Expires At")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                          className="input-label"
                                          htmlFor="BlockDate"
                                        >
                                            <input
                                              defaultValue={cashdesk?.licence?.blockDate
                                                ? formatDate(cashdesk?.licence?.blockDate)
                                                : "-"}
                                              id="BlockDate"
                                              className="input input-disabled"
                                              type="text"
                                              disabled
                                            />
                                            <span className="input-text">
                                                {__("Block Date")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="Status"
                                        >
                                            <input
                                                value={capitalize(cashdesk?.status)}
                                                id="Status"
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Status")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="Approved"
                                        >
                                            <input
                                                value={cashdesk?.approved.isApproved ? __("Approved") : __("Not Approved")}
                                                id="Approved"
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Approved")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Device Information")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="OSVersion"
                                        >
                                            <input
                                                defaultValue={cashdesk?.deviceInfo?.OSVersion}
                                                id="OSVersion"
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("OS Version")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="BitDepth"
                                        >
                                            <input
                                                defaultValue={cashdesk?.deviceInfo?.bitDepth}
                                                id="BitDepth"
                                                className="input input-disabled"
                                                type="Card"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Bit Depth")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="MacAddress"
                                        >
                                            <input
                                                defaultValue={cashdesk?.deviceInfo?.MACAddress}
                                                id="MacAddress"
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("MAC Address")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Applet Information")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="AppVersion"
                                        >
                                            <input
                                                defaultValue={cashdesk?.appletInfo?.appVersion}
                                                id="AppVersion"
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("App Version")}
                                            </span>
                                        </label>
                                    </div>

                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="Resequence"
                                        >
                                            <input
                                                defaultValue={getParsedDate(
                                                    cashdesk?.appletInfo
                                                        ?.versionCheckedAt,
                                                )}
                                                id="Resequence"
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Version Checked At")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Fiscal Information")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="BusinessName"
                                        >
                                            <input
                                                defaultValue={cashdesk?.businessID?.name}
                                                id="BusinessName"
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Business Name")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="BranchName"
                                        >
                                            <input
                                                defaultValue={cashdesk?.branchID?.name}
                                                id="BranchName"
                                                className="input input-disabled"
                                                type="Card"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Branch Name")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="FiscalModule"
                                        >
                                            <input
                                                defaultValue={cashdesk?.fiscalModule?.fiscalID}
                                                id="FiscalModule"
                                                className="input input-disabled"
                                                type="text"
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Fiscal Module")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageCashdeskView;
