import {capitalize} from "../../../utils/capitalize";
import {useSelector} from "react-redux";
import {translate} from "../../../translations/translate";

const SearchTagGroup = ({tagsArray, removeTag, label}) => {
  // Redux Selectors
  const lang = useSelector((state) => state.languageReducer.lang);

  // translate hook
  const __ = (key) => translate(lang, key);

  const deleteSelectedClient = (tagId) => {
    removeTag(tagId);
  };

  return (
    <>
      {tagsArray?.length > 0 && (
        <div className="info-card white-bg">
          <p className="card-heading">
            {__(`Selected ${capitalize(label)}`)}
          </p>
          <div className="card-content equal-padding">
            <div className="tags-container">
              {tagsArray.map((tag) => {
                const {name} = tag;
                const viewName = name?.first && name?.last
                  ? name.first + " " + name.last
                  : name;
                return (
                  <div className="tag" key={tag?._id}>
                    {viewName}
                    <span
                      className="delete-btn"
                      onClick={() => deleteSelectedClient(tag._id)}
                    ></span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchTagGroup;