import React, { useState } from "react";
import Header from "../../../components/LayoutBasic/Header";
import Button from "../../../components/global/Button";
import Input from "../../../components/LayoutBasic/Input";
import cleanseObject from "../../../utils/cleanseObject";
import { translate } from "../../../translations/translate";
import "./styles.sass";
import { IMaskInput } from "react-imask";
import { ApiService } from "../../../utils/ApiService";
import { errorToast, successToast } from "../../../utils/toast";
import CardVerificationInput from "../../../components/LayoutBasic/CardVerificationInput";
import {
    authActionStart,
    authActionSuccess,
    roleAction,
} from "../../../redux/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { useFormatMask } from "../../../hooks/useFormatMask";

const PageSignUp = ({ ...props }) => {
    const dispatch = useDispatch();
    const PhoneMask = "+998 (00) 000-00-00";
    const phoneEmailMask = [
        {
            mask: PhoneMask,
        },
    ];

    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const [phoneNumberExists, setPhoneNumberExists] = useState(undefined);
    const [passwordInputs, setPasswordInputs] = useState({
        password: null,
        confirmPassword: null,
    });
    let password = passwordInputs.password || "",
        confirmPassword = passwordInputs.confirmPassword || "";

    const [passwordConfirmed, setPasswordConfirmed] = useState({
        checked: false,
        equal: false,
    });
    const [client, setClient] = useState({
        name: {
            first: null,
            last: null,
        },
        phone: null,
        email: null,
        password: null,
    });
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [otpSent, setOtpSent] = useState(false);

    const comparePasswords = () => {
        const newPasswordConfirmationState = {
            checked: true,
            equal: password === confirmPassword,
        };
        setPasswordConfirmed(newPasswordConfirmationState);
        setClient({
            ...client,
            password: password,
        });
        return newPasswordConfirmationState;
    };

    const checkNumberExistence = (phone) => {
        ApiService.postEvent("/exist", null, { phone: phone })
            .then((response) => {
                if (
                    response.data.exists === true &&
                    response.data.model === "User"
                ) {
                    setPhoneNumberExists(false);
                } else if (
                    response.data.exists === true &&
                    response.data.model === "Client"
                ) {
                    setPhoneNumberExists(true);
                    errorToast(__("This phone number is already taken"));
                } else if (response.status === 300) {
                    setPhoneNumberExists(true);
                    errorToast(__("This phone number is already taken"));
                } else {
                    setPhoneNumberExists(false);
                }
            })
            .catch((response) => {
                if (response.status === 404 && response.data.exists === false) {
                    setPhoneNumberExists(false);
                } else if (response.status === 300) {
                    setPhoneNumberExists(true);
                    errorToast(__("This phone number is already taken"));
                }
            });
    };

    const onPhoneChange = (e) => {
        const phoneVal = useFormatMask(
            e.clipboardData ? e.clipboardData.getData("text") : e.target.value,
        );
        setClient({
            ...client,
            phone: phoneVal,
        });
        if (phoneVal.length === 13) checkNumberExistence(phoneVal);
    };

    const validatePassword = (password) => {
        if (password.length < 8) {
            errorToast("Password should be at least 8 characters long.");
            return false;
        }
        if (/^\d+$/.test(password)) {
            errorToast("Password should contain at least one non-numeric character.");
            return false;
        }
        if (/^[A-Za-z]+$/.test(password)) {
            errorToast("Password should contain at least one non-alphabet character.");
            return false;
        }
        return true;
    };

    const onSubmit = (e) => {
        e.preventDefault();

        // Check if the password meets the requirements
        if (!validatePassword(password)) {
            return;
        }

        let body = cleanseObject(
            otpSent ? { ...client, otp: otp.join("") } : client,
        );
        if (!phoneNumberExists)
            ApiService.postEvent("/clients/create", null, body)
                .then((response) => {
                    if (response.status) {
                        successToast(response.message);
                        if (response.code === "OTP_SENT_SUCCESSFULLY") {
                            setClient({
                                ...client,
                                verificationToken:
                                response.data.verificationToken,
                            });
                            setOtpSent(true);
                        } else if (
                            response.status === 201 &&
                            response.code === "CLIENT_CREATED_SUCCESSFULLY"
                        ) {
                            dispatch(authActionStart());
                            dispatch(authActionSuccess(response.data));
                            dispatch(roleAction("client"));
                            window.location.replace("/");
                        }
                    } else {
                        errorToast(response.message);
                    }
                })
                .catch((error) => {
                    errorToast(error.message);
                    setOtpSent(false);
                    setClient({ ...client, verificationToken: null });
                    setOtp(new Array(6).fill(""));
                });
    };

    return (
        <div {...props} className="page page-sign-up">
            <Header />
            <div className="component-card-sign-up">
                <div className="card-inner">
                    <h1 className="component-heading">
                        {__("Create an account")}
                    </h1>
                    <div className="component-card-text">
                        <p className="component-text">
                            {__(
                                "Please fill in your personal information to sign up",
                            )}
                        </p>
                    </div>
                    <form className="card-form" onSubmit={onSubmit}>
                        <div className="inputs-block">
                            <Input
                                className="card-input"
                                htmlFor="name"
                                id="Name"
                                type="text"
                                required
                                placeholder={__("First Name")}
                                onChange={(e) => {
                                    setClient({
                                        ...client,
                                        name: {
                                            ...client.name,
                                            first: e.target.value,
                                        },
                                    });
                                }}
                                disabled={otpSent}
                            />
                            <Input
                                className="card-input"
                                htmlFor="lastName"
                                id="LastName"
                                type="text"
                                required
                                placeholder={__("Last Name")}
                                onChange={(e) => {
                                    setClient({
                                        ...client,
                                        name: {
                                            ...client.name,
                                            last: e.target.value,
                                        },
                                    });
                                }}
                                disabled={otpSent}
                            />
                            <div className="component-card-input">
                                <label className="card-label" htmlFor="phone">
                                    <IMaskInput
                                        className="mask-on-input card-input"
                                        htmlFor="phone"
                                        id="phone"
                                        type="tel"
                                        required
                                        name="phone"
                                        placeholder="+(998) 99 222 33 44"
                                        mask={phoneEmailMask}
                                        autoComplete="off"
                                        onPaste={onPhoneChange}
                                        onChange={onPhoneChange}
                                        disabled={otpSent}
                                    />
                                </label>
                            </div>
                            <Input
                                className="card-input"
                                htmlFor="Email"
                                id="Email"
                                type="text"
                                name="email"
                                placeholder={__("Email")}
                                onChange={(e) => {
                                    setClient({
                                        ...client,
                                        email: e.target.value,
                                    });
                                }}
                                disabled={otpSent}
                            />
                            <Input
                                className="card-input"
                                htmlFor="userPassword"
                                id="password"
                                type="password"
                                required
                                placeholder={__("Password")}
                                onChange={(e) => {
                                    password = e.target.value;
                                    setPasswordInputs({
                                        ...passwordInputs,
                                        password: password,
                                    });
                                }}
                                disabled={otpSent}
                            />
                            <Input
                                className="card-input"
                                htmlFor="userPassword"
                                id="userConfirmPassword"
                                type="password"
                                required
                                placeholder={__("Repeat Password")}
                                onChange={(e) => {
                                    confirmPassword = e.target.value;
                                    setPasswordInputs({
                                        ...passwordInputs,
                                        confirmPassword: confirmPassword,
                                    });
                                    if (
                                        password &&
                                        confirmPassword &&
                                        confirmPassword?.length >=
                                        password?.length
                                    )
                                        comparePasswords();
                                }}
                                disabled={otpSent}
                            />
                            {passwordConfirmed.checked &&
                                !passwordConfirmed.equal && (
                                    <div className="sign-up-text">
                                        <p className="card-sign-up-text">
                                            {__(
                                                "The entered passwords do not match",
                                            )}
                                        </p>
                                    </div>
                                )}
                            {otpSent && (
                                <div>
                                    <div className="component-card-text">
                                        <p className="component-text">
                                            {__(
                                                "Please enter the verification code we sent to",
                                            )}
                                            {client?.phone}
                                        </p>
                                    </div>
                                    <CardVerificationInput
                                        otp={otp}
                                        setOtp={setOtp}
                                    />
                                </div>
                            )}
                        </div>
                        <Button className="btn" text={__("Submit")} />
                        {/* <Link to="/auth/reset">*/}
                        {/* </Link>*/}
                    </form>
                    {/* <div className="sign-up-text">*/}
                    {/*    <p className="card-sign-up-text">By clicking on &quot;Sign up&quot;, you accept the <Link to="/"*/}
                    {/*                                                                                              className="sing-up-link">Terms*/}
                    {/*        of the Public Offer</Link> and <Link to="/" className="sing-up-link">Privacy Policy</Link>*/}
                    {/*    </p>*/}
                    {/* </div>*/}
                </div>
            </div>
        </div>
    );
};
export default PageSignUp;
