import "./styles.sass";
import {capitalize} from "../../utils/capitalize";

const CustomInput = ({label = "", value= "", ...otherProps}) => {

  return (
    <label className="custom-input_label">
      <input className="custom-input" value={value} {...otherProps}/>
      {label && (
        <span className="custom-input_title">{capitalize(label)}</span>
      )}
    </label>
  );
};

export default CustomInput;