import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../LayoutBasic/Logo";
import useApiHook from "../../../hooks/useApiHook";
import { translate } from "../../../translations/translate";
import config from "../../../config";
import "./styles.sass";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {getTokenData} from "../../../utils/helper";

const SidebarNavigation = () => {
    const navigate = useNavigate();
    const lang = useSelector((state) => state.languageReducer.lang);
    const storedUserData = useSelector((state) => state.authReducer);
    const decodedToken = getTokenData(storedUserData?.token);
    const __ = (key) => translate(lang, key);

    const isOpen = useSelector(state => state.sidebar.isOpen);
    const warehouseStatus = useSelector(state => state.warehouse.warehouseStatus);
    const [dropdowns, setDropdowns] = useState({ dynamic: [] });

    const isClient = storedUserData.role === "client";
    const isUser = storedUserData.role === "user";
    const isDealer = decodedToken?.role?.title === "Dealer";
    let url = isClient ? `${config.API_BASE_URL}/businesses` : "";

    const { data: businessesData } = useApiHook(url);
    let businesses = [];
    if (businessesData && businessesData?.data?.docs)
        businesses = businessesData.data.docs;
    const currentPath = useLocation().pathname;
    const state = useLocation().state;
    let businessID = isUser ? state?.business?.id || state?.business?._id : undefined;

    let business = null;
    const { data: businessData } = useApiHook(isUser ? `${config.API_BASE_URL}/businesses/${businessID}` : "");
    if (businessData && businessData?.data?.docs) {
        business = businessData?.data?.docs[0];
    }
    const isCurrentPage = (checkURL, strict = false) => {
        if (strict) {
            const pathArray = currentPath.split("/");
            const currentExact = pathArray[pathArray.length - 1];
            return currentExact === checkURL.replace("/", "");
        }
        return currentPath.includes(checkURL);
    };
    const isParentOfCurrentPage = (childrenArr) => {
        const result = childrenArr.find((element) =>
            currentPath.includes(element),
        );

        if (result) return true;
    };

    const handleDropdownChange = (id, dynamic = null) => {
        let stateObj;
        if (dynamic) {
            stateObj = {
                ...dropdowns,
            };
            if (dropdowns.dynamic) {
                const keys = Object.keys(dropdowns.dynamic);
                if (id.includes("bus") && keys.some(str => str.includes("bus")))
                    stateObj.dynamic = [];
            }
            stateObj["dynamic"][id] = !dropdowns.dynamic[id];
        } else {
            stateObj = {
                ...dropdowns,
            };
            stateObj[id] = !dropdowns[id];
        }
        setDropdowns(stateObj);
    };

    if (isCurrentPage("/businesses"))
        if (!dropdowns.businessesDropdown)
            setDropdowns({
                ...dropdowns,
                businessesDropdown: true
            });

    return (
        <div
            className={`component component-sidebar-navigation ${!isOpen && "state-closed"
                }`}
        >
            <Link to="/" className="sidebar-header">
                {isOpen ? <Logo /> : <Logo type="small" />}
            </Link>

            <div className="sidebar-body">
                <div className={`sidebar-title ${!isOpen && "sidebar-title-centered"}`}>
                    <span>{__("Menu")}</span>
                </div>
                {isUser && (
                    <div className={`sidebar-items ${!isOpen && "sidebar-items-centered"}`}>
                        {!isDealer && (
                          <>
                              {/* Fiscal */}
                              <div
                                className="item"
                                onClick={() => navigate("/fiscal")}
                              >
                                  <div
                                    className={`item-content ${isCurrentPage("fiscal") ? "active" : ""
                                    }`}
                                  >
                                      <FontAwesomeIcon
                                        icon="fa-regular fa-floppy-disk"
                                        className="item-icon"
                                        fixedWidth
                                      />
                                      <span className="item-text">
                                    {__("Fiscal Modules")}
                                </span>
                                  </div>
                              </div>

                              {/* Users */}
                              <div
                                className="item"
                                onClick={() => navigate("/users")}
                              >
                                  <div
                                    className={`item-content ${isCurrentPage("users") ? "active" : ""
                                    }`}
                                  >
                                      <FontAwesomeIcon
                                        icon="fa-solid fa-user-tie"
                                        className="item-icon"
                                        fixedWidth
                                      />
                                      <span className="item-text">{__("Users")}</span>
                                  </div>
                              </div>
                          </>
                        )}

                        {/* Clients */}
                        <div
                            className="item"
                            onClick={() => navigate("/clients")}
                        >
                            <div
                                className={`item-content ${isCurrentPage("clients") ? "active" : ""
                                    }`}
                            >
                                <FontAwesomeIcon
                                    icon="fa-regular fa-user"
                                    className="item-icon"
                                    fixedWidth
                                />
                                <span className="item-text">{__("Clients")}</span>
                            </div>
                        </div>

                        {/* Business */}
                        <div className={"item"}>
                            <div
                                className={`item-content ${isCurrentPage("businesses") ? "active" : ""
                                    }`}
                                onClick={() => navigate("/businesses")}
                            >
                                <i className="item-icon icon-businesses" />
                                <span className="item-text">{__("Businesses")}</span>
                            </div>
                        </div>
                        {isCurrentPage("businesses") && (business?._id) &&
                            <div
                              className={`item dropdown ${((isParentOfCurrentPage([
                                  `/${business.slug}`,
                                  `/${business._id}`,
                                  `/${business.slug}/branches`,
                                  `/${business._id}/branches`,
                                  `/${business.slug}/cashdesks`,
                                  `/${business._id}/cashdesks`,
                                  `/${business.slug}/categories`,
                                  `/${business._id}/categories`,
                                  `/${business.slug}/arrivals`,
                                  `/${business._id}/arrivals`,
                              ]) || dropdowns.dynamic["bus" + business._id]) ?
                                "open" : "")
                              }`}
                                key={business._id}
                            >
                                {/* Business Name */}
                                <div
                                    className={`item-content ${dropdowns.dynamic[
                                        "bus" + business._id
                                    ] && "active"
                                        }`}
                                    onClick={() =>
                                        handleDropdownChange(
                                            "bus" + business._id,
                                            true,
                                        )
                                    }
                                >
                                    <i className="item-icon icon-business-building-location" />
                                    <span className="item-text">{business.name}</span>
                                    <i className="arrow icon-chevron" />
                                </div>
                                <div className="dropdown-body no-line">
                                    <div
                                      className="item"
                                      onClick={() =>
                                        navigate(
                                          `/businesses/${business._id}`,
                                          {
                                              state: {
                                                  business: {
                                                      _id: business._id,
                                                      slug: business.slug
                                                  }
                                              },
                                          },
                                        )
                                      }
                                    >
                                        {/* Business Information */}
                                        <div
                                          className={`item-content ${isCurrentPage(
                                            `${business._id}`,
                                            true,
                                          )
                                            ? "active"
                                            : ""
                                          }`}
                                        >
                                            <i className="item-icon icon-information"/>
                                            <span className="item-text">
                                                {__("Information")}
                                            </span>
                                        </div>
                                    </div>

                                    {/* Business Branches */}
                                    <div
                                      className={`item dropdown
                                                    ${(dropdowns.dynamic[business._id + "branches"] ||
                                      (isParentOfCurrentPage([
                                          `/${business.slug ||
                                          business._id
                                          }/branches`,
                                      ])) ? "open" : "")
                                      }`}
                                      key={business._id}
                                    >
                                        <div
                                          className={`item-content ${dropdowns.dynamic[
                                          business._id + "branches"
                                            ] && "active"
                                          }`}
                                          onClick={() =>
                                            handleDropdownChange(
                                              business._id + "branches", true
                                            )
                                          }
                                        >
                                            <i className="item-icon icon-business-building-location"/>
                                            <span className="item-text">{__("Branches")}</span>
                                            <i className="arrow icon-chevron"/>
                                        </div>
                                        <div className="dropdown-body p-0">
                                            <div
                                              className="item"
                                              onClick={() =>
                                                navigate(
                                                  `/businesses/${business._id}/branches`,
                                                  {
                                                      state: {
                                                          business: business,
                                                      },
                                                  },
                                                )
                                              }
                                            >
                                                <div
                                                  className={`item-content ${isCurrentPage(
                                                    `${business._id}/branches`,
                                                  )
                                                    ? "active"
                                                    : ""
                                                  }`}
                                                >
                                                    <i
                                                      className="item-icon icon-information"
                                                    />
                                                    <span className="item-text">{__("View All")}</span>
                                                </div>
                                            </div>
                                            {business.branches && business.branches.length > 0 &&
                                              business.branches.map(branch => (
                                                <div key={branch._id}
                                                     className={`item dropdown 
                                                                    ${(dropdowns.dynamic["branch_" + branch._id] ||
                                                     (isParentOfCurrentPage([
                                                         `/${branch.slug ||
                                                         branch._id
                                                         }/branches`,
                                                     ])) ? "open" : "")
                                                     }`}
                                                >
                                                    {/* Branch Name */}
                                                    <div
                                                      className={`item-content ${isCurrentPage(
                                                        `${branch.slug ||
                                                        branch._id
                                                        }`,
                                                      )
                                                        ? "active"
                                                        : ""
                                                      }`}
                                                      onClick={() => {
                                                          handleDropdownChange(
                                                            "branch_" + branch._id, true
                                                          );
                                                      }
                                                      }
                                                    >
                                                        <i className="item-icon icon-branch"/>
                                                        <span className="item-text">{branch.name}</span>
                                                        <i className="arrow icon-chevron"/>
                                                    </div>
                                                    <div className="dropdown-body no-line">
                                                        <div className="item"
                                                             onClick={() =>
                                                               navigate(
                                                                 `/branches/${branch._id}`,
                                                                 {
                                                                     state: {
                                                                         business: business,
                                                                         branch: branch
                                                                     },
                                                                 },
                                                               )
                                                             }
                                                        >
                                                            <div
                                                              className={`item-content ${isCurrentPage(
                                                                `${branch.slug ||
                                                                branch._id
                                                                }`,
                                                                true,
                                                              )
                                                                ? "active"
                                                                : ""
                                                              }`}
                                                            >
                                                                <i className="item-icon icon-information"/>
                                                                <span className="item-text">{__("Information")}</span>
                                                            </div>
                                                        </div>

                                                        {/* Branch Cashdesks */}
                                                        <div
                                                          className={`item dropdown
                                                                            ${(dropdowns.dynamic["branch_" + branch._id + "_cashdesks"] ||
                                                          (isParentOfCurrentPage([
                                                              `/${business.slug ||
                                                              business._id
                                                              }/branches`,
                                                          ])) ? "open" : "")
                                                          }`}
                                                          key={business._id}
                                                        >
                                                            <div
                                                              className={`item-content ${dropdowns.dynamic[
                                                              "branch_" + branch._id + "_cashdesks"
                                                                ] && "active"
                                                              }`}
                                                              onClick={() =>
                                                                handleDropdownChange(
                                                                  "branch_" + branch._id + "_cashdesks", true
                                                                )
                                                              }
                                                            >
                                                                <i
                                                                  className="item-icon icon-business-building-location"/>
                                                                <span className="item-text">{__("Cashdesks")}</span>
                                                                <i className="arrow icon-chevron"/>
                                                            </div>
                                                            <div className="dropdown-body p-0">
                                                                <div
                                                                  className="item"
                                                                  onClick={() =>
                                                                    navigate(
                                                                      `/businesses/${business.slug || business._id}/branches/${branch.slug || branch._id}/cashdesks`,
                                                                      {
                                                                          state: {
                                                                              business: business,
                                                                              branch: branch
                                                                          },
                                                                      },
                                                                    )
                                                                  }
                                                                >
                                                                    <div
                                                                      className={`item-content ${isCurrentPage(
                                                                        `/businesses/${business.slug || business._id}/branches/${branch.slug || branch._id}/cashdesks`, true
                                                                      )
                                                                        ? "active"
                                                                        : ""
                                                                      }`}
                                                                    >
                                                                        <i className="item-icon icon-information"/>
                                                                        <span
                                                                          className="item-text">{__("View All")}</span>
                                                                    </div>
                                                                </div>
                                                                {branch.cashdesks && branch.cashdesks.length > 0 &&
                                                                  branch.cashdesks.map(cashdesk => (
                                                                    <div key={cashdesk._id}
                                                                         className={`item dropdown
                                                                                            ${(dropdowns.dynamic["cashdesk" + cashdesk._id] ||
                                                                         (isParentOfCurrentPage([
                                                                             `/${cashdesk.uniqueID ||
                                                                             cashdesk._id
                                                                             }/branches`,
                                                                         ])) ? "open" : "")
                                                                         }`}
                                                                         onClick={() => {
                                                                             handleDropdownChange(
                                                                               "cashdesk" + cashdesk._id, true
                                                                             );
                                                                         }
                                                                         }
                                                                    >
                                                                        <div
                                                                          className={`item-content ${isCurrentPage(
                                                                            `${cashdesk.uniqueID ||
                                                                            cashdesk._id
                                                                            }`,
                                                                          )
                                                                            ? "active"
                                                                            : ""
                                                                          }`}
                                                                        >
                                                                            <i className="item-icon icon-cashdesk"/>
                                                                            <span
                                                                              className="item-text">{cashdesk.uniqueID}</span>
                                                                            <i className="arrow icon-chevron"/>
                                                                        </div>
                                                                        <div className="dropdown-body no-line">
                                                                            <div className="item"
                                                                                 onClick={() =>
                                                                                   navigate(
                                                                                     `/businesses/${business.slug || business._id}/branches/${branch.slug || branch._id}/cashdesks/${cashdesk.uniqueID}`,
                                                                                     {
                                                                                         state: {
                                                                                             business: business,
                                                                                             branch: branch,
                                                                                             cashdesk: {
                                                                                                 id: cashdesk._id,
                                                                                                 slug: cashdesk.uniqueID
                                                                                             }
                                                                                         },
                                                                                     },
                                                                                   )
                                                                                 }
                                                                            >
                                                                                <div
                                                                                  className={`item-content ${isCurrentPage(
                                                                                    `${business.slug ||
                                                                                    business._id
                                                                                    }`,
                                                                                    true,
                                                                                  )
                                                                                    ? "active"
                                                                                    : ""
                                                                                  }`}
                                                                                >
                                                                                    <i
                                                                                      className="item-icon icon-information"/>
                                                                                    <span
                                                                                      className="item-text">{__("Information")}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                  ))
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                              ))
                                            }
                                        </div>
                                    </div>

                                    {/* Business Cashdesks */}
                                    <div
                                      className={"item"}
                                      onClick={() =>
                                        navigate(
                                          `/businesses/${business.slug ||
                                          business._id
                                          }/cashdesks`,
                                          {
                                              state: {
                                                  business:
                                                  business,
                                              },
                                          },
                                        )
                                      }
                                    >
                                        <div
                                          className={`item-content ${isCurrentPage(
                                            `${business.slug ||
                                            business._id
                                            }/cashdesks`,
                                          )
                                            ? "active"
                                            : ""
                                          }`}
                                        >
                                            <i className="item-icon icon-cashdesk"/>
                                            <span className="item-text">
                                                {__("Cashdesks")}
                                            </span>
                                        </div>
                                    </div>

                                    {/* Categories */}
                                    <div
                                      className={"item"}
                                      onClick={() =>
                                        navigate(
                                          `/businesses/${business.slug ||
                                          business._id
                                          }/categories`,
                                          {
                                              state: {
                                                  business:
                                                  business,
                                              },
                                          },
                                        )
                                      }
                                    >
                                        <div
                                          className={`item-content ${isCurrentPage(
                                            `${business.slug ||
                                            business._id
                                            }/categories`,
                                          )
                                            ? "active"
                                            : ""
                                          }`}
                                        >
                                            <i className="item-icon icon-bx-category"/>
                                            <span className="item-text">{__("Categories")}</span>
                                        </div>
                                    </div>

                                    {/* Products */}
                                    <div
                                      className={"item"}
                                      onClick={() =>
                                        navigate(
                                          `/businesses/${business.slug ||
                                          business._id
                                          }/products`,
                                          {
                                              state: {
                                                  business:
                                                  business,
                                              },
                                          },
                                        )
                                      }
                                    >
                                        <div
                                          className={`item-content ${isCurrentPage(
                                            `${business.slug ||
                                            business._id
                                            }/products`,
                                          )
                                            ? "active"
                                            : ""
                                          }`}
                                        >
                                            <i className="item-icon icon-inventory"/>
                                            <span className="item-text">{__("Products")}</span>
                                        </div>
                                    </div>

                                    {warehouseStatus && (<>
                                        {/* Warehouse route */}
                                        <div
                                            className={`item dropdown ${isCurrentPage("warehouse") ? "open" : ""}`}
                                        >
                                            <div
                                                className={`item-content ${isCurrentPage(
                                                    `${business._id}/warehouse`,
                                                )
                                                    ? "active"
                                                    : ""
                                                }`}
                                                onClick={() =>  {
                                                    navigate(`/businesses/${business._id}/warehouse`,
                                                        {
                                                            state: {
                                                                business: business,
                                                            },
                                                        });
                                                }}
                                            >
                                                <i className="item-icon icon-warehouse" />
                                                <span className="item-text">{__("Warehouse")}</span>
                                            </div>
                                            {/* Warehouse Branches list */}
                                            <div className="dropdown-body no-line">
                                                {business.branches && business.branches.length > 0 &&
                                                    business.branches.map(branch => (
                                                        <div key={branch._id} className="item">
                                                            {/* Branch Name */}
                                                            <div
                                                                className={`item-content ${isCurrentPage(branch._id)
                                                                    ? "active"
                                                                    : ""
                                                                }`}
                                                                onClick={() => navigate(branch._id, {
                                                                    state: {
                                                                        business,
                                                                        branchName: branch.name
                                                                    }
                                                                })}
                                                            >
                                                                <i className="item-icon icon-branch" />
                                                                <span className="item-text">{branch.name}</span>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>

                                        {/* Arrivals route */}
                                        <div
                                            className="item"
                                            onClick={() => navigate(`/businesses/${business._id}/arrivals`, {
                                                state: {
                                                    business: business,
                                                },
                                            })}
                                        >
                                            <div
                                                className={`item-content ${isCurrentPage(`${business._id}/arrivals`)
                                                    ? "active"
                                                    : ""
                                                }`}
                                            >
                                                <i className="item-icon icon-arrival" />
                                                <span className="item-text">{__("Arrivals")}</span>
                                            </div>
                                        </div>

                                        {/* Transfers route */}
                                        <div
                                            className="item"
                                            onClick={() => navigate(`/businesses/${business._id}/transfers`, {
                                              state: {
                                                  business: business,
                                              },
                                          },)}
                                        >
                                            <div
                                              className={`item-content ${isCurrentPage(
                                                `${business._id}/transfers`,
                                              )
                                                ? "active"
                                                : ""
                                              }`}
                                            >
                                                <i className="item-icon icon-transfer"/>
                                                <span className="item-text">{__("Transfers")}</span>
                                            </div>
                                        </div>

                                        {/* Withdrawals route */}
                                        <div
                                          className="item"
                                          onClick={() => navigate(`/businesses/${business._id}/withdrawals`, {
                                              state: {
                                                  business: business,
                                              },
                                          },)}
                                        >
                                            <div
                                              className={`item-content ${isCurrentPage(
                                                `${business._id}/withdrawals`,
                                              )
                                                ? "active"
                                                : ""
                                              }`}
                                            >
                                                <i className="item-icon icon-withdrawal"/>
                                                <span className="item-text">{__("Withdrawals")}</span>
                                            </div>
                                        </div>
                                    </>)}

                                    {/* Receipts */}
                                    <div
                                      className={"item"}
                                      onClick={() =>
                                        navigate(
                                          `/businesses/${business._id}/receipts`,
                                          {
                                              state: { business },
                                          },
                                        )
                                      }
                                    >
                                        <div
                                          className={`item-content ${isCurrentPage(
                                            `${business.slug ||
                                            business._id
                                            }/receipts`,
                                          )
                                            ? "active"
                                            : ""
                                          }`}
                                        >
                                            <i className="item-icon icon-receipt-money"/>
                                            <span className="item-text">{__("Receipts")}</span>
                                        </div>
                                    </div>

                                    {/* Business Roles */}
                                    <div
                                      className={"item"}
                                      onClick={() =>
                                        navigate(`/businesses/${business?.slug || business?._id}/roles`,
                                          {
                                              state: {
                                                  business:
                                                  business,
                                              },
                                          },
                                        )
                                      }
                                    >
                                        <div
                                          className={`item-content ${isCurrentPage(
                                            `${business?.slug || business?._id}/roles`,
                                          )
                                            ? "active"
                                            : ""
                                          }`}
                                        >
                                            <FontAwesomeIcon
                                              icon="fa-solid fa-user-tie"
                                              className="item-icon"
                                              fixedWidth
                                            />
                                            <span className="item-text">{__("Roles")}</span>
                                        </div>
                                    </div>

                                    {/* Staff */}
                                    <div
                                      className={"item"}
                                      onClick={() =>
                                        navigate(`/businesses/${business?.slug || business?.id}/staff`,
                                          {
                                              state: {
                                                  business: {
                                                      id: business?._id,
                                                      slug: business?.slug,
                                                  }
                                              },
                                          },
                                        )
                                      }
                                    >
                                        <div
                                          className={`item-content ${isCurrentPage(
                                            `${business.slug ||
                                            business._id
                                            }/staff`,
                                          )
                                            ? "active"
                                            : ""
                                          }`}
                                        >
                                            <FontAwesomeIcon
                                              icon="fa-solid fa-user-tie"
                                              className="item-icon"
                                              fixedWidth
                                            />
                                            <span className="item-text">{__("Staff")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }

                        {/* Branches */}
                        <div className={"item"}>
                            <div
                              className={`item-content ${isCurrentPage("branches") ? "active" : ""
                              }`}
                              onClick={() => navigate("/branches", {})}
                            >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-shop"
                                  className="item-icon"
                                  fixedWidth
                                />
                                <span className="item-text">{__("Branches")}</span>
                            </div>
                        </div>

                        {/* Cashdesks */}
                        <div className={"item"}>
                            <div
                              className={`item-content ${isCurrentPage("cashdesks") ? "active" : ""
                              }`}
                              onClick={() => navigate("/cashdesks")}
                            >
                                <FontAwesomeIcon
                                  icon="fa-solid fa-cash-register"
                                  className="item-icon"
                                  fixedWidth
                                />
                                <span className="item-text">{__("Cashdesks")}</span>
                            </div>
                        </div>

                        {/* Global Roles */}
                        {!isDealer && (
                          <div className={"item"}>
                              <div
                                className={`item-content ${isCurrentPage("roles") ? "active" : ""
                                }`}
                                onClick={() => navigate("/roles")}
                              >
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-user-tie"
                                    className="item-icon"
                                    fixedWidth
                                  />
                                  <span className="item-text">{__("Roles")}</span>
                              </div>
                          </div>
                        )}
                    </div>
                )}
                {isClient && (
                    <div className={`sidebar-items ${!isOpen && "sidebar-items-centered"}`}>
                        <div
                            className={`item dropdown ${(
                                (dropdowns["businessesDropdown"]
                                ) && "open"
                            )
                                || ""
                                }`}
                        >
                            {/* Businesses */}
                            <div
                                className={`item-content ${isCurrentPage("/businesses", true)
                                    ? "active"
                                    : ""
                                    }`}
                                onClick={() =>
                                    handleDropdownChange("businessesDropdown")
                                }
                            >
                                <i className="item-icon icon-businesses" />
                                <span className="item-text">{__("Businesses")}</span>
                                <i className="arrow icon-chevron" />
                            </div>
                            <div className="dropdown-body p-0">
                                <div
                                    className="item"
                                    onClick={() =>
                                        navigate(
                                            "/businesses"
                                        )
                                    }
                                >
                                    <div
                                        className={`item-content ${isCurrentPage(
                                            "/businesses", true
                                        )
                                            ? "active"
                                            : ""
                                            }`}
                                    >
                                        <i className="item-icon icon-information" />
                                        <span className="item-text">{__("View All")}</span>
                                    </div>
                                </div>
                                {businesses.length > 0 &&
                                    businesses.map((business) => (
                                        <div
                                            className={`item dropdown
                                    ${(dropdowns.dynamic["bus" + business._id] ||
                                                    (isParentOfCurrentPage([
                                                        `/${business.slug ||
                                                        business._id
                                                        }`,
                                                        `/${business.slug ||
                                                        business._id
                                                        }/branches`,
                                                        `/${business.slug ||
                                                        business._id
                                                        }/cashdesks`,
                                                        `/${business.slug ||
                                                        business._id
                                                        }/categories`,
                                                    ])) ?
                                                    "open" : "")
                                                }`}
                                            key={business._id}
                                        >
                                            {/* Business Name */}
                                            <div
                                                className={`item-content ${dropdowns.dynamic[
                                                    "bus" + business._id
                                                ] && "active"
                                                    }`}
                                                onClick={() =>
                                                    handleDropdownChange(
                                                        "bus" + business._id,
                                                        true,
                                                    )
                                                }
                                            >
                                                <i className="item-icon icon-business-building-location" />
                                                <span className="item-text">{business.name}</span>
                                                <i className="arrow icon-chevron" />
                                            </div>
                                            <div className="dropdown-body no-line">
                                                <div
                                                    className="item"
                                                    onClick={() =>
                                                        navigate(
                                                            `/businesses/${business._id}`,
                                                            {
                                                                state: {
                                                                    business: {
                                                                        _id: business._id,
                                                                        slug: business.slug
                                                                    }
                                                                },
                                                            },
                                                        )
                                                    }
                                                >
                                                    {/* Business Information */}
                                                    <div
                                                        className={`item-content ${isCurrentPage(
                                                            `${business.slug ||
                                                            business._id
                                                            }`,
                                                            true,
                                                        )
                                                            ? "active"
                                                            : ""
                                                            }`}
                                                    >
                                                        <i className="item-icon icon-information" />
                                                        <span className="item-text">
                                                            {__("Information")}
                                                        </span>
                                                    </div>
                                                </div>

                                                {/* Business Branches */}
                                                <div
                                                    className={`item dropdown
                                                    ${(dropdowns.dynamic[business._id + "branches"] ||
                                                            (isParentOfCurrentPage([
                                                                `/${business.slug ||
                                                                business._id
                                                                }/branches`,
                                                            ])) ? "open" : "")
                                                        }`}
                                                    key={business._id}
                                                >
                                                    <div
                                                        className={`item-content ${dropdowns.dynamic[
                                                            business._id + "branches"
                                                        ] && "active"
                                                            }`}
                                                        onClick={() =>
                                                            handleDropdownChange(
                                                                business._id + "branches", true
                                                            )
                                                        }
                                                    >
                                                        <i className="item-icon icon-business-building-location" />
                                                        <span className="item-text">{__("Branches")}</span>
                                                        <i className="arrow icon-chevron" />
                                                    </div>
                                                    <div className="dropdown-body p-0">
                                                        <div
                                                            className="item"
                                                            onClick={() =>
                                                                navigate(
                                                                    `/businesses/${business._id}/branches`,
                                                                    {
                                                                        state: {
                                                                            business: business,
                                                                        },
                                                                    },
                                                                )
                                                            }
                                                        >
                                                            <div
                                                                className={`item-content ${isCurrentPage(
                                                                    `${business.slug ||
                                                                    business._id
                                                                    }/branches`,
                                                                )
                                                                    ? "active"
                                                                    : ""
                                                                    }`}
                                                            >
                                                                <i
                                                                    className="item-icon icon-information"
                                                                />
                                                                <span className="item-text">{__("View All")}</span>
                                                            </div>
                                                        </div>
                                                        {business.branches && business.branches.length > 0 &&
                                                            business.branches.map(branch => (
                                                                <div key={branch._id}
                                                                    className={`item dropdown 
                                                                    ${(dropdowns.dynamic["branch_" + branch._id] ||
                                                                            (isParentOfCurrentPage([
                                                                                `/${branch.slug ||
                                                                                branch._id
                                                                                }/branches`,
                                                                            ])) ? "open" : "")
                                                                        }`}
                                                                >
                                                                    {/* Branch Name */}
                                                                    <div
                                                                        className={`item-content ${isCurrentPage(
                                                                            `${branch.slug ||
                                                                            branch._id
                                                                            }`,
                                                                        )
                                                                            ? "active"
                                                                            : ""
                                                                            }`}
                                                                        onClick={() => {
                                                                            handleDropdownChange(
                                                                                "branch_" + branch._id, true
                                                                            );
                                                                        }
                                                                        }
                                                                    >
                                                                        <i className="item-icon icon-branch" />
                                                                        <span className="item-text">{branch.name}</span>
                                                                        <i className="arrow icon-chevron" />
                                                                    </div>
                                                                    <div className="dropdown-body no-line">
                                                                        <div className="item"
                                                                            onClick={() =>
                                                                                navigate(
                                                                                    `/branches/${branch._id}`,
                                                                                    {
                                                                                        state: {
                                                                                            business: business,
                                                                                            branch: branch
                                                                                        },
                                                                                    },
                                                                                )
                                                                            }
                                                                        >
                                                                            {/* Branch Information */}
                                                                            <div
                                                                                className={`item-content ${isCurrentPage(
                                                                                    `${branch.slug ||
                                                                                    branch._id
                                                                                    }`,
                                                                                    true,
                                                                                )
                                                                                    ? "active"
                                                                                    : ""
                                                                                    }`}
                                                                            >
                                                                                <i className="item-icon icon-information" />
                                                                                <span className="item-text">{__("Information")}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className={`item dropdown
                                                                            ${(dropdowns.dynamic["branch_" + branch._id + "_cashdesks"] ||
                                                                                    (isParentOfCurrentPage([
                                                                                        `/${business.slug ||
                                                                                        business._id
                                                                                        }/branches`,
                                                                                    ])) ? "open" : "")
                                                                                }`}
                                                                            key={business._id}
                                                                        >
                                                                            {/* Branch Cashdesks */}
                                                                            <div
                                                                                className={`item-content ${dropdowns.dynamic[
                                                                                    "branch_" + branch._id + "_cashdesks"
                                                                                ] && "active"
                                                                                    }`}
                                                                                onClick={() =>
                                                                                    handleDropdownChange(
                                                                                        "branch_" + branch._id + "_cashdesks", true
                                                                                    )
                                                                                }
                                                                            >
                                                                                <i className="item-icon icon-business-building-location" />
                                                                                <span className="item-text">{__("Cashdesks")}</span>
                                                                                <i className="arrow icon-chevron" />
                                                                            </div>
                                                                            <div className="dropdown-body p-0">
                                                                                <div
                                                                                    className="item"
                                                                                    onClick={() =>
                                                                                        navigate(
                                                                                            `/businesses/${business.slug || business._id}/branches/${branch.slug || branch._id}/cashdesks`,
                                                                                            {
                                                                                                state: {
                                                                                                    business: business,
                                                                                                    branch: branch
                                                                                                },
                                                                                            },
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className={`item-content ${isCurrentPage(
                                                                                            `/businesses/${business.slug || business._id}/branches/${branch.slug || branch._id}/cashdesks`, true
                                                                                        )
                                                                                            ? "active"
                                                                                            : ""
                                                                                            }`}
                                                                                    >
                                                                                        <i className="item-icon icon-information" />
                                                                                        <span className="item-text">{__("View All")}</span>
                                                                                    </div>
                                                                                </div>
                                                                                {branch.cashdesks && branch.cashdesks.length > 0 &&
                                                                                    branch.cashdesks.map(cashdesk => (
                                                                                        <div key={cashdesk._id}
                                                                                            className={`item dropdown
                                                                                            ${(dropdowns.dynamic["cashdesk" + cashdesk._id] ||
                                                                                                    (isParentOfCurrentPage([
                                                                                                        `/${cashdesk.uniqueID ||
                                                                                                        cashdesk._id
                                                                                                        }/branches`,
                                                                                                    ])) ? "open" : "")
                                                                                                }`}
                                                                                            onClick={() => {
                                                                                                handleDropdownChange(
                                                                                                    "cashdesk" + cashdesk._id, true
                                                                                                );
                                                                                            }
                                                                                            }
                                                                                        >
                                                                                            <div
                                                                                                className={`item-content ${isCurrentPage(
                                                                                                    `${cashdesk.uniqueID ||
                                                                                                    cashdesk._id
                                                                                                    }`,
                                                                                                )
                                                                                                    ? "active"
                                                                                                    : ""
                                                                                                    }`}
                                                                                            >
                                                                                                <i className="item-icon icon-branch" />
                                                                                                <span className="item-text">{cashdesk.uniqueID}</span>
                                                                                                <i className="arrow icon-chevron" />
                                                                                            </div>
                                                                                            <div className="dropdown-body no-line">
                                                                                                <div className="item"
                                                                                                    onClick={() =>
                                                                                                        navigate(
                                                                                                            `/businesses/${business.slug || business._id}/branches/${branch.slug || branch._id}/cashdesks/${cashdesk.uniqueID}`,
                                                                                                            {
                                                                                                                state: {
                                                                                                                    business: business,
                                                                                                                    branch: branch,
                                                                                                                    cashdesk: {
                                                                                                                        id: cashdesk._id,
                                                                                                                        slug: cashdesk.uniqueID
                                                                                                                    }
                                                                                                                },
                                                                                                            },
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    <div
                                                                                                        className={`item-content ${isCurrentPage(
                                                                                                            `${business.slug ||
                                                                                                            business._id
                                                                                                            }`,
                                                                                                            true,
                                                                                                        )
                                                                                                            ? "active"
                                                                                                            : ""
                                                                                                            }`}
                                                                                                    >
                                                                                                        <i className="item-icon icon-information" />
                                                                                                        <span className="item-text">{__("Information")}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                {/* Business Cashdesks*/}
                                                <div
                                                    className={"item"}
                                                    onClick={() =>
                                                        navigate(
                                                            `/businesses/${business.slug ||
                                                            business._id
                                                            }/cashdesks`,
                                                            {
                                                                state: {
                                                                    business:
                                                                        business,
                                                                },
                                                            },
                                                        )
                                                    }
                                                >
                                                    <div
                                                        className={`item-content ${isCurrentPage(
                                                            `${business.slug ||
                                                            business._id
                                                            }/cashdesks`,
                                                        )
                                                            ? "active"
                                                            : ""
                                                            }`}
                                                    >
                                                        <i className="item-icon icon-cashdesk" />
                                                        <span className="item-text">
                                                            {__("Cashdesks")}
                                                        </span>
                                                    </div>
                                                </div>
                                                {/* Business Categories */}
                                                <div
                                                    className={"item"}
                                                    onClick={() =>
                                                        navigate(
                                                            `/businesses/${business.slug ||
                                                            business._id
                                                            }/categories`,
                                                            {
                                                                state: {
                                                                    business:
                                                                        business,
                                                                },
                                                            },
                                                        )
                                                    }
                                                >
                                                    <div
                                                        className={`item-content ${isCurrentPage(
                                                            `${business.slug ||
                                                            business._id
                                                            }/categories`,
                                                        )
                                                            ? "active"
                                                            : ""
                                                            }`}
                                                    >
                                                        <i className="item-icon icon-bx-category" />
                                                        <span className="item-text">{__("Categories")}</span>
                                                    </div>
                                                </div>

                                                {/* Products */}
                                                <div
                                                    className={"item"}
                                                    onClick={() =>
                                                        navigate(
                                                            `/businesses/${business.slug ||
                                                            business._id
                                                            }/products`,
                                                            {
                                                                state: {
                                                                    business:
                                                                        business,
                                                                },
                                                            },
                                                        )
                                                    }
                                                >
                                                    <div
                                                        className={`item-content ${isCurrentPage(
                                                            `${business.slug ||
                                                            business._id
                                                            }/products`,
                                                        )
                                                            ? "active"
                                                            : ""
                                                            }`}
                                                    >
                                                        <i className="item-icon icon-inventory" />
                                                        <span className="item-text">{__("Products")}</span>
                                                    </div>
                                                </div>

                                                {warehouseStatus && (<>
                                                    {/* Warehouse route */}
                                                    <div
                                                        className={`item dropdown ${isCurrentPage("warehouse") ? "open" : ""}`}
                                                    >
                                                        <div
                                                            className={`item-content ${isCurrentPage(
                                                                `${business._id}/warehouse`,
                                                            )
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                            onClick={() => {
                                                                navigate(`/businesses/${business._id}/warehouse`,
                                                                    {
                                                                        state: {
                                                                            business: business,
                                                                        },
                                                                    });
                                                            }}
                                                        >
                                                            <i className="item-icon icon-warehouse" />
                                                            <span className="item-text">{__("Warehouse")}</span>
                                                        </div>
                                                        {/* Warehouse Branches list */}
                                                        <div className="dropdown-body no-line">
                                                            {business.branches && business.branches.length > 0 &&
                                                                business.branches.map(branch => (
                                                                    <div key={branch._id} className="item">
                                                                        {/* Branch Name */}
                                                                        <div
                                                                            className={`item-content ${isCurrentPage(branch._id)
                                                                                ? "active"
                                                                                : ""
                                                                            }`}
                                                                            onClick={() => navigate(branch._id, {
                                                                                state: {
                                                                                    business,
                                                                                    branchName: branch.name,
                                                                                },
                                                                            })}
                                                                        >
                                                                            <i className="item-icon icon-branch" />
                                                                            <span
                                                                                className="item-text">{branch.name}</span>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>

                                                    {/* Arrivals route */}
                                                    <div
                                                        className="item"
                                                        onClick={() => navigate(`/businesses/${business._id}/arrivals`, {
                                                            state: {
                                                                business: business,
                                                            },
                                                        })}
                                                    >
                                                        <div
                                                            className={`item-content ${isCurrentPage(`${business._id}/arrivals`)
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                        >
                                                            <i className="item-icon icon-arrival" />
                                                            <span className="item-text">{__("Arrivals")}</span>
                                                        </div>
                                                    </div>

                                                    {/* Transfers route */}
                                                    <div
                                                        className="item"
                                                        onClick={() => navigate(`/businesses/${business._id}/transfers`, {
                                                            state: {
                                                                business: business,
                                                            },
                                                        })}
                                                    >
                                                        <div
                                                            className={`item-content ${isCurrentPage(
                                                                `${business._id}/transfers`,
                                                            )
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                        >
                                                            <i className="item-icon icon-transfer" />
                                                            <span className="item-text">{__("Transfers")}</span>
                                                        </div>
                                                    </div>

                                                    {/* Withdrawals route */}
                                                    <div
                                                        className="item"
                                                        onClick={() => navigate(`/businesses/${business._id}/withdrawals`, {
                                                            state: {
                                                                business: business,
                                                            },
                                                      },)}
                                                    >
                                                        <div
                                                          className={`item-content ${isCurrentPage(
                                                            `${business._id}/withdrawals`,
                                                          )
                                                            ? "active"
                                                            : ""
                                                          }`}
                                                        >
                                                            <i className="item-icon icon-withdrawal"/>
                                                            <span className="item-text">{__("Withdrawals")}</span>
                                                        </div>
                                                    </div>
                                                </>)}

                                                {/* Receipts */}
                                                <div
                                                    className={"item"}
                                                    onClick={() =>
                                                        navigate(
                                                            `/businesses/${business._id}/receipts`,
                                                            {
                                                                state: {
                                                                    business:
                                                                        business,
                                                                },
                                                            },
                                                        )
                                                    }
                                                >
                                                    <div
                                                        className={`item-content ${isCurrentPage(
                                                            `${business.slug ||
                                                            business._id
                                                            }/receipts`,
                                                        )
                                                            ? "active"
                                                            : ""
                                                            }`}
                                                    >
                                                        <i className="item-icon icon-receipt-money" />
                                                        <span className="item-text">{__("Receipts")}</span>
                                                    </div>
                                                </div>

                                                {/* Staff */}
                                                <div
                                                    className={"item"}
                                                    onClick={() =>
                                                        navigate(`/businesses/${business?.slug || business?.id}/staff`,
                                                            {
                                                                state: {
                                                                    business: {
                                                                        id: business?._id,
                                                                        slug: business?.slug,
                                                                    }
                                                                },
                                                            },
                                                        )
                                                    }
                                                >
                                                    <div
                                                        className={`item-content ${isCurrentPage("/businesses/:businessID/staff")
                                                            ? "active"
                                                            : ""
                                                            }`}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon="fa-solid fa-user-tie"
                                                            className="item-icon"
                                                            fixedWidth
                                                        />
                                                        <span className="item-text">{__("Staff")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div
                                    className="item"
                                    onClick={() =>
                                        navigate(
                                            "/businesses/create"
                                        )
                                    }
                                >
                                    <div
                                        className={`item-content ${isCurrentPage(
                                            "/businesses/create"
                                        )
                                            ? "active"
                                            : ""
                                            }`}
                                    >
                                        <i className="item-icon icon-add" />
                                        <span className="item-text">{__("Add Business")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Branches */}
                        <div
                            className="item"
                            onClick={() =>
                                navigate("/branches")
                            }
                        >
                            <div
                                className={`item-content ${isCurrentPage(
                                    "/branches",
                                )
                                    ? "active"
                                    : ""
                                    }`}
                            >
                                <i className="item-icon icon-business-building-location" />
                                <span className="item-text">{__("Branches")}</span>
                            </div>
                        </div>

                        {/* Cashdesks */}
                        <div
                            className={"item"}
                            onClick={() =>
                                navigate(
                                    "/cashdesks"
                                )
                            }
                        >
                            <div
                                className={`item-content ${isCurrentPage("/cashdesks",
                                )
                                    ? "active"
                                    : ""
                                    }`}
                            >
                                <i className="item-icon icon-cashdesk" />
                                <span className="item-text">{__("Cashdesks")}</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="company-info">
                <div className="company-info--container">
                    <p className="company-info--content">{__("LLC")} “E-POS Systems”, <br/>
                        {__("The Republic of Uzbekistan")}
                    </p>
                    <p className="company-info--content">”E-POS Management” v1.0.0</p>
                </div>
            </div>
        </div>
    );
};

export default SidebarNavigation;
