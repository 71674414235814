import config from "../../config";
import useApiHook from "../../hooks/useApiHook";
import {capitalize} from "../capitalize";

const getStaffRoles = (businessID) => {
    // Retrieve available Staff Roles
    const rolesURL = `${config.API_BASE_URL}/businesses/${businessID}/staff/roles`;
    const {data: rolesData} = useApiHook(rolesURL);
    return rolesData?.data?.docs?.map((item) => {
        return {
            value: item._id,
            label: capitalize(item.title)
        };
    });
};

export default getStaffRoles;