import {
    AUTH_FAIL,
    AUTH_START,
    AUTH_SUCCESS,
    ROLE_SUCCESS,
    LOGOUT,
} from "../actionTypes/authActionTypes";
import { createReducer } from "../store/helper";

const initialState = {
    token: null,
    error: null,
    loading: false,
    role: "user",
    profileData: null,
};

const authReducer = {
    [AUTH_SUCCESS]: (state = initialState, action) => {
        return {
            ...state,
            token: action.payload.accessToken,
            profileData: action.payload,
            error: null,
            loading: false,
        };
    },
    [ROLE_SUCCESS]: (state = initialState, action) => {
        return {
            ...state,
            role: action.payload,
            profileData: state.profileData,
            token: state.token,
            error: null,
            loading: false,
        };
    },
    [AUTH_FAIL]: (state = initialState, action) => {
        return {
            ...state,
            error: action.payload,
            loading: false,
            token: null,
            profileData: null,
            role: null,
        };
    },
    [AUTH_START]: (state = initialState) => {
        return {
            ...state,
            profileData: null,
            error: null,
            loading: true,
            token: null,
            role: null,
        };
    },
    [LOGOUT]: (state = initialState) => {
        return {
            ...state,
            profileData: null,
            error: null,
            loading: true,
            token: null,
            role: null,
        };
    },
};

export default createReducer(initialState, authReducer);
