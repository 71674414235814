import React, { useEffect } from "react";
import PropTypes from "prop-types";
import xIcon from "../../../assets/icons/icon_delete.svg";
import "./styles.sass";
const Input = (props) => {
    const blockRef = React.createRef();
    const makeVisible = function () {
        blockRef.current.classList.remove("with-animation");
        if (props.relative) return;
        blockRef.current.classList.add("visible");
    };
    useEffect(() => {
        makeVisible();
    });
    return (
        <div
            className={`component-card-input with-animation ${
                props.relative && "input_relative"
            }`}
            ref={blockRef}
        >
            <label className="card-label" htmlFor={props.htmlFor}>
                <input
                    onChange={props.onChange}
                    id={props.id}
                    className={props.className}
                    type={props.type}
                    placeholder={props.placeholder}
                />
                <p className="error-text">{props.text}</p>
            </label>
            {props.icon && (
                <div className="burger-active" onClick={props.onClick}>
                    <span className="burger-item" />
                    <span className="burger-item" />
                    <span className="burger-item" />
                    <span className="burger-item" />
                </div>
            )}
        </div>
    );
};
Input.propTypes = {
    htmlFor: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func,
};
export default Input;
