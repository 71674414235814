import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import useApiHook from "../../../hooks/useApiHook";

import { Box, colors, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import BreadCrumbs from "../../../components/BreadCrumbs";
import Filter from "../../../components/Filter";
import Preloader from "../../../components/Preloader";
import { NumericFormat } from "react-number-format";

import { updateTotalAction } from "../../../redux/reducers/paginationControllerReducer";
import { translate } from "../../../translations/translate";
import config from "../../../config";

const PageWarehouseTable = () => {
    const dispatch = useDispatch();
    // Lang selector
    const lang = useSelector((state) => state.languageReducer.lang);
    // Pagination controller
    const controller = useSelector(
        (state) => state.paginationControllerReducer,
    );
    // state and businessID from react-router-dom
    const { state } = useLocation();
    const { businessID } = useParams();
    const navigate = useNavigate();

    const baseUrl = `${config.API_BASE_URL}/businesses/${
        businessID || state?.business?._id
    }/products?`;

    // States
    const [url, setUrl] = useState(baseUrl);

    const { loading, data } = useApiHook(url);

    // functions / handlers
    const __ = (key) => translate(lang, key);

    const formatProductsArr = (products) => {
        return products?.map((product) => ({
            ...product,
            id: product._id,
        }));
    };

    // Effects
    useEffect(() => {
        setUrl(
            `${baseUrl}&page=${controller.page + 1}&limit=${
                controller.pageSize
            }`,
        );
    }, [controller.page, controller.pageSize]);

    useEffect(() => {
        if (data) {
            dispatch(updateTotalAction(parseInt(data?.data?.totalDocs) || 0));
        }
    }, [data]);

    const productColumn = [
        {
            field: "title",
            headerName: __("Title"),
            flex: 2,
            cellClassName: "main-column-cell",
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent={"flex-start"}
                        width={"100%"}
                        onClick={() => {
                            navigate(
                                `/businesses/${
                                    state?.business?.slug ||
                                    state?.business?._id ||
                                    businessID
                                }/products/${row.slug || row.id}`,
                                {
                                    state: {
                                        business: {
                                            id:
                                                state?.business._id ||
                                                businessID,
                                            slug:
                                                state?.business.slug ||
                                                businessID,
                                        },
                                        product: {
                                            id: row.id,
                                            slug: row.slug,
                                        },
                                    },
                                },
                            );
                        }}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={600}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {row?.title?.uz}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "icps",
            headerName: __("ICPS"),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={400}
                        fontSize={14}
                        color={colors.common["black"]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                    >
                        {row?.productTasnif?.icps}
                    </Typography>
                );
            },
        },
        {
            field: "purchasePrice",
            headerName: __("Purchase Price"),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={400}
                        fontSize={14}
                        color={colors.common["black"]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                    >
                        <NumericFormat
                            displayType="text"
                            value={row?.purchasePrice / 100 || "-"}
                            thousandSeparator={" "}
                            decimalSeparator={","}
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </Typography>
                );
            },
        },
        {
            field: "sellingPrice",
            headerName: __("Selling Price"),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={400}
                        fontSize={14}
                        color={colors.common["black"]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                    >
                        <NumericFormat
                            displayType="text"
                            value={row?.price / 100}
                            thousandSeparator={" "}
                            decimalSeparator={","}
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    </Typography>
                );
            },
        },
        {
            field: "inStock",
            flex: 1,
            headerName: __("In Stock"),
            renderCell: ({ row }) => {
                return (
                    <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={400}
                        fontSize={14}
                        color={colors.common["black"]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                    >
                        {row.inStock}
                    </Typography>
                );
            },
        },
        {
            field: "totalCount",
            flex: 1,
            headerName: __("Total Count"),
            renderCell: ({ row }) => {
                return (
                    <Typography
                        fontFamily="Manrope, sans-serif"
                        fontWeight={400}
                        fontSize={14}
                        color={colors.common["black"]}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                        whiteSpace={"nowrap"}
                    >
                        {row?.totalStored}
                    </Typography>
                );
            },
        },
    ];
    const tableRow = formatProductsArr(data?.data?.docs) || [];
    const buttons = [
        {
            text: __("Transfer"),
            link: `/businesses/${businessID}/transfers/create`,
            state: state,
            classNames: "outline",
        },
        {
            text: __("Add Withdrawal"),
            link: `/businesses/${businessID}/withdrawals/create`,
            state: state,
            classNames: "error",
        },
        {
            text: __("Add Arrival"),
            link: `/businesses/${businessID}/arrivals/create`,
            state: state,
        },
    ];
    const breadCrumbsArray = [{ label: __("Warehouse"), link: "" }];

    if (loading) return <Preloader />;

    return (
        <div className="page-withdrawal-table">
            <BreadCrumbs breadcrumbs={breadCrumbsArray} />
            <div className="page-table-branch">
                <Box>
                    <Filter
                        description={__("Warehouse")}
                        buttons={buttons}
                        // filters={filters}
                        // page={page}
                        // search={searchInputValue}
                        // setSearch={setSearchInputValue}
                    />
                    <Box
                        sx={{
                            "&": {
                                margin: "0",
                                width: "100%",
                            },
                            "& .name-column--cell": {
                                color: colors.green[300],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                            },
                        }}
                    >
                        <DataGrid
                            rows={tableRow}
                            rowCount={controller.total}
                            page={controller.page}
                            pageSize={controller.pageSize}
                            columns={productColumn}
                            {...config.TABLE_CONFIG}
                        />
                    </Box>
                </Box>
            </div>
        </div>
    );
};

export default PageWarehouseTable;
