import config from "../config";

export class ApiService {
    static _host = config.API_BASE_URL;
    static _apiBase = ApiService._host + "";
    static _headers = {
        "Content-Type": "application/json",
    };

    static postEvent = async (url, token, body) => {
        const headers = ApiService.createHeaders(token);
        const res = await fetch(ApiService._apiBase + url, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body),
        });
        const response = await res.json();
        response.status = res.status;
        if (res.ok) return response;
        if (!res.ok) {
            throw response;
        }
        return response;
    };
    static deleteEvent = async (url, token, body) => {
        const headers = ApiService.createHeaders(token);
        const res = await fetch(ApiService._apiBase + url, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(body)
        });
        const response = await res.json();
        response.status = res.status;
        if (res.ok) return response;
        if (!res.ok) {
            throw response;
        }
        return response;
    };

    static patchEvent = async (url, token, body) => {
        const headers = ApiService.createHeaders(token);
        const res = await fetch(ApiService._apiBase + url, {
            method: "PATCH",
            headers: headers,
            body: JSON.stringify(body),
        });
        const response = await res.json();
        response.status = res.status;
        if (res.ok) return response;
        if (!res.ok) {
            throw response;
        }
        return response;
    };

    static createHeaders = (token) => {
        let headers = ApiService._headers;
        if (token) {
            headers = {
                ...headers,
                Authorization: `Bearer ${token}`,
            };
        }
        return headers;
    };
    static getResources = async (url, token) => {
        const headers = ApiService.createHeaders(token);
        const res = await fetch(ApiService._apiBase + url, {
            headers: headers,
        });
        const response = await res.json();
        response.status = res.status;
        if (!res.ok) {
            throw response;
        }
        return response;
    };
}
