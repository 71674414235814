import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./styles.sass";
import { translate } from "../../translations/translate";
import { useSelector } from "react-redux";

const BreadCrumbs = ({ ...props }) => {
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    return (
        <div className="bread-crumbs">
            <div className="crumbs">
                <Link className="crumbs-link" key="Home" to="/">
                    {__("Home")}
                </Link>
            </div>
            {props.breadcrumbs?.map((item, index) => {
                return (
                    <div className="crumbs" key={index}>
                        {index === props.breadcrumbs.length - 1 ? (
                            <p className="crumbs-link disabled">
                                {__(item.label)}
                            </p>
                        ) : (
                            <Link
                                className="crumbs-link "
                                to={item.link}
                                state={item.state || {}}
                            >
                                {__(item.label)}
                            </Link>
                        )}
                    </div>
                );
            })}
        </div>
    );
};
export default BreadCrumbs;
