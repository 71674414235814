import fileDefault from "../../assets/images/NotFound.png";
import filePdf from "../../assets/images/user.png";
import fileExcel from "../../assets/images/excel.svg";

export const ImageConfig = {
    default: fileDefault,
    pdf: filePdf,
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet": fileExcel
    // png,
    // css,
};

export const MIMETypes = [

];

export const imageTypes = [
    "image/jpeg",
    "image/png",
    "image/webp",
    "image/svg+xml",
];