import React from "react";
import PropTypes from "prop-types";
import "../../assets/fonts/epos-icons/style.css";
import "./styles.sass";

const CheckBox = (props) => {
    return (
        <div className={`${props.className || ""}`}>
            <label htmlFor={props.htmlFor} className={`checkbox-label ${props.className === "no__padding" ? props.className : ""}`}>
                <input
                    className="bg "
                    type={props.inputType}
                    id={props.id}
                    name={props.name || ""}
                    value={props.value}
                    defaultChecked={props.defaultChecked}
                    checked={props.isChecked && "checked"}
                    onChange={props.onChange}
                    disabled={props.disabled}
                />
                <span className="checkbox" />
                <p className={props.titleClass}>{props.title}</p>
            </label>
        </div>
    );
};
CheckBox.propTypes = {
    id: PropTypes.string,
    htmlFor: PropTypes.string,
    titleClass: PropTypes.string,
    title: PropTypes.string,
    isChecked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    onChange: PropTypes.func,
    inputType: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
};

export default CheckBox;
