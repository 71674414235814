import React from "react";
import PropTypes from "prop-types";
import "./styles.sass";
import {useNavigate} from "react-router-dom";
import {translate} from "../../translations/translate";
import {useSelector} from "react-redux";
const ProductCard = (props) => {
    const navigate = useNavigate();
    const clickHandler =  props.onClick;
    const item = props.item;
    const lang = useSelector((state) => state.languageReducer.lang);

    const __ = (key) => translate(lang, key);
    return(
        <div className={"component-product-card " + props.classNames}
             onClick={props.link ? navigate(props.link, props.state) : clickHandler ? clickHandler : () => {}}
        >
            <div className="content">
                {(item.img || props.img) &&
                <div className="card-img-container"><img src="" alt="" className="img"/></div>
                }
                <div className="text-content">
                    <p className="component-card-name">{item.name?.ru || ""}</p>
                    <p className="component-card-name"> {__("ICPS")} : <span className="bold">{item.icps}</span></p>
                    {item.internationalCode &&
                        <p className="component-card-name">{__("Int. Code")} : <span
                        className="bold">{item.internationalCode}</span></p>
                    }
                </div>
            </div>
        </div>
    );
};

ProductCard.propTypes ={
    link: PropTypes.string,
    state: PropTypes.object,
    icps: PropTypes.string,
    internationalCode: PropTypes.string,
    _id: PropTypes.string,
    classNames: PropTypes.string
};

export default ProductCard;