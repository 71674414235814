import React from "react";
import "./styles.sass";

const Preloader = () => {
    return (
        <div className="preloader-page">
            <div className="loader">
                <hr />
                <hr />
                <hr />
                <hr />
            </div>
        </div>
    );
};

export default Preloader;
