export const SET_CHECKED = "SET_CHECKED";
export const SET_UNCHECKED = "SET_UNCHECKED";
export const SET_PRICE_RANGE = "SET_PRICE_RANGE";
export const SET_EXPIRATION = "SET_EXPIRATION";
export const SET_STATUSES = "SET_STATUSES";
export const SET_USER_ROLES = "SET_USER_ROLES";
export const SET_BUSINESS_TYPE = "SET_BUSINESS_TYPE";
export const SET_BUSINESS_NAME = "SET_BUSINESS_NAME";
export const SET_CASHDESK_BRANCH = "SET_CASHDESK_BRANCH";
export const SET_VERSION = "SET_VERSION";
export const RESET_FILTERS = "RESET_FILTERS";
