import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../../redux/actions/authAction";
import { translate } from "../../translations/translate";
import user from "../../assets/images/user.png";
import Toggle from "../Toggle";
import "./styles.sass";
import { setTheme } from "../../redux/actions/themeAction";

const Profile = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { profileData, role } = useSelector((state) => state.authReducer);
    const [profile, setProfile] = useState(false);
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const profileUrl = `/users/${profileData?._id}`;

    const onToggleChange = () => {
        if (props.theme !== "dark") {
            props.setThemeFunc("dark");
            document.body.classList.remove("light");
            document.body.classList.add("dark");
        } else {
            props.setThemeFunc("light");
            document.body.classList.remove("dark");
            document.body.classList.add("light");
        }
    };
    const handleProfile = () => {
        setProfile(!profile);
    };
    document.body.addEventListener("click", (e) => {
        if (
            e.target?.className !== "component-profile-handler" &&
            !e.target.closest(".component-profile-items") &&
            profile
        ) {
            setProfile(false);
        }
    });

    const handleLogout = () => {
        dispatch(logoutAction());
        window.location.replace("/auth");
    };

    const handleNavigate = (url) => {
        navigate(url);
        setProfile(false);
    };

    return (
        <div className="component-profile">
            <div className="component-header-profile-inner">
                <div
                    className="component-profile-handler"
                    onClick={handleProfile}
                ></div>
                <div className="component-profile-switcher">
                    <div className="component-profile-bar">
                        <img
                            src={user}
                            alt="avatar"
                            className="avatar-upload"
                        />
                        <p className="component-profile-name">
                            {profileData?.name?.first}{" "}
                            {profileData?.name?.last.charAt(0)}
                        </p>
                    </div>
                    <div className="profile-icon">
                        <i className="icon-chevron" />
                    </div>
                </div>
                {profile ? (
                    <div className="component-profile-items">
                        {role === "user" && (
                            <div className="profile-switcher-link">
                                <i className="icon-profile link-icon" />
                                <div
                                    onClick={() => handleNavigate(profileUrl)}
                                    className="profile-link"
                                >
                                    {__("Profile")}
                                </div>
                            </div>
                        )}
                        <div className="profile-switcher-link">
                            <i className="icon-theme link-icon" />
                            <div
                                className="profile-link"
                            >
                                {__("Theme")}
                            </div>
                            <Toggle
                                inputClassName="toggle-input"
                                spanClassName="custom-toggle-field sm"
                                icon="icon-sun"
                                iconSecond="icon-dark"
                                inputType="checkbox"
                                onChange={onToggleChange}
                                isChecked={props.theme === "dark"}
                            />
                        </div>
                        <div
                            onClick={handleLogout}
                            className="profile-switcher-link"
                        >
                            <i className="icon-exit link-icon" />
                            <div className="profile-link">{__("Exit")}</div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        theme: state.theme.theme,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setThemeFunc(theme) {
            dispatch(setTheme(theme));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
