import {
  UPDATE_PERMITTED_CLIENTS,
  REMOVE_PERMITTED_CLIENTS,
  UPDATE_PERMITTED_BUSINESSES,
  REMOVE_PERMITTED_BUSINESSES, EMPTY_DEALER_REDUCER
} from "../actionTypes/dealerActionTypes";

export const updatePermittedClients = (payload) => ({
  type: UPDATE_PERMITTED_CLIENTS,
  payload: payload
});

export const removePermittedClient = (payload) => ({
  type: REMOVE_PERMITTED_CLIENTS,
  payload: payload
});

export const updatePermittedBusinesses = (payload) => ({
  type: UPDATE_PERMITTED_BUSINESSES,
  payload: payload
});

export const removePermittedBusiness = (payload) => ({
  type: REMOVE_PERMITTED_BUSINESSES,
  payload: payload
});

export const emptyDealerState = () => ({
  type: EMPTY_DEALER_REDUCER
});