import React from "react";
import {Link} from "react-router-dom";
import { useSelector } from "react-redux";
import { translate } from "../../translations/translate";
import Button from "../../components/global/Button";
import Error500 from "../../assets/images/NoConnection.webp";
import "./styles.sass";

const PageError500 = ({ ...props }) => {
  const lang = useSelector(state => state.languageReducer.lang);
  const __ = key => translate(lang, key);

  return (
    <div { ...props } className="page page-error-500">
        <div className="page-error-500-main-content">
          <div className="error-500-left-side">
            <h1 className="component-heading">500</h1>
            <h2 className="component-error-heading">{__("Internal Server Error")}</h2>
            <div className="component-card-text">
                <p className="component-text">{__("Server Error 500. We apologies and we are fixing the problem")}</p>
            </div>
            <Link to="/">
              <Button text={__("Back to Home")} className="btn"/>
            </Link>
          </div>
          <div className="error-500-right-side">
              <img className="error-500-right-side-image" src={Error500} alt="error 500" />
          </div>
        </div>
    </div>
  );
};

export default PageError500;