import {ApiService} from "../ApiService";

export const searchProductQuery = async (businessId, query, token) => {
  try{
    const { data } = await ApiService.getResources(`/businesses/${
      businessId}/products/search/products?query=${query}`, token);
    return data.docs;
  } catch (error){
    return [];
  }
};