import LayoutBasic from "../layouts/Basic";
import LayoutMain from "../layouts/Main";

// Pages
import PageAuthorization from "../pages/LayoutBasic/Authorization";
import PageNewPassword from "../pages/LayoutBasic/NewPassword";
import PageResetPassword from "../pages/LayoutBasic/Reset";
import PageSignUp from "../pages/LayoutBasic/SignUp";
import PageVerification from "../pages/LayoutBasic/Verification";

import PageTable from "../pages/User/Table";

// Main Layout Pages
import PageDashboard from "../pages/LayoutMain/Dashboard";
import PageEdit from "../pages/User/Edit";
import PageCreate from "../pages/User/Create";
import PageViews from "../pages/User/Views";
import PageBusinessCreate from "../pages/Business/Create";
import PageBusinessEdit from "../pages/Business/Edit";
import PageBusinessTable from "../pages/Business/Table";
import PageBusinessViews from "../pages/Business/View";
import PageBranchCreate from "../pages/Branch/Create";
import PageBranchEdit from "../pages/Branch/Edit";
import PageBranchTable from "../pages/Branch/Table";
import PageBranchView from "../pages/Branch/View";
import PageFiscalCreate from "../pages/FiscalModule/Create";
import PageFiscalEdit from "../pages/FiscalModule/Edit";
import PageFiscalView from "../pages/FiscalModule/View";
import PageFiscalTable from "../pages/FiscalModule/Table";
import PageCashDeskCreate from "../pages/Cashdesk/Create";
import PageCashDeskEdit from "../pages/Cashdesk/Edit";
import PageCashDeskView from "../pages/Cashdesk/View";
import PageCashdeskTable from "../pages/Cashdesk/Table";
import Role from "../pages/Role";
import PageClientCreate from "../pages/Client/Create";
import PageClientEdit from "../pages/Client/Edit";
import PageClientView from "../pages/Client/View";
import PageClientTable from "../pages/Client/Table";
import PageChequeView from "../pages/Cheque/View";
import PageChequeTable from "../pages/Cheque/Table";
import PageReportView from "../pages/Report/View";
import PageReportTable from "../pages/Report/Table";
import PageCartView from "../pages/Cart/View";
import PageCartTable from "../pages/Cart/Table";
import PageCategoryTable from "../pages/Category/Table";
import PageCategoryCreate from "../pages/Category/Create";
import PageProductCreate from "../pages/Product/Create";
import PageProductTable from "../pages/Product/Table";
import PageCategoryView from "../pages/Category/View";
import PageCategoryEdit from "../pages/Category/Edit";
import PageProductView from "../pages/Product/View";
import PageProductEdit from "../pages/Product/Edit";
import PageCashDeskEditEps from "../pages/Cashdesk/Edit/EPS";
import PageCashdeskEPSView from "../pages/Cashdesk/View/EPS";
import PageStaffTable from "../pages/Staff/Table";
import PageStaffCreate from "../pages/Staff/Create";
import PageStaffEdit from "../pages/Staff/Edit";
import PageArrivalsTable from "../pages/Arrivals/Table";
import PageCreateArrival from "../pages/Arrivals/Create";
import PageArrivalView from "../pages/Arrivals/View";
import PageWithdrawalsTable from "../pages/Withdrawal/Table";
import PageCreateWithdrawal from "../pages/Withdrawal/Create";
import PageWithdrawalView from "../pages/Withdrawal/View";
import PageWarehouseTable from "../pages/Warehouse/Table";
import PageTransfersTable from "../pages/Transfers/Table";
import PageTransferCreate from "../pages/Transfers/Create";
import PageTransferView from "../pages/Transfers/View";
import BranchWarehouseTable from "../pages/Warehouse/BranchTable";

const clientRoutes = [
    {
        path: "/",
        element: <LayoutMain />,
        children: [
            {
                path: "",
                index: true,
                element: <PageBusinessTable />,
            },
            {
                path: "/dashboard",
                index: true,
                element: <PageDashboard />,
            },
        ],
    },
    {
        path: "/user",
        element: <LayoutMain />,
        children: [
            {
                path: "/table",
                index: false,
                element: <PageTable />,
            },
            {
                path: "/edit",
                index: false,
                element: <PageEdit />,
            },
            {
                path: "/create",
                index: false,
                element: <PageCreate />,
            },
            {
                path: "/role",
                index: false,
                element: <Role />,
            },
            {
                path: "/view",
                index: false,
                element: <PageViews />,
            },
        ],
    },
    {
        path: "/businesses",
        element: <LayoutMain />,
        children: [
            {
                path: "/create",
                index: true,
                element: <PageBusinessCreate />,
            },
            {
                path: "/:id/edit",
                index: false,
                element: <PageBusinessEdit />,
            },
            {
                path: "/",
                index: false,
                element: <PageBusinessTable />,
            },
            {
                path: "/:id",
                index: false,
                element: <PageBusinessViews />,
            },
            {
                path: "/:id/branches",
                index: false,
                element: <PageBranchTable />,
            },
            {
                path: "/:id/branches/:branchID",
                index: false,
                element: <PageBranchView />,
            },
            {
                path: "/:id/branches/:branchID/cashdesks",
                index: false,
                element: <PageCashdeskTable />,
            },
            {
                path: "/:id/branches/:branchID/cashdesks/:cashdeskID",
                index: false,
                element: <PageCashDeskView />,
            },
            {
                path: "/:id/cashdesks",
                index: false,
                element: <PageCashdeskTable />,
            },
            {
                path: "/:id/categories",
                index: false,
                element: <PageCategoryTable />,
            },
            {
                path: "/:id/receipts",
                index: false,
                element: <PageChequeTable />,
            },
            {
                path: "/:id/receipts/:receiptID",
                index: false,
                element: <PageChequeView />,
            },
        ],
    },
    {
        path: "/fiscal",
        element: <LayoutMain />,
        children: [
            {
                path: "/create",
                index: true,
                element: <PageFiscalCreate />,
            },
            {
                path: "/edit",
                index: false,
                element: <PageFiscalEdit />,
            },
            {
                path: "/view",
                index: false,
                element: <PageFiscalView />,
            },
            {
                path: "/table",
                index: false,
                element: <PageFiscalTable />,
            },
        ],
    },
    {
        path: "/clients",
        element: <LayoutMain />,
        children: [
            {
                path: "/create",
                index: true,
                element: <PageClientCreate />,
            },
            {
                path: "/:id/edit",
                index: false,
                element: <PageClientEdit />,
            },
            {
                path: "/:id",
                index: false,
                element: <PageClientView />,
            },
            {
                path: "/table",
                index: false,
                element: <PageClientTable />,
            },
        ],
    },
    {
        path: "/cheques",
        element: <LayoutMain />,
        children: [
            {
                path: "/view",
                index: true,
                element: <PageChequeView />,
            },
            {
                path: "/table",
                index: false,
                element: <PageChequeTable />,
            },
        ],
    },
    {
        path: "/cart",
        element: <LayoutMain />,
        children: [
            {
                path: "/view",
                index: true,
                element: <PageCartView />,
            },
            {
                path: "/table",
                index: false,
                element: <PageCartTable />,
            },
        ],
    },
    {
        path: "/report",
        element: <LayoutMain />,
        children: [
            {
                path: "/view",
                index: false,
                element: <PageReportView />,
            },
            {
                path: "/table",
                index: false,
                element: <PageReportTable />,
            },
        ],
    },
    {
        path: "/cashdesks",
        element: <LayoutMain />,
        children: [
            {
                path: "/create",
                index: true,
                element: <PageCashDeskCreate />,
            },
            {
                path: "/:id/edit",
                index: false,
                element: <PageCashDeskEdit />,
            },
            {
                path: "/:id/eps/edit",
                index: false,
                element: <PageCashDeskEditEps />,
            },
            {
                path: "/:id",
                index: false,
                element: <PageCashDeskView />,
            },
            {
                path: "/",
                index: false,
                element: <PageCashdeskTable />,
            },
            {
                path: "/:id/eps",
                index: false,
                element: <PageCashdeskEPSView />,
            },
        ],
    },
    {
        path: "/branches",
        element: <LayoutMain />,
        children: [
            {
                path: "/create",
                index: true,
                element: <PageBranchCreate />,
            },
            {
                path: "/:id/edit",
                index: false,
                element: <PageBranchEdit />,
            },
            {
                path: "/",
                index: false,
                element: <PageBranchTable />,
            },
            {
                path: "/:id",
                index: false,
                element: <PageBranchView />,
            },
        ],
    },
    {
        path: "/businesses/:businessID/categories",
        element: <LayoutMain />,
        children: [
            {
                path: "/create",
                index: true,
                element: <PageCategoryCreate />,
            },
            {
                path: "/:id/edit",
                index: false,
                element: <PageCategoryEdit />,
            },
            {
                path: "/",
                index: false,
                element: <PageCategoryTable />,
            },
            {
                path: "/:id",
                index: false,
                element: <PageCategoryView />,
            },
        ],
    },
    {
        path: "/businesses/:businessID/products",
        element: <LayoutMain />,
        children: [
            {
                path: "/create",
                index: true,
                element: <PageProductCreate />,
            },
            {
                path: "/:id/edit",
                index: false,
                element: <PageProductEdit />,
            },
            {
                path: "/",
                index: false,
                element: <PageProductTable />,
            },
            {
                path: "/:id",
                index: false,
                element: <PageProductView />,
            },
        ],
    },
    {
        path: "/businesses/:businessID/arrivals",
        element: <LayoutMain />,
        children: [
            {
                path: "/",
                index: true,
                element: <PageArrivalsTable />,
            },
            {
                path: "/create",
                index: true,
                element: <PageCreateArrival />,
            },
            {
                path: "/:invoiceId",
                index: true,
                element: <PageArrivalView />,
            }
        ]
    },
    {
        path: "/businesses/:businessID/withdrawals",
        element: <LayoutMain />,
        children: [
            {
                path: "/",
                index: true,
                element: <PageWithdrawalsTable />,
            },
            {
                path: "/create",
                index: true,
                element: <PageCreateWithdrawal />,
            },
            {
                path: "/:withdrawalId",
                index: true,
                element: <PageWithdrawalView />,
            }
        ]
    },
    {
        path: "/businesses/:businessID/warehouse",
        element: <LayoutMain />,
        children: [
            {
                path: "/",
                index: true,
                element: <PageWarehouseTable />,
            },
            {
                path: "/:branchID",
                element: <BranchWarehouseTable />,
            }
        ]
    },
    {
        path: "/businesses/:businessID/transfers",
        element: <LayoutMain />,
        children: [
            {
                path: "/",
                index: true,
                element: <PageTransfersTable />,
            },
            {
                path: "/create",
                index: true,
                element: <PageTransferCreate />,
            },
            {
                path: "/:transferId",
                index: true,
                element: <PageTransferView />,
            }
        ]
    },
    {
        path: "/businesses/:businessID/staff",
        element: <LayoutMain />,
        children: [
            {
                path: "/create",
                index: true,
                element: <PageStaffCreate />,
            },
            {
                path: "/:id/edit",
                index: false,
                element: <PageStaffEdit />,
            },
            {
                path: "/",
                index: false,
                element: <PageStaffTable />,
            },
        ],
    },
    {
        path: "/auth",
        element: <LayoutBasic />,
        children: [
            {
                path: "/",
                index: true,
                element: <PageAuthorization />,
            },
            {
                path: "/verification",
                index: false,
                element: <PageVerification />,
            },
            {
                path: "/register",
                index: false,
                element: <PageSignUp />,
            },
            {
                path: "/reset",
                index: false,
                element: <PageResetPassword />,
            },
            {
                path: "/newPassword",
                index: false,
                element: <PageNewPassword />,
            },
        ],
    },
    {
        path: "/clients",
        element: <LayoutMain />,
        children: [
            {
                path: "/:id",
                index: false,
                element: <PageClientView />,
            }
        ],
    },
    // {
    //     path: "/*",
    //     element: <LayoutEndpoint />,
    //     children: [
    //         {
    //             path: "*",
    //             index: true,
    //             element: <PageError404 />,
    //         },
    //         {
    //             path: "/*fg",
    //             index: false,
    //             element: <PageError500 />,
    //         },
    //     ],
    // },
];

export default clientRoutes;
