import React from "react";

const QRCodeGenerator = ({ url }) => {
    // Функция для генерации QR-кода из строки
    const generateQRCode = (text) => {
        // Размер QR-кода (в пикселях)
        const size = 200;

        // Кодирование строки в Base64
        const encodedText = encodeURIComponent(text);
        const base64Text = window.btoa(encodedText);

        // Формирование URL для генерации QR-кода
        const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${base64Text}&size=${size}x${size}`;

        // Возвращаем JSX с изображением QR-кода
        return <img src={qrCodeUrl} alt="QR Code" />;
    };

    return (
        <div style={{textAlign : "center" , padding : "18px 0"}}>
            {generateQRCode(url)}
        </div>
    );
};

export default QRCodeGenerator;
