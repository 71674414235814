import config from "../../config";
import useApiHook from "../../hooks/useApiHook";
import {capitalize} from "../capitalize";

const getStatuses = (model) => {
    if(!model)
        model = "user";

    // Retrieve available statuses for defined model
    const statusesURL = `${config.API_BASE_URL}/statuses/${model}`;
    const {data: statusesData} = useApiHook(statusesURL);
    return statusesData?.data?.map((item) => {
        return {
            value: item,
            label: capitalize(item)
        };
    });
};

export default getStatuses;