import React from "react";
import PropTypes from "prop-types";
import logo from "../../../assets/images/logo.svg";
import logoSmall from "../../../assets/images/logoSmall.svg";
import logoDark from "../../../assets/images/logoDark.svg";
import "./styles.sass";
import { useSelector } from "react-redux";

const Logo = ({...props}) => {
  const {theme} = useSelector((state) => state.theme);
  return (
    <div {...props} className="component component-header-logo">
      <div className="component-container">
        {theme !== "dark" && <img src={props.type === "small" ? logoSmall : logo} alt="e-pos logo" />}
        {theme === "dark" && <img src={props.type === "small" ? logoSmall : logoDark} alt="e-pos logo" />}
      </div>
    </div>
  );
};

Logo.propTypes = {
  type: PropTypes.string
};

export default Logo;