import React from "react";
import Button from "../global/Button";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { translate } from "../../translations/translate";
import "./styles.sass";
import { useSelector } from "react-redux";

const PageHeader = (props) => {
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    return (
        <div className="component-header-box">
            <div className="header">
                <div className="component-header-title">
                    <h1 className="header-name">{props.name}</h1>
                    <div className="buttons">
                        {props.buttons &&
                            props.buttons.map((button, i) => {
                               return button.link ? (
                                  <Link
                                    to={button.link}
                                    key={i}
                                    state={button.state}
                                  >
                                      <Button
                                        onClick={button.onClick}
                                        className={`btn ${button.className} sm`}
                                        text={__(button.name)}
                                        type="button"
                                      />
                                  </Link>
                                ) : (
                                  <Button
                                    onClick={button.onClick}
                                    disabled={button?.disabled}
                                    className={`btn ${button.className} sm`}
                                    text={__(button.name)}
                                    type="button"
                                    key={i}
                                  />
                            );})
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

PageHeader.propTypes = {
    name: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default PageHeader;
