const cleanseObject = (object) => {
    return Object.fromEntries(
        Object.entries(object)
            .filter(([_, value]) => value != null)
            .map(([key, value]) => [
                key,
                (!Array.isArray(value) && value === Object(value)) ? cleanseObject(value) : value,
            ]),
    );
};

export default cleanseObject;