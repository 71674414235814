import { SET_CLOSED, SET_OPEN } from "../actionTypes/sidebarActionTypes";

const initialState = {
    isOpen: true
};
const sidebarReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_OPEN:
            return {...state, isOpen: true};
        case SET_CLOSED:
            return {...state, isOpen: false};
        default:
            return state;
    }
};
export default sidebarReducer;