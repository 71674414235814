import {useEffect, useState} from "react";
import Input from "../LayoutBasic/Input";
import { useSelector } from "react-redux";
import {translate} from "../../translations/translate";
import {stringWithoutSpaces} from "../../utils/removeStrSpaces";

import "./styles.sass";

const SearchBar = ({setSearch = () => {}, searchWithoutSpaces, ...props}) => {
    const lang = useSelector(state => state.languageReducer.lang);
    const __ = key => translate(lang, key);

    const [isOpen, setIsOpen] = useState(props.isOpen || false);
    const [style, setStyle] = useState("");
    const [debounce, setDebounce] = useState("");
    const onIconClick = function() {
        setIsOpen(!isOpen);
        if(style === "with_margin") {
            setStyle("");
            setDebounce("");
        }
        if(style === "") {
            setStyle("with_margin");
        }
    };

    // Delaying the request to optimize the sending of the request
    useEffect(() => {
        const handler = setTimeout(() => {
            setSearch(debounce);
        }, 300);
        return () => clearTimeout(handler);
    }, [debounce]);

    return (
        <div className={`component-search ${style} ${props.className}`}>
            { !isOpen &&
                <div className="search-inner">
                    <div className="search-icon" onClick={onIconClick}>
                        <i className="icon-search" />
                    </div>
                </div>
            }
            { isOpen && <div className="search-inner">
                <div className="search-icon">
                    <i className="icon-search" />
                </div>
                <Input
                    htmlFor="text"
                    id="text"
                    className="card-input sm card-input_search"
                    type="text"
                    placeholder={__("Search")}
                    icon={`${true}`}
                    relative={`${true}`}
                    onClick={props.isOpen ? null : onIconClick}
                    onChange={(e) => {
                        setDebounce(
                            searchWithoutSpaces ? stringWithoutSpaces(e.target.value) : e.target.value
                        );
                    }}
                    value={props.search}
                />
            </div> }
        </div>
    );
};
export default SearchBar;
