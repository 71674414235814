import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {NumericFormat} from "react-number-format";
import Preloader from "../../../components/Preloader";
import PageHeader from "../../../components/PageHeader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { translate } from "../../../translations/translate";
import useApiHook from "../../../hooks/useApiHook";
import {capitalize} from "../../../utils/capitalize";
import { ownerTypeValues, isMarkedValues } from "../../../utils/consts";
import {truncateString} from "../../../utils/truncateString";
import { formatDate } from "../../../utils/formatDate";
import config from "../../../config";
import "./styles.sass";

const PageProductView = () => {
    const lang = useSelector((state) => state.languageReducer.lang);
    const warehouseStatus = useSelector(state => state.warehouse.warehouseStatus);
    const __ = (key) => translate(lang, key);
    const { state } = useLocation();
    const { businessID, id: productID } = useParams();
    const url = `${config.API_BASE_URL}/businesses/${
        state?.business?.id || state?.business?.slug || businessID
    }/products/${state?.product?.id || productID}`;

    const { data, loading, error } = useApiHook(url);

    const product = data?.data?.docs[0];

    const buttons = [
        {
            name: "Back",
            link: `/businesses/${state?.business?.slug || state?.business?.id || businessID
                }/products`,
            state: {
                business: {
                    _id: state?.business?.id || businessID,
                    slug: state?.business?.slug,
                    name: state?.business?.name,
                }
            },
            className: "outline",
        },
        {
            name: "Edit",
            link: `/businesses/${state?.business?.slug || state?.business?.id || businessID
                }/products/${state?.product?.id || productID}/edit`,
            state: {
                business: {
                    id: state?.business?.id || businessID,
                    slug: state?.business?.slug || businessID,
                },
                product: {
                    id: state?.product?.id || productID,
                },
            },
            className: "",
        },
    ];

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error}</div>;
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[{ label: "Product", link: "/product" }]}
            />
            <div className="page component-page-business-create">
                <form className="page-inner">
                    <PageHeader
                        name= {__("View Product")}
                        buttons={buttons}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            {data?.data?.docs?.map((item, i) => (
                                <div key={i}>
                                    {/* Product Information */}
                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-information"/>
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__("Product Information")}
                                                    </p>
                                                    <p className="card-subtitle"></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-item-container">
                                            {/* 1 */}
                                            <div className="input-item">
                                                {/*  Tasnif Product Info  */}
                                                <div className="info-card">
                                                    <p className="card-heading">
                                                        {__("Tasnif Product Info")}
                                                    </p>
                                                    <div className="card-content card-content-disabled tasnif-disabled">
                                                        <p className="text">
                                                            <span className="bold">
                                                                {__("Title")}
                                                            </span>
                                                        </p>
                                                        <p className="text">
                                                            UZ: {
                                                            item?.productTasnif
                                                              ?.name?.uz
                                                        }
                                                        </p>
                                                        <p className="text">
                                                            RU:
                                                            {
                                                                item?.productTasnif
                                                                  ?.name?.ru
                                                            }
                                                        </p>
                                                        <p className="text">
                                                            <span className="bold">
                                                            {__("ICPS")}:
                                                            </span>{" "}
                                                            {
                                                                item?.productTasnif
                                                                  ?.icps
                                                            }
                                                        </p>
                                                        <p className="text">
                                                            <span className="bold">
                                                                 {__("Int. Code")}:
                                                            </span>{" "}
                                                            {
                                                                item?.productTasnif
                                                                  ?.internationalCode
                                                            }
                                                        </p>
                                                        <p className="text">
                                                            <span className="bold">
                                                                 {__("Marking")}:
                                                            </span>{" "}
                                                            {
                                                                item?.productTasnif?.label === "1"
                                                                  ? __("Required")
                                                                  : __("Not required")
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                {/* 2 */}
                                                <div className="input-item">
                                                    {/* Title */}
                                                    <div className="component-input full smart-search-container">
                                                        <label
                                                          className="input-label"
                                                          htmlFor="title"
                                                        >
                                                            <input
                                                              defaultValue={
                                                                  item?.title?.uz
                                                              }
                                                              id="title"
                                                              className="input input-disabled"
                                                              type="text"
                                                              required={true}
                                                              disabled
                                                            />
                                                            <span className="input-text">
                                                                {__("Title")}
                                                                </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* 3 */}
                                                <div className="input-item">
                                                    {/* Descr*/}
                                                    <div className="component-input full">
                                                        <label
                                                          className="input-label"
                                                          htmlFor="description"
                                                        >
                                                        <textarea
                                                          defaultValue={
                                                              item
                                                                ?.description
                                                                ?.uz
                                                          }
                                                          disabled
                                                          id="description"
                                                          className="input input-disabled"
                                                          rows={3}
                                                        />
                                                            <span className="input-text">
                                                                {__("Description")}
                                                                </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="input-item">
                                                    <div className="component-input full">
                                                        <label
                                                          className="input-label"
                                                          htmlFor="isMarked"
                                                        >
                                                            <input
                                                              defaultValue={item?.isMarked ? __(isMarkedValues[0].label) : __(isMarkedValues[1].label)}
                                                              disabled
                                                              id="isMarked"
                                                              className="input input-disabled"
                                                              type="text"
                                                            />
                                                            <span className="input-text">
                                                            {`${__("Marking")}:`}
                                                        </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Categories */}
                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-information"/>
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__("Categories")}
                                                    </p>
                                                    <p className="card-subtitle"></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-item">
                                            <div className="info-card white-bg">
                                                <p className="card-heading">
                                                    {__("Categories")}
                                                </p>
                                                <div
                                                  className="card-content equal-padding card-content-50 card-content-disabled">
                                                    <div className="tags-container">
                                                        {item?.categories
                                                          && item?.categories?.length > 0
                                                          && item?.categories.map((category, index) => (
                                                            <div className="tag padding-tag" key={index}>
                                                                {category?.title?.ru}
                                                            </div>
                                                          ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Tax Information */}
                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-information"/>
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__("Tax Information")}
                                                    </p>
                                                    <p className="card-subtitle"></p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* 1 */}
                                        <div className="input-item">
                                            {/* Intenational Code */}
                                            <div className="component-input ">
                                                <label
                                                  className="input-label"
                                                  htmlFor="intenationalCode"
                                                >
                                                    <input
                                                      defaultValue={
                                                          item
                                                            ?.productTasnif
                                                            ?.internationalCode
                                                      }
                                                      id="intenationalCode"
                                                      className="input input-disabled"
                                                      type="text"
                                                      disabled
                                                    />
                                                    <span className="input-text">
                                                            {__("Intenational Code")}
                                                        </span>
                                                </label>
                                            </div>
                                            {/* Owner Type */}
                                            <div className="component-input">
                                                <label
                                                  className="input-label"
                                                  htmlFor="ownerType"
                                                >
                                                    <input
                                                      defaultValue={ownerTypeValues.find(type => type.value === item?.ownerType).label}
                                                      disabled
                                                      id="ownerType"
                                                      className="input input-disabled"
                                                      type="text"
                                                    />
                                                    <span className="input-text">
                                                            {__("Owner Type")}
                                                        </span>
                                                </label>
                                            </div>
                                        </div>
                                        {/* 2 */}
                                        <div className="input-item">
                                            {/* ICPS */}
                                            <div className="component-input smart-search-container">
                                                <label
                                                  className="input-label"
                                                  htmlFor="icps"
                                                >
                                                    <input
                                                      defaultValue={
                                                          item
                                                            ?.productTasnif
                                                            ?.icps
                                                      }
                                                      id="icps"
                                                      className="input input-disabled"
                                                      type="text"
                                                      disabled
                                                    />

                                                    <span className="input-text">
                                                           {__("ICPS")}
                                                        </span>
                                                </label>
                                            </div>
                                            {/* VAT */}
                                            <div className="component-input">
                                                <label
                                                  className="input-label"
                                                  htmlFor="vat"
                                                >
                                                    <input
                                                      defaultValue={
                                                          item?.VAT
                                                      }
                                                      disabled
                                                      id="vat"
                                                      className="input input-disabled"
                                                      type="text"
                                                      required={true}
                                                    />
                                                    <span className="input-text">
                                                            {__("VAT")}
                                                        </span>
                                                </label>
                                            </div>
                                        </div>
                                        {/* 3 */}
                                        <div className="input-item">
                                            <div className="component-input">
                                                <label
                                                  className="input-label"
                                                  htmlFor="unit"
                                                >
                                                    <input
                                                      defaultValue={product?.units || "-"}
                                                      disabled
                                                      id="unit"
                                                      className="input input-disabled"
                                                      type="text"
                                                      required={true}
                                                    />
                                                    <span className="input-text">
                                                            {__("Unit")}
                                                        </span>
                                                </label>
                                            </div>
                                            <div className="component-input">
                                                <label
                                                  className="input-label"
                                                >
                                                    <input
                                                      type="text"
                                                      className="input input-disabled"
                                                      value={__(capitalize(product?.productType)) || "-"}
                                                      disabled
                                                    />
                                                    <span className="input-text">
                                                            {__("Type")}
                                                        </span>
                                                </label>
                                            </div>

                                        </div>
                                        {/* 4 */}
                                        {warehouseStatus ? (
                                          <div className="input-item">
                                              {/* Article */}
                                              <div className="component-input">
                                                    <label
                                                        className="input-label"
                                                        htmlFor="article"
                                                    >
                                                        <input
                                                            defaultValue={item?.article}
                                                            disabled
                                                            id="article"
                                                            className="input input-disabled"
                                                            type="text"
                                                        />
                                                        <span className="input-text">
                                                            Article
                                                        </span>
                                                    </label>
                                                </div>
                                          </div>
                                        ) : (
                                          <div className="input-item">
                                              {/* In Stock */}
                                              <div className="component-input">
                                                  <label
                                                    className="input-label"
                                                    htmlFor="inStock"
                                                  >
                                                      <input
                                                        id="inStock"
                                                        className="input input-disabled"
                                                        type="text"
                                                        defaultValue={
                                                            item?.inStock
                                                        }
                                                        disabled
                                                      />
                                                      <span className="input-text">
                                                            {__("In Stock")}
                                                        </span>
                                                  </label>
                                              </div>
                                          </div>
                                        )}
                                    </div>
                                    {/* Price Information */}
                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-information"/>
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__("Price Information")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* 1 */}
                                        <div className="input-item">
                                            {/* Price */}
                                            <div className="component-input">
                                                <label
                                                  className="input-label"
                                                >
                                                        <span className="input-text">
                                                             {__(" Price")}
                                                            </span>
                                                        <NumericFormat
                                                            id="price"
                                                            className="input input-disabled"
                                                            displayType="text"
                                                            thousandSeparator={" "}
                                                            decimalSeparator={"."}
                                                            value={item.price / 100}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                        />
                                                    </label>
                                                </div>
                                                {/* Purchase Price */}
                                                <div className="component-input">
                                                    <label
                                                        className="input-label"
                                                        htmlFor="purchasePrice"
                                                    >
                                                        <NumericFormat
                                                            id="purchasePrice"
                                                            type="text"
                                                            className="input input-disabled"
                                                            thousandSeparator={" "}
                                                            decimalSeparator={"."}
                                                            value={item?.purchasePrice / 100}
                                                            decimalScale={2}
                                                            fixedDecimalScale={true}
                                                            disabled
                                                        />
                                                        <span className="input-text">
                                                            {__("Purchase Price")}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            {/* 2 */}
                                            <div className="input-item">
                                                {/* Discount */}
                                                <div className="component-input">
                                                    <label
                                                        className="input-label"
                                                        htmlFor="discount"
                                                    >
                                                        <input
                                                            id="discount"
                                                            className="input input-disabled"
                                                            type="text"
                                                            defaultValue={
                                                                item?.discount?.value?.amount > 0
                                                                    ? `${item.discount.value.amount} sum`
                                                                    : item?.discount?.value?.percentage !== undefined
                                                                        ? `${item.discount.value.percentage}%`
                                                                        : "No discount"
                                                            }
                                                            disabled
                                                        />
                                                        <span className="input-text">
                                                                {__("Discount")}
                                                            </span>
                                                    </label>
                                                </div>
                                                <div className="input-item-container">
                                                    {/* Start Date */}
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                            htmlFor="startDate"
                                                        >
                                                            <input
                                                                id="startDate"
                                                                className="input input-disabled"
                                                                type="text"
                                                                disabled
                                                                defaultValue={formatDate(item.discount?.activeThrough?.startDate)}
                                                            />
                                                            <span className="input-text date-picker">
                                                                {__("Discount Start Date")}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    {/* End Date */}
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                            htmlFor="endDate"
                                                        >
                                                            <input
                                                                id="endDate"
                                                                className="input input-disabled"
                                                                type="text"
                                                                disabled
                                                                defaultValue={formatDate(item.discount?.activeThrough?.endDate)}
                                                            />
                                                            <span className="input-text date-picker">
                                                                {__("Discount End Date")}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/* Supply Information */}
                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-information"/>
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__("Supply Information")}
                                                    </p>
                                                    <p className="card-subtitle"></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-item">
                                            {/* Commitent TIN */}
                                            <div className="component-input">
                                                <label
                                                  className="input-label"
                                                  htmlFor="commitentTIN"
                                                >
                                                    <input
                                                      id="commitentTIN"
                                                      className="input input-disabled"
                                                      type="text"
                                                      defaultValue={
                                                          item?.committentTIN
                                                      }
                                                      disabled
                                                    />
                                                    <span className="input-text">
                                                        {__("Commitent TIN/PINFL")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Warehouse Status */}
                                    {warehouseStatus && (
                                      <div className="component-box-card">
                                          <div className="card-header">
                                              <div className="card-info">
                                                  <div className="card-icon">
                                                      <i className="icon-information"/>
                                                  </div>
                                                  <div className="card-text">
                                                      <p className="card-title">
                                                          {__("Warehouse Status")}
                                                      </p>
                                                  </div>
                                              </div>
                                          </div>
                                          {/* 1 */}
                                          <div className="input-item">
                                              {/* Total Quantity */}
                                              <div className="component-input">
                                                  <label
                                                    className="input-label"
                                                    htmlFor="TotalStored"
                                                  >
                                                      <input
                                                        id="TotalStored"
                                                        className="input input-disabled"
                                                        type="text"
                                                        defaultValue={
                                                            item?.totalStored
                                                        }
                                                        disabled
                                                      />
                                                      <span className="input-text">
                                                            {__("Total Quantity")}
                                                        </span>
                                                  </label>
                                              </div>
                                              {/* In Stock */}
                                              <div className="component-input">
                                                  <label
                                                    className="input-label"
                                                    htmlFor="inStock"
                                                  >
                                                      <input
                                                        id="inStock"
                                                        className="input input-disabled"
                                                        type="text"
                                                        defaultValue={
                                                            item?.inStock
                                                        }
                                                        disabled
                                                      />
                                                      <span className="input-text">
                                                            {__("In Stock")}
                                                        </span>
                                                  </label>
                                              </div>
                                          </div>
                                          {/* Branches in stock */}
                                          {item?.stock?.map((branch) => (
                                            <div className="input-item" key={branch.branchID?._id}>
                                                <div className="component-input">
                                                    <label
                                                      className="input-label"
                                                    >
                                                        <input
                                                          className="input input-disabled"
                                                          type="text"
                                                          value={
                                                              branch?.count?.available
                                                          }
                                                          disabled
                                                        />
                                                        <span className="input-text">
                                                            {truncateString(branch.branchID?.name, 30)}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="component-input">
                                                    <label
                                                      className="input-label"
                                                    >
                                                        <input
                                                          className="input input-disabled"
                                                          type="text"
                                                          value={
                                                              branch?.count?.sold
                                                          }
                                                          disabled
                                                        />
                                                        <span className="input-text">
                                                            {__("Sold")}
                                                        </span>
                                                    </label>
                                                </div>
                                                <div className="component-input">
                                                    <label
                                                      className="input-label"
                                                    >
                                                        <input
                                                          className="input input-disabled"
                                                          type="text"
                                                          value={
                                                              branch?.count?.utilized
                                                          }
                                                          disabled
                                                        />
                                                        <span className="input-text">
                                                            {__("Utilized")}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                          ))}
                                      </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageProductView;
