import {ApiService} from "../ApiService";

export const searchCategories = async (businessID, query, token) => {
    try{
        const response = await ApiService.getResources(`/businesses/${businessID}/product-categories/search/category?query=${query}`, token);
        if (!response.data.docs || !Array.isArray(response.data.docs)) {
            return [];
        } else {
            return response.data.docs;
        }
    }
    catch (e){
        return [];
    }
};