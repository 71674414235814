import { useState, useRef, useEffect } from "react";
import { translate } from "../../../translations/translate";
import { useSelector } from "react-redux";

const Timer = ({ onFinish }) => {
    const Ref = useRef(null);

    const [timer, setTimer] = useState("00:00");
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const getTimeRemaining = (event) => {
        const total = Date.parse(event) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);

        return {
            total,
            hours,
            minutes,
            seconds,
        };
    };

    const startTimer = (event) => {
        let { total, hours, seconds, minutes } = getTimeRemaining(event);

        if (total >= 0) {
            setTimer(
              (hours > 9 ? minutes : "0" + minutes) +
              ":" +
              (seconds > 9 ? seconds : "0" + seconds),
            );
        }
    };

    const clearTimer = (event) => {
        setTimer("01:00");

        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(event);
            const { total } = getTimeRemaining(event);
            if (total <= 0 && typeof onFinish === "function") {
                onFinish();
                clearInterval(Ref.current);
            }
        }, 1000);

        Ref.current = id;
    };

    const getDeadTime = () => {
        let deadline = new Date();

        deadline.setSeconds(deadline.getSeconds() + 60);

        return deadline;
    };

    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);

    return (
      <div className="verification-time">
          <p>
              {__("You can send the code again after", lang) + " " + timer}
          </p>
      </div>
    );
};

export default Timer;
