import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import ModalWindow from "../../../components/ModalWindow";
import { Box, colors, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiService } from "../../../utils/ApiService";
import {useDispatch, useSelector} from "react-redux";
import { translate } from "../../../translations/translate";
import { errorToast } from "../../../utils/toast";
import Action from "../../../components/Action/index.jsx";
import BreadCrumbs from "../../../components/BreadCrumbs/index.jsx";
import Filter from "../../../components/Filter/index.jsx";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import Preloader from "../../../components/Preloader";
import "../../../assets/fonts/epos-icons/style.css";
import "./styles.sass";
import {updateTotalAction} from "../../../redux/reducers/paginationControllerReducer";

Modal.setAppElement("#root");

const PageRolesTable = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const navigate = useNavigate();
    const [id, setId] = useState();

    const { token } = useSelector((state) => state.authReducer);

    // USE LOCATION
    const { state } = useLocation();

    // CHECKING WHAT ROLES ARE THESE
    const isStaffRoles = !!state?.business;
    const openModals = (id) => {
        setModalIsOpen(true);
        setId(id);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const removeRole = () => {
        ApiService.deleteEvent(`/${isStaffRoles ? `businesses/${state?.business?._id}/staff/roles` : "users-roles"}/${id}`, token)
            .then((res) => {
                if (res.status === 200) {
                    window.location.reload();
                }
            })
            .catch((error) => {
                errorToast(error.message);
            });
    };

    // QUERY TO BACKEND
    const url = `${config.API_BASE_URL}/${isStaffRoles ? `businesses/${state?.business?._id}/staff/roles/?` : "users-roles?"}`;

    const [urlBase, setUrlBase] = useState(url);

    const { data, loading, error } = useApiHook(urlBase);

    // TRANSLATIONS
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const dispatch = useDispatch();
    const controller = useSelector(
        (state) => state.paginationControllerReducer,
    );
    useEffect(() => {
        if(data) {
            dispatch(updateTotalAction(parseInt(data?.data?.totalDocs) || 0));
        }
    }, [data]);

    useEffect(() => {
        setUrlBase(
            `${url}&page=${controller.page + 1}&limit=${
                controller.pageSize
            }`,
        );
    }, [controller.page, controller.pageSize]);

    // LOADING AND ERROR
    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );

    if (error) return <div>{error}</div>;

    // ROLES LIST MAPPING
    let rolesList = data?.data?.docs?.map((role) => ({
            title: role?.title,
            id: role?._id
    }));


    // TABLE COLUMNS
    const columns = [
        {
            field: "roleName",
            headerName: __("Name"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={600}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {__(row?.title)}
                            </Typography>
                    </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Action
                            onClick={() =>
                                navigate(`/roles/${row.id}/edit`, {
                                    state: {
                                        role: {
                                            id: row.id,
                                        },
                                        ... isStaffRoles ? ({business: state?.business}) : {},
                                    },
                                })
                            }
                            onClickView={() =>
                                navigate(`/roles/${row.id}`, {
                                    state: {
                                        role: {
                                            id: row.id,
                                        },
                                        ... isStaffRoles ? ({business: state?.business}) : {},
                                    },
                                })
                            }
                            openModal={() => openModals(row?.id)}
                        />
                    </Box>
                );
            },
        },
    ];

    return (
        <div>
            <BreadCrumbs breadcrumbs={[{ label: __("Roles"), item: "/roles" }]} />
            <div className="page page-table-roles">
                <Box>
                    <Filter
                        description={__(` ${__("Roles")} ${isStaffRoles ? `${__("of")} ${state?.business?.name}` : ""}`)}
                        text={__("Add Role")}
                        createLink="/roles/create"
                        state={state}
                    />
                    <Box
                        sx={{
                            "&": {
                                margin: "0",
                                height: "90vh",
                                minHeight: "350px",
                            },
                            "& .name-column--cell": {
                                color: colors.green[300],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                            },
                        }}
                    >
                        <DataGrid
                            rows={rolesList}
                            columns={columns}
                            rowCount={controller.total}
                            page={controller.page}
                            pageSize={controller.pageSize}
                            {...config.TABLE_CONFIG}
                        />
                    </Box>
                </Box>
                <Modal
                    className="delete-modal"
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                >
                    <div className="modal-bottom">
                        <ModalWindow
                            title={__("Are you sure?")}
                            text={__(
                                "Are you sure you want to permanently delete this Role?",
                            )}
                            onClick={closeModal}
                            onClickDelete={removeRole}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default PageRolesTable;
