import { Outlet } from "react-router-dom";
import "./styles.sass";

const LayoutBasic = ( { ...props } ) => {
    return (
        <div { ...props } className="layout layout-basic">
            <Outlet />
        </div>
    );
};

export default LayoutBasic;