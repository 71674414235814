import React, { useState } from "react";
import { translate } from "../../../translations/translate";
import { useSelector } from "react-redux";
import { ApiService } from "../../../utils/ApiService";
import { errorToast, successToast } from "../../../utils/toast";
import { useNavigate } from "react-router-dom";
import { fiscalPref } from "../../../hooks/fiscalPref";
import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import SelectComponent from "../../../components/Select";
import "./styles.sass";
import { PatternFormat } from "react-number-format";

const PageFiscalCreate = () => {
    const fiscalMask = "0000 0000 0000";
    const FiscalMask = [
        {
            mask: fiscalMask,
        },
    ];
    const navigate = useNavigate();

    const { token } = useSelector((state) => state.authReducer);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [fiscalLetters, setFiscalLetters] = useState("UZ");
    const [fiscalNumbers, setFiscalNumbers] = useState("");
    const [virtualNumbers, setVirtualNumbers] = useState("");
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    // CALLBACK FUNCTIONS
    const onCancelClick = function (e) {
        e.preventDefault();
        navigate("/fiscal");
    };

    const onSubmit = (e) => {
        e.preventDefault();

        document.getElementById("FiscalID").setCustomValidity("");
        document.getElementById("VirtualID").setCustomValidity("");

        // CHECKING IF FISCAL NUMBERS WERE ENTERED

        if(fiscalNumbers.length !== 12) {
            document.getElementById("FiscalID").setCustomValidity(__("Phone"));
        }
        if(virtualNumbers.length !== 6) {
            document.getElementById("VirtualID").setCustomValidity(__("Email"));
        }
        if(fiscalNumbers.length === 12 && (virtualNumbers.length  === 6 || virtualNumbers.length  === 5) ) {
            const body = {
                fiscalID: fiscalLetters + fiscalNumbers.split(" ").join(""),
                virtualID: virtualNumbers.split(" ").join("")
            };
            if (isSubmitting) return;
            setIsSubmitting(true);
            ApiService.postEvent("/fiscalmodule/create", token, body)
                .then((response) => {
                    if (response.status === 201) {
                        successToast(__("Successfully created"));
                        setIsSubmitting(false);
                        navigate("/fiscal");
                    }
                })
                .catch((error) => {
                        setIsSubmitting(false);
                        errorToast(error.message);
                });
            return;
        } else {
            // Set custom validity messages for both fields
            // errorToast(__("You did not provide a proper fiscal module"));
            return;
        }
    };
    const buttons = [
        {
            name: "Back",
            link: "/fiscal",
            className: "outline",
        },
    ];
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Fiscal", link: "/fiscal" },
                    { label: "Create" },
                ]}
            />
            <div className="page component-page-fiscal-create">
                <form onSubmit={onSubmit} className="page-create-inner">
                    <PageHeader
                        name={__("Create Fiscal Module")}
                        buttons={buttons}
                    />
                    <div className="cards-inner">
                        <div className="left-side-cards">
                            <div className="component-create-box-card">
                                <div className="card-user">
                                    <div className="user-icon">
                                        <i className="icon-information" />
                                    </div>
                                    <div className="user-text">
                                        <p className="user-title">
                                            {__("Fiscal Module Information")}
                                        </p>
                                    </div>
                                </div>
                                <div className="input-inner">
                                    <div className="component-ui-input input-item input-right">
                                        <div className="component-switcher">
                                            <label
                                                className="ui-card-label"
                                                htmlFor="FiscalIDLetters"
                                            >
                                                <span className="ui-input-text">
                                                    {__("Fiscal ID")}
                                                </span>
                                                <SelectComponent
                                                    options={fiscalPref}
                                                    onChange={(e) =>
                                                        setFiscalLetters(
                                                            e.value,
                                                        )
                                                    }
                                                    value={
                                                        fiscalPref.find(
                                                            (item) =>
                                                                item.value ===
                                                                fiscalLetters,
                                                        ) || fiscalPref[0]
                                                    }
                                                    name="FiscalIDLetters"
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="component-ui-input">
                                        <label
                                            className="ui-card-label"
                                            htmlFor="FiscalID"
                                        >
                                            <PatternFormat
                                                format="#### #### ####"
                                                mask="_"
                                                value={fiscalNumbers}
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    setFiscalNumbers(value);
                                                }}
                                                id="FiscalID"
                                                className="ui-input"
                                                type="tel"
                                                placeholder="____ ____ ____"
                                                minLength={12}
                                                required
                                                onInvalid={e => e.target.setCustomValidity(__("Fiscal ID"))}
                                                onInput={e => e.target.setCustomValidity("")}
                                            />
                                        </label>
                                    </div>

                                    <div className="component-ui-input">
                                        <label
                                            className="ui-card-label"
                                            htmlFor="VirtualID"
                                        >
                                            <PatternFormat
                                                format="### ###"
                                                mask="_"
                                                value={virtualNumbers}
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    setVirtualNumbers(value);
                                                }}
                                                id="VirtualID"
                                                className="ui-input ui-input--virtual"
                                                type="tel"
                                                placeholder="___ ___"
                                                minLength={5}
                                                required
                                                onInvalid={e => e.target.setCustomValidity(__("Virtual Number"))}
                                                onInput={e => e.target.setCustomValidity("")}
                                            />
                                            <span className="input-text">{__("Virtual Number")}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-unsaved" />
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                                className="btn outline sm"
                                text={__("Cancel")}
                                onClick={onCancelClick}
                                type="button"
                            />
                            <Button
                                className="btn sm"
                                text={__("Save")}
                                type="submit"
                                disabled={isSubmitting}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageFiscalCreate;
