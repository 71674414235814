import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useLocation, useParams} from "react-router";

import {DataGrid} from "@mui/x-data-grid";
import {NumericFormat} from "react-number-format";
import {Box, colors, Typography} from "@mui/material";
import BreadCrumbs from "../../../components/BreadCrumbs";
import PageHeader from "../../../components/PageHeader";
import TableHeader from "../../../components/TableHeader";
import Preloader from "../../../components/Preloader";

import {translate} from "../../../translations/translate";
import {getParsedDate} from "../../../utils/getParsedDate";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import {useNavigate} from "react-router-dom";

const PageWithdrawalView = () => {
    const navigate = useNavigate();
    const {state}  = useLocation();
    const {withdrawalId, businessID} = useParams();
    // Lang selector
    const lang = useSelector((state) => state.languageReducer.lang);
    // Pagination controller
    const controller = useSelector((state) => state.paginationControllerReducer);

    const baseUrl = `${config.API_BASE_URL}/businesses/${businessID}/invoice/${withdrawalId}`;

    // States
    const [url, setUrl] = useState(baseUrl);

    const { loading, data } = useApiHook(url);
    const withdrawal = data?.data?.docs[0];

    // Handlers / functions
    const __ = (key) => translate(lang, key);

    const formatProductsArr = (products) => {
        return products?.map((product) => ({
            ...product,
            id: product._id
        }));
    };

    // Effects
    // TODO: implement table pagination

    const breadCrumbsArray = [
        {label: __("Withdrawals"), link: "/businesses"},
        {label: __("View")},
    ];

    const productColumn = [
        {
            field: "article",
            headerName: __("Article"),
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={600}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                    onClick={() => {
                        navigate(
                          `/businesses/${state?.business?.slug ||
                          state?.business?._id ||
                          businessID
                          }/products/${row.productID.id}`,
                          {
                              state: {
                                  business: {
                                      id:
                                        state?.business._id ||
                                        businessID,
                                      slug:
                                        state?.business.slug ||
                                        businessID,
                                  },
                                  product: {
                                      id: row.productID.id,
                                      slug: row.productID.id,
                                  },
                              },
                          },
                        );
                    }}
                  >
                      {row.productID?.article}
                  </Typography>
                );
            }
        },
        {
            field: "productName",
            headerName: __("Name"),
            width: 300,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={600}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                    onClick={() => {
                        navigate(
                          `/businesses/${state?.business?.slug ||
                          state?.business?._id ||
                          businessID
                          }/products/${row.productID.id}`,
                          {
                              state: {
                                  business: {
                                      id:
                                        state?.business._id ||
                                        businessID,
                                      slug:
                                        state?.business.slug ||
                                        businessID,
                                  },
                                  product: {
                                      id: row.productID.id,
                                      slug: row.productID.id,
                                  },
                              },
                          },
                        );
                    }}
                  >
                      {row.productID?.title?.uz}
                  </Typography>
                );
            }
        },
        {
            field: "barcode",
            headerName: __("Barcode"),
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      {row?.productID?.productTasnif?.internationalCode}
                  </Typography>
                );
            }
        },
        {
            field: "labeling",
            headerName: __("Labeling"),
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      {row?.productID?.isMarked ? "required" : "not required"}
                  </Typography>
                );
            }
        },
        {
            field: "purchasePrice",
            headerName: __("Purchase Price"),
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      <NumericFormat
                        displayType="text"
                        value={row?.purchasePrice / 100}
                        thousandSeparator={" "}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale
                      />
                  </Typography>
                );
            }
        },
        {
            field: "sellingPrice",
            headerName: __("Selling Price"),
            flex: 1,
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      <NumericFormat
                        displayType="text"
                        value={row?.price / 100}
                        thousandSeparator={" "}
                        decimalSeparator={","}
                        decimalScale={2}
                        fixedDecimalScale
                      />
                  </Typography>
                );
            }
        },
        {
            field: "amount",
            flex: 1,
            headerName: __("Amount"),
            renderCell: ({row}) => {
                return (
                  <Typography
                    fontFamily="Manrope, sans-serif"
                    fontWeight={400}
                    fontSize={14}
                    color={colors.common["black"]}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    whiteSpace={"nowrap"}
                  >
                      <NumericFormat
                        displayType="text"
                        thousandSeparator={" "}
                        value={row?.count}
                      />
                  </Typography>
                );
            }
        },
    ];
    const products = formatProductsArr(withdrawal?.products) || [];

    const headerValues = {
        invoice: withdrawal?.invoiceNumber,
        date: getParsedDate(withdrawal?.recieptDate),
        inn: withdrawal?.suplierINN,
        resPerson: withdrawal?.responsiblePerson,
        totalCount: withdrawal?.totalCount,
        branch: withdrawal?.toBranchID?.name || __("Warehouse"),
        totalSum: withdrawal?.totalPrice
    };

    if (loading) return <Preloader />;

    return (
      <div>
          <BreadCrumbs breadcrumbs={breadCrumbsArray}/>
          <div className="withdrawal-page">
              <PageHeader name={__("View Withdrawal")}/>
              <Box
                sx={{marginTop: "24px", overflowX: "scroll", background: "var(--basic-white)", borderRadius: "6px"}}
              >
                  <TableHeader readOnly inputValues={headerValues}/>
                  <Box
                    sx={{
                        "&": {
                            margin: "0",
                            width: "100%"
                        },
                        "& .name-column--cell": {
                            color: colors.green[300],
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                        },
                    }}
                  >
                      <DataGrid
                        rows={products}
                        rowCount={controller.total}
                        page={controller.page}
                        pageSize={controller.pageSize}
                        columns={productColumn}
                        {...config.TABLE_CONFIG}
                      />
                  </Box>
              </Box>
          </div>
      </div>
    );
};

export default PageWithdrawalView;
