import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import config from "../../../config";
import useApiHook from "../../../hooks/useApiHook";
import Preloader from "../../../components/Preloader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import "./styles.sass";
import { translate } from "../../../translations/translate";
import { capitalize } from "../../../utils/capitalize";
import { ApiService } from "../../../utils/ApiService";
import { Box, Modal, Typography, colors } from "@mui/material";
import { NumericFormat } from "react-number-format";
import StatusTag from "../../../components/StatusTag";
import Action from "../../../components/Action";
import Filter from "../../../components/Filter";
import { DataGrid } from "@mui/x-data-grid";
import ModalWindow from "../../../components/ModalWindow";
import { errorToast } from "../../../utils/toast";

const PageCategoryView = () => {

    // LANGUAGE
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const { state } = useLocation();
    const { businessID, id: categoryID } = useParams();
    const storedUserData = useSelector((state) => state.authReducer);
    const token = storedUserData.token;
    const url = `${config.API_BASE_URL}/businesses/${state?.business?.id || state?.business?.slug || businessID
        }/product-categories/${state?.category?.id || categoryID}`;
    const { data, loading, error } = useApiHook(url);
    const category = data?.data?.docs[0];
    const buttons = [
        {
            name: "Back",
            link: `/businesses/${state?.business?.id || businessID}/categories`,
            state: {
                business: {
                    id: state?.business?.id || businessID,
                    name: state?.business?.name,
                },
            },
            className: "outline",
        },
        {
            name: "Edit",
            link: `/businesses/${state?.business?.id || businessID
                }/categories/${state?.category?.id || categoryID}/edit`,
            state: {
                business: {
                    id: state?.business?.id || businessID,
                },
                category: {
                    id: state?.category?.id || categoryID,
                },
            },
            className: "",
        },
    ];

    // LOGIC FOR PRODUCTS DISPLAY
    let products = [];

    if (category?.products.length)
        category?.products.map((item) => {
            let product = {
                title: item.title?.ru || "—",
                productType: capitalize(item.productType) || "—",
                icps: item.productTasnif?.icps || "-",
                price: item.price / 100 || 0,
                discountAmount: item.discount?.value?.amount / 100 || 0,
                discountPercentage: item.discount?.value?.percentage || 0,
                id: item._id,
                slug: item.slug || "",
                status: item.status,
                businessID: businessID,
                businessSlug: state?.business?.slug,
            };
            products.push(product);
        });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [id, setId] = useState();
    const navigate = useNavigate();
    const openModals = (id) => {
        setModalIsOpen(true);
        setId(id);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };
    const removeProduct = () => {
        ApiService.deleteEvent(`/businesses/${state?.business?.id || businessID}/products/${id}`, token)
            .then((res) => {
                if (res.status === 200) {
                    window.location.reload();
                }
            })
            .catch(() => {
                errorToast(__("Some error occurred"));
            });
    };
    const columns = [
        {
            field: "title",
            headerName: __("Title"),
            flex: 2,
            cellClassName: "main-column-cell",
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent={"flex-start"}
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={500}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {row.title}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "productType",
            headerName: __("Type"),
            className: "table-phone",
            flex: 1,
            renderCell: ({ row: { productType } }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {productType}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "icps",
            headerName: __("ICPS"),
            flex: 1.5,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {row.icps}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "price",
            headerName: __("Price"),
            flex: 0,
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <NumericFormat
                            displayType="text"
                            thousandSeparator={" "}
                            decimalSeparator={","}
                            value={row.price}
                            decimalScale={2}
                            fixedDecimalScale={true}
                        />
                    </Box>
                );
            },
        },
        {
            field: "discount",
            headerName: __("Discount"),
            flex: 0,
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        {!!(row.discountAmount || row.discountPercentage) && (
                            <NumericFormat
                                displayType="text"
                                thousandSeparator={" "}
                                decimalSeparator={","}
                                value={
                                    row.discountAmount || row.discountPercentage
                                }
                                suffix={row.discountPercentage !== 0 ? "%" : ""}
                                decimalScale={2}
                                fixedDecimalScale={
                                    row.discountPercentage === 0
                                }
                            />
                        )}
                        {!(row.discountAmount || row.discountPercentage) && (
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={400}
                                fontSize={14}
                                color={colors.common["black"]}
                            >
                                {"-"}
                            </Typography>
                        )}
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: __("Status"),
            type: "string",
            flex: 1.5,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <StatusTag
                            className={`ui-status sm ${props.value === "blocked"
                                ? "question"
                                : "active"
                                }`}
                            text={props.value}
                        />
                    </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Action
                            onClick={() =>
                                navigate(
                                    `/businesses/${state?.business?.slug ||
                                    state?.business?.id ||
                                    businessID
                                    }/products/${row.slug || row.id}/edit`,
                                    {
                                        state: {
                                            business: {
                                                id:
                                                    state?.business.id ||
                                                    businessID,
                                                slug:
                                                    state?.business.slug ||
                                                    businessID,
                                                name: state?.business?.name,
                                            },
                                            product: {
                                                id: row.id,
                                                slug: row.slug,
                                            },
                                        },
                                    },
                                )
                            }
                            onClickView={() =>
                                navigate(
                                    `/businesses/${state?.business?.slug ||
                                    state?.business?.id ||
                                    businessID
                                    }/products/${row.slug || row.id}`,
                                    {
                                        state: {
                                            business: {
                                                id:
                                                    state?.business.id ||
                                                    businessID,
                                                slug:
                                                    state?.business.slug ||
                                                    businessID,
                                                name: state?.business?.name
                                            },
                                            product: {
                                                id: row.id,
                                                slug: row.slug,
                                            },
                                        },
                                    },
                                )
                            }
                            openModal={() => openModals(row?.id)}
                        />
                    </Box>
                );
            },
        },
    ];

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error}</div>;
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    {
                        label: "Categories",
                        link: `/businesses/${state?.business?.id || businessID
                            }/product-categories`,
                    },
                    { label: category?.title?.en },
                ]}
            />
            <div className="page component-page-business-create">
                <form className="page-inner">
                    <PageHeader
                        name={__("View Category")}
                        buttons={buttons}
                        state={{
                            business: {
                                _id: state?.business?.id || businessID,
                                slug: state?.business?.slug || businessID,
                            },
                        }}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            {data?.data?.docs?.map((item, i) => (
                                <div key={i}>
                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-information"></i>
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__("Category Information")}
                                                    </p>
                                                    <p className="card-subtitle"></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-item">
                                            <div className="component-input full">
                                                <label
                                                    className="input-label"
                                                    htmlFor="title"
                                                >
                                                    <input
                                                        defaultValue={
                                                            item?.title?.uz
                                                        }
                                                        id="title"
                                                        className="input input-disabled"
                                                        type="text"
                                                        required={true}
                                                        disabled
                                                    />
                                                    <span className="input-text">
                                                        {__("Title")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-item">
                                            <div className="component-input full">
                                                <label
                                                    className="input-label"
                                                    htmlFor="description"
                                                >
                                                    <textarea
                                                        defaultValue={
                                                            item?.description
                                                                ?.uz
                                                        }
                                                        id="description"
                                                        className="input input_large input-disabled"
                                                        rows={3}
                                                        required={true}
                                                        disabled
                                                    />
                                                    <span className="input-text">
                                                        {__("Description")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-table-product table products-table">
                                        <Box>
                                            <Filter
                                                buttons={[
                                                    {
                                                        text: __("Add Product"),
                                                        link: `/businesses/${state?.business.slug ||
                                                            state?.business.id ||
                                                            businessID
                                                            }/products/create`,
                                                        state: {
                                                            business: {
                                                                _id: state?.business?.id || businessID,
                                                            },
                                                        },
                                                        classNames: "outline",
                                                    },
                                                ]}
                                                description={__("Products")}
                                                filters={{
                                                    isProductType: true,
                                                    isProductCategory: true,
                                                    isPriceRange: true,
                                                    isStatus: true,
                                                }}
                                            />
                                            <Box
                                                m="40px 0 0"
                                                height="75vh"
                                                sx={{
                                                    "&": {
                                                        margin: "0",
                                                        height: "90vh",
                                                        minHeight: "350px",
                                                    },
                                                    "& .name-column--cell": {
                                                        color: colors.green[300],
                                                    },
                                                    "& .MuiDataGrid-footerContainer": {
                                                        borderTop: "none",
                                                    },
                                                }}
                                            >
                                                <DataGrid
                                                    rows={products}
                                                    columns={columns}
                                                    {...config.TABLE_CONFIG}
                                                />
                                            </Box>
                                        </Box>
                                        <Modal
                                            className="delete-modal"
                                            isOpen={modalIsOpen}
                                            onRequestClose={closeModal}
                                        >
                                            <div className="modal-bottom">
                                                <ModalWindow
                                                    title="Are you sure?"
                                                    text="Are you sure you want to permanently delete this product?"
                                                    onClick={closeModal}
                                                    onClickDelete={removeProduct}
                                                />
                                            </div>
                                        </Modal>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageCategoryView;
