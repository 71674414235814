import React from "react";
import { useSelector } from "react-redux";
import Button from "../global/Button";
import { translate } from "../../translations/translate";
import PropTypes from "prop-types";
import "./styles.sass";

const ModalWindow = ({ ...props }) => {
    const lang = useSelector(state => state.languageReducer.lang);
    const __ = key => translate(lang, key);

    return (
        <div className="component-modal-window">
            <div className="modal-inner">
                <div className="modal-title">
                    <h1 className="window-title">{props.title}</h1>
                </div>
                <div className="modal-text">
                    <p className="window-text">{props.text}</p>
                </div>
                <div className="btn-container">
                    <Button
                        className="btn outline sm"
                        text={__("Cancel")}
                        onClick={props.onClick}
                    ></Button>
                    <Button
                        className={`btn ${!props.button && "error"} sm`}
                        text={props.button || __("Delete")}
                        onClick={props.onClickDelete}
                    ></Button>
                </div>
            </div>
        </div>
    );
};
ModalWindow.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    onCLick: PropTypes.func,
    onClickDelete: PropTypes.func,
};

export default ModalWindow;
