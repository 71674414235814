import { connect, useDispatch } from "react-redux";
import SearchBar from "../../SearchBar";
import FilterOptions from "./FilterOptions";
import "./styles.sass";
import { setBusinessTypeAC, setBusinessNameAC, setCashdeskBranchAC } from "../../../redux/actions/filtersAction";

const FilterItem = (props) => {
    // USE DISPATCH
    const dispatch = useDispatch();

    // CALLBACKS
    const onSearchChange = function(e) {
        props.filter === "businessType" && dispatch(props.setBusinessTypeAC(e.target.value));
        props.filter === "businessName" && dispatch(props.setBusinessNameAC(e.target.value));
        props.filter === "cashdeskBranch" && dispatch(props.setCashdeskBranchAC(e.target.value));

    };

    return (
        <div className="filterItem">
            <h2 className="heading-filter">{props.heading}</h2>
            {props.searchBar && <SearchBar onChange={onSearchChange} isOpen={true} className={"smaller"} />}
            {!props.searchBar && <FilterOptions filter={props.filter} page={props.page} />}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, {setBusinessTypeAC, setBusinessNameAC, setCashdeskBranchAC})(FilterItem);
