import * as XLSX from "xlsx";

export const exportToExcel = (data, fileName) => {

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the workbook directly as a file
    XLSX.writeFile(wb, fileName);
  };