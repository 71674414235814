import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { translate } from "../../translations/translate";
import Button from "../../components/global/Button";
import Error404 from "../../assets/images/NotFound.webp";
import "./styles.sass";

const PageError404 = ({ ...props }) => {
    const lang = useSelector(state => state.languageReducer.lang);
    const __ = key => translate(lang, key);

    return (
        <div {...props} className="page page-error-404">
            <div className="page-error-404-main-content">
                <div className="error-404-left-side">
                    <h1 className="component-heading">404</h1>
                    <h2 className="component-error-heading">{__("Page Not Found")}</h2>
                    <div className="component-card-text">
                        <p className="component-text">
                            {__("The page you are looking for does not exist!")}
                        </p>
                    </div>
                    <Link to="/">
                        <Button text={__("Back to home")} className="btn" />
                    </Link>
                </div>
                <div className="error-404-right-side">
                    <img
                        className="error-404-right-side-image"
                        src={Error404}
                        alt="error 404"
                    />
                </div>
            </div>
        </div>
    );
};

export default PageError404;
