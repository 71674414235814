import React, { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import SearchBar from "../SearchBar";
import Button from "../global/Button";
import PropTypes from "prop-types";
import Filters from "../Filters";
import "./styles.sass";
import { useDispatch, useSelector } from "react-redux";
import { resetFilters } from "../../redux/actions/filtersAction";

const Filter = (props) => {
    const dispatch = useDispatch();

    // CHECKING THE ROLE OF THE USER
    const {role} = useSelector(state => state.authReducer);
    const {pathname} = useLocation();
    const isUser = role === "user";

    const filtersMenu = useRef(null);
    const [filterIsOpen, setFilterIsOpen] = useState(false);
    const closeOpenMenus = (e) => {
        if (
            filtersMenu?.current &&
            filterIsOpen &&
            !filtersMenu?.current?.contains(e?.target)
        ) {
            setFilterIsOpen(false);
        }
    };
    document.addEventListener("mousedown", closeOpenMenus);
    const onOpenFiltersClick = function (e) {
        e.preventDefault();
        setFilterIsOpen((filterIsOpen) => !filterIsOpen);
    };

    const conditionPath = pathname.split("/")[3];

    return (
        <div className="component component-filter">
            <div className="left">
                <h2 className="description">{props.description}</h2>
            </div>
            <div className="right">
                <div className="component-ui-filter">
                    {
                       (isUser || conditionPath === "receipts" || conditionPath === "categories" || conditionPath === "products")
                        && <SearchBar search={props.search} setSearch={props.setSearch} searchWithoutSpaces={props.searchWithoutSpaces} />
                    }
                    <div className="component-filter-inner" ref={filtersMenu}>
                        <a
                            className="component-filter-link"
                            onClick={onOpenFiltersClick}
                        >
                            <i className="icon-filter" />
                        </a>
                        {filterIsOpen && (
                            <Filters
                                filters={props.filters}
                                page={props.page}
                            />
                        )}
                    </div>
                    <div className="component component-reload">
                        <a
                            className="component-reload-link"
                            onClick={(e) => {
                                e.preventDefault();
                                dispatch(resetFilters());
                            }}
                        >
                            <i className="icon-reload-update" />
                        </a>
                    </div>
                </div>
                {!props.buttons && !props.withoutButton && (
                    <Link
                        className="link-button"
                        to={props.createLink}
                        state={props.state}
                    >
                        <Button className="btn outline sm" text={props.text} />
                    </Link>
                )}
                {props.buttons?.length > 0 && (
                    <div className={"btns-container"}>
                        {props.buttons.map((button, index) => (
                            <Link
                                key={index}
                                className="link-button"
                                to={button.link}
                                state={button.state}
                                onClick={(e) => {
                                    if (button.onClick) {
                                        e.preventDefault();
                                        button.onClick();
                                    }
                                }}
                            >
                                <Button
                                    className={`btn ${button.classNames}`}
                                    text={button.text}
                                />
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

Filter.propTypes = {
    createLink: PropTypes.string,
    text: PropTypes.string,
    state: PropTypes.object,
    buttons: PropTypes.array,
    className: PropTypes.string,
    searchWithoutSpaces: PropTypes.bool,
    setSearch: PropTypes.func
};
export default Filter;
