import React from "react";
import { capitalize } from "../../utils/capitalize";
import { translate } from "../../translations/translate";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import "../../assets/fonts/epos-icons/style.css";
import "./styles.sass";

const StatusTag = (props) => {
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const statusesArr = {
        active: "green",
        free: "grey",
        completed: "cyan ",
        blocked: "red",
        deleted: "red",
        expired: "red",
        defective: "red",
        "in progress": "yellow",
        reserved: "yellow",
        resolved: "blue",
    };

    const status = props?.text?.toLowerCase();
    const className = statusesArr[status] || "grey";

    return (
        <div className="component-ui-status">
            <div className={`ui-status ${props.className} ${className}`}>
                {/* <div><i className={props.iconClass}></i></div> */}
                {__(capitalize(props?.text))}
            </div>
        </div>
    );
};
StatusTag.propTypes = {
    text: PropTypes.string,
    iconClass: PropTypes.string,
    className: PropTypes.string,
};

export default StatusTag;
