import React, {useEffect, useRef, useState} from "react";
import {ImageConfig, imageTypes} from "./config";
import config from "../../config";
import "./styles.sass";
import Button from "../../components/global/Button";
import {useSelector} from "react-redux";
import {transliterate} from "../../utils/transliterate";
import axios from "axios";
import {errorToast, successToast} from "../../utils/toast";
import PropTypes from "prop-types";
import jwt_decode from "jwt-decode";
import {capitalize} from "../../utils/capitalize";
import {translate} from "../../translations/translate";

const DragAndDrop = ({partiallyUpload = null, setPartiallyUpload = () => {}, isLoading = false, ...props}) => {
    const lang = useSelector((state) => state.languageReducer.lang);
    const {token} = useSelector((state) => state.authReducer);
    const __ = (key) => translate(lang, key);
    const wrapperRef = useRef(null);

    const fiscalModulesFailed = partiallyUpload?.fiscalImportData?.failedValidationInstances?.length || 0;
    const fiscalModulesSuccess = partiallyUpload?.fiscalImportData?.validInstances?.length || 0;
    const fiscalModulesTotal = fiscalModulesSuccess + fiscalModulesFailed;

    const [fileList, setFileList] = useState([]);
    const onDragEnter = () => wrapperRef.current.classList.add("dragover");
    const decodedToken = jwt_decode(token);

    const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

    const onDrop = () => wrapperRef.current.classList.remove("dragover");

    const onFileDrop = (e) => {
        const newFile = e.target.files[0];
        if (newFile) {
            if (props.isImage && !imageTypes.includes(newFile.type.toLowerCase()))
                errorToast(__("You are required to upload an image here. Available formats: .jpg, .png, .webp, .svg"));
            else
                setFileList((prev) => [...prev, newFile]);
        }
    };
    useEffect(() => {
        if (fileList.length > 0) {
            if (!wrapperRef.current.classList.contains("hidden"))
                wrapperRef.current.classList.add("hidden");
        } else {
            wrapperRef.current.classList.remove("hidden");
            setPartiallyUpload(null);
        }
    }, [fileList]);

    const fileRemove = (file) => {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
    };
    const uploadFile = async () => {
        if (fileList.length === 0 || props.isUpload === false) {
            return;
        }

        try {
            const originalFile = fileList[0];
            const newFileName = transliterate(originalFile.name);

            // Create a new file with the modified name
            const blob = originalFile.slice(0, originalFile.size, originalFile.type);
            const newFile = new File([blob], newFileName, {type: originalFile.type});

            // Prepare FormData with the new file
            const formData = new FormData();
            formData.append("uploadType", props.uploadType || "document");
            formData.append("file", newFile);
            formData.append("docModel", props.docModel || capitalize(decodedToken.userType) || "User");
            formData.append("ownerID", props.ownerID || decodedToken.ID || "");

            const headers = {
                // "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            };

            const response = await axios.post(`${config.API_BASE_URL}/uploads/create`, formData, {headers});
            handleUploadSuccess(response.data.data);
        } catch (error) {
            handleUploadError();
        }
    };

    const handleUploadSuccess = (data) => {
        successToast(__("File was successfully uploaded"));
        props.setter({
            id: data._id,
            url: data.fileInfo.url,
            name: data.fileInfo.originalName,
        });
        props.closeDragNDrop();
    };

    const handleUploadError = () => {
        errorToast(__("Something went wrong. ") + __("Delete your file and try again"));
    };

    return (
        <div className="component-modal-window">
            <div className="modal-inner modal-drag-drop height-auto">
                <div className="close-modal" onClick={props.closeDragNDrop}/>
                <div className="modal-content">
                    <div className="card-info">
                        <div className="card-icon">
                            <i className="icon-upload"/>
                        </div>
                        <div className="card-text">
                            <p className="card-title">
                                {__("Upload a file")}
                            </p>
                        </div>
                    </div>
                    <div className="drag-and-drop">
                        <div
                            ref={wrapperRef}
                            className="drop-file-input"
                            onDragEnter={onDragEnter}
                            onDragLeave={onDragLeave}
                            onDrop={onDrop}
                        >
                            <div className="drop-file-input__label">
                                <p>
                                    <i className="icon-upload"/>
                                    {__("Drag & Drop or")}
                                    <label htmlFor="file-input" className="label"> {__("Choose file")}</label>
                                    {__("to upload")}
                                </p>
                            </div>
                            <input
                                className="input-file"
                                type="file"
                                id="file-input"
                                value=""
                                onChange={onFileDrop}
                            />
                        </div>
                        {fileList.length > 0 ? (
                            <div className="files-preview">
                                {/* <p className="drop-file-preview__title">
                                Ready to upload
                            </p> */}
                                {fileList.map((item, index) => (
                                    <div
                                        key={index}
                                        className="files-preview__item"
                                    >
                                        <div className="file-info-container">
                                            <img
                                                className="icon"
                                                src={
                                                    item.type.includes("image") ?
                                                        URL.createObjectURL(item) :
                                                        ImageConfig[
                                                            item.type.split("/")[1]
                                                            ] || ImageConfig["default"]
                                                }
                                                alt=""
                                            />
                                            <div className="file-info">
                                                <p className="text">{item.name}</p>
                                                <p className="text">
                                                    {
                                                        item.size < 1024 ? `${item.size} B` :
                                                            (item.size >= 1024 && item.size < (1024 * 1024)) ? `${(item.size / 1024).toFixed(2)} KB` :
                                                                `${(item.size / 1024 / 1024).toFixed(2)} MB`
                                                    }
                                                </p>
                                            </div>
                                        </div>

                                        <div className="drop-actions">
                                            <a
                                                href={`${URL.createObjectURL(
                                                    item,
                                                )}`}
                                                target="_blank"
                                                className="action-icon drop-file-links"
                                                rel="noreferrer"
                                            >
                                                <i className="icon-view"/>
                                            </a>
                                            <a
                                                href={`${URL.createObjectURL(
                                                    item,
                                                )}`}
                                                download
                                                className="action-icon drop-file-links"
                                            >
                                                <i className="icon-information"/>
                                            </a>
                                            <span
                                                className="action-icon drop-file-links delete-link"
                                                onClick={() => fileRemove(item)}
                                            >
                                            <i className="icon-delete"></i>
                                        </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : null}

                        {isLoading ? <p>{__("Loading")}...</p> : (
                            partiallyUpload?.failedProducts ? (
                                <div className="files-log">
                                    <div
                                        className="files-log__item">{`${partiallyUpload.successful} ${__("of")} ${partiallyUpload.total} ${__("products imported successfully")}`}</div>
                                    <div className="files-log__item">{__("List of failed products:")}</div>
                                    {partiallyUpload.failedProducts.map(({product, reason, icpsError}, idx) => (
                                        <div className="files-log__item"
                                             key={idx}>{product.title.uz} - {__("Reason:")} {`${__("ICPS")} ${icpsError} ${__("was not found in Tasnif Products Database")}`} </div>
                                    ))}
                                    <div className="files-log__item">
                                        {__("Number of failed Products:")} {partiallyUpload.failedProducts?.length}
                                    </div>
                                </div>
                            ) : partiallyUpload?.fiscalImportData ? (
                                <div className="files-log">
                                    <div
                                        className="files-log__item">{`${fiscalModulesSuccess} ${__("of")} ${fiscalModulesTotal} ${__("fiscal modules imported successfully")}`}</div>
                                    {fiscalModulesFailed > 0 &&
                                        <div className="files-log__item">{__("List of failed modules:")}</div>}
                                    {partiallyUpload.fiscalImportData.failedValidationInstances.map(({
                                                                                                         fiscalID,
                                                                                                         virtualID,
                                                                                                         validationError
                                                                                                     }, idx) => (
                                        <div className="files-log__item" key={idx}>Fiscal
                                            ID:{fiscalID} - {__("Reason:")} {__(validationError)} </div>
                                    ))}
                                    <div className="files-log__item">
                                        {__("Number of failed Products:")} {fiscalModulesFailed}
                                    </div>
                                </div>
                            ) : partiallyUpload?.notFoundProducts && (
                              <div className="files-log">
                                  <div className="files-log__item">
                                      {`${partiallyUpload.successful} ${__("of")} ${partiallyUpload.total} ${__("products imported successfully")}`}
                                  </div>
                                  <div className="files-log__item">{__("List of failed products:")}</div>
                                  {partiallyUpload.notFoundProducts.map(({name, article}, idx) => (
                                    <div className="files-log__item" key={idx}>
                                        {name} - {__("Article:")} {`${article} ${__("was not found in the catalog")}`}
                                    </div>
                                  ))}
                                  <div className="files-log__item">
                                      {__("Number of failed Products:")} {partiallyUpload.failed}
                                  </div>
                              </div>
                            )
                        )}

                    </div>
                    <div className="modal-footer">
                        <div className="note-container">
                            <p className="text">{__("You can upload only one document.")}</p>
                        </div>
                        <div className="btns-container">
                            <Button
                              className="btn outline sm"
                              text={__("Cancel")}
                              onClick={props.closeDragNDrop}
                            />
                            <Button
                              className="btn sm"
                              disabled={props.isLoading}
                              text={__("Save")}
                              type="submit"
                              onClick={
                                  (e) => {
                                        e.preventDefault();
                                        if (props.isUpload === false) {
                                            if (fileList.length > 0)
                                                props.setter(fileList[0]);
                                        } else {
                                            uploadFile();
                                        }
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

DragAndDrop.propTypes = {
    setter: PropTypes.func,
    closeDragNDrop: PropTypes.func,
    required: PropTypes.bool,
    uploadType: PropTypes.string,
    docModel: PropTypes.string,
    isImage: PropTypes.bool
};
export default DragAndDrop;
