import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { fiscalPref } from "../../../hooks/fiscalPref";
import { ApiService } from "../../../utils/ApiService";
import { errorToast, successToast } from "../../../utils/toast";
import { translate } from "../../../translations/translate";
import { useSelector } from "react-redux";
import { PatternFormat } from "react-number-format";
import CheckBox from "../../../components/CheckBox";
import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import cleanseObject from "../../../utils/cleanseObject";
import SelectComponent from "../../../components/Select";
import BreadCrumbs from "../../../components/BreadCrumbs";
import "./styles.sass";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";

const PageCashDeskCreate = ({ ...props }) => {

    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    // CASHDESK TYPES
    const applicationTypes = [
        { label: __("Cashdesk"), value: "cashdesk" },
        { label: __("Communicator"), value: "communicator" },
        { label: __("Mobile"), value: "mobile" },
    ];

    const PhoneMask = "+998 (00) 000-00-00";

    const phoneEmailMask = [
        {
            mask: PhoneMask,
        },
    ];
    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false);


    const storedUserData = useSelector((state) => state.authReducer);
    const token = storedUserData.token;
    const { state } = useLocation();
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();
    let business, branch;
    if (state?.business?._id) {
        const businessReqResult = useApiHook(
            `${config.API_BASE_URL}/businesses/${state?.business?._id}`,
        );
        if (businessReqResult.data?.data?.docs?.length === 1) {
            business = businessReqResult.data.data.docs[0];
        }
    }
    if (state?.branch?._id) {
        const branchReqResult = useApiHook(
            `${config.API_BASE_URL}/branches/${state?.branch?._id}`,
        );
        if (branchReqResult.data?.data?.docs?.length === 1) {
            branch = branchReqResult.data.data.docs[0];
        }
    }
    const [cashdesk, setCashdesk] = useState({
        businessID: state?.business?._id || undefined,
        branchID: state?.branch?._id || undefined,
        fiscalModule: undefined,
        applicationType: applicationTypes[0].value,
    });

    const [fiscalID, setFiscalID] = useState("");
    const [fiscalLetters, setFiscalLetters] = useState("");
    const [fiscalPrefix, setFiscalPrefix] = useState("UZ");
    const [fiscalNumbers, setFiscalNumbers] = useState("");
    const [applicationType, setApplicationType] = useState("");

    useEffect(() => {
        setCashdesk({
            ...cashdesk,
            fiscalModule: fiscalPrefix + fiscalNumbers,
        });
    }, [fiscalPrefix, fiscalNumbers]);
    useEffect(() => {
        setCashdesk({
            ...cashdesk,
            applicationType,
        });
    }, [applicationType]);

    const onCancelClick = function () {
        navigate("/cashdesks");
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if(cashdesk.fiscalModule.length !== 14) {
            errorToast(__("You did not provide a proper fiscal module"));
            return;
        }
        if(!cashdesk.applicationType) {
            errorToast(__("Select an application type"));
            return;
        }
        setCashdesk(cashdesk);
        const body = cleanseObject(cashdesk);
        if (isSubmitting) return;
        setIsSubmitting(true);
        if (body.businessID && body.branchID && body.fiscalModule) {
            ApiService.postEvent("/cashdesks/create", token, body)
                .then((response) => {
                    if (response.status === 201) {
                        successToast(__("Successfully created"));
                        setIsSubmitting(false);

                        navigate("/businesses");
                    } else {

                        errorToast(response.message);
                    }
                })
                .catch((error) => {
                    setIsSubmitting(false);

                    errorToast(error.message);
                });
        } else errorToast(__("Something Went Wrong"));
    };
    const buttons = [
        {
            name: "Back",
            link: "/cashdesks",
            state: {},
            className: "outline",
        },
    ];
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Cashdesks", link: "/cashdesks" },
                    { label: "Create" },
                ]}
            />
            <div {...props} className="page component-page-cashdesk-create">
                <form onSubmit={onSubmit} className="page-inner">
                    <PageHeader
                        name={__("Create Cashdesk")}
                        buttons={buttons}
                    />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <i className="icon-information"></i>
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Cashdesk Information")}
                                            </p>
                                            <p className="card-subtitle"></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="BusinesshName"
                                        >
                                            <input
                                                defaultValue={
                                                    state?.business?.name ||
                                                    business?.slug ||
                                                    ""
                                                }
                                                disabled={true}
                                                id="BusinesshName"
                                                className="input"
                                                type="text"
                                                placeholder={__(
                                                    "Enter your company name",
                                                )}
                                            />
                                            <span className="input-text">
                                                {__("Business")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="BranchName"
                                        >
                                            <input
                                                defaultValue={
                                                    state?.branch?.name ||
                                                    branch?.slug ||
                                                    ""
                                                }
                                                disabled={true}
                                                id="BranchName"
                                                className="input"
                                                type="text"
                                                placeholder={__(
                                                    "Enter branch name",
                                                )}
                                            />
                                            <span className="input-text">
                                                {__("Branch")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item fiscal-input-item">
                                    <div className="component-input input-item input-right">
                                        <div className="component-switcher fiscal-switcher">
                                            <label
                                                className="input-label"
                                                htmlFor="FiscalIDLetters"
                                            >
                                                <span className="input-text">
                                                    {__("Fiscal ID")}
                                                </span>
                                                <SelectComponent
                                                    options={fiscalPref}
                                                    onChange={(e) =>
                                                        setFiscalPrefix(e.value)
                                                    }
                                                    name="FiscalIDLetters"
                                                    value={fiscalPref.find(
                                                        (pref) =>
                                                            pref.value ===
                                                            fiscalPrefix,
                                                    )}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="FiscalID"
                                        >
                                            <PatternFormat
                                                format="#### #### ####"
                                                mask="_"
                                                placeholder="____ ____ ____"
                                                value={fiscalID.fiscalNumbers}
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    setFiscalNumbers(value);
                                                }}
                                                id="FiscalID"
                                                className="input fiscal-padding"
                                                type="tel"
                                            />
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label className="input-label" htmlFor="applicationType">
                                            <span className="input-text">{__("Type")}</span>
                                            <SelectComponent
                                                options={applicationTypes}
                                                onChange={(e) => setApplicationType(e.value)}
                                                name="applicationType"
                                                value={applicationTypes.find((type) => type.value === applicationType)}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-not-save"></i>
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                                className="btn outline sm"
                                text={__("Cancel")}
                                onClick={onCancelClick}
                                type="button"
                            />
                            <Button
                                className="btn sm"
                                text={__("Save")}
                                type="submit"
                                disabled={isSubmitting}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageCashDeskCreate;
