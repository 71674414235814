import {ApiService} from "../ApiService";

export const searchDealer = async (query, token) => {
  try{
    const response = await ApiService.getResources(`/users/search/user/?role=dealer&name=${query}`, token);
    if (!response.data || !Array.isArray(response.data.docs)) {
      return [];
    } else {
      return response.data.docs;
    }
  }
  catch (e){
    return [];
  }
};