import React from "react";
import PropTypes from "prop-types";
import "./styles.sass";
import {useNavigate} from "react-router-dom";
import {url} from "../../utils/generalizeURL";
import defaultAvatar from "../../assets/images/user.png";

const UserLinkCard = (props) => {
    const navigate = useNavigate();
    // const clickHandler = props.userLink ? navigate(props.userLink) : props.onClick;
    const clickHandler = props.onClick;
    return (
        <div
            className={"component-user-link-card " + props.classNames}
            onClick={clickHandler}
        >
            {/* <div className="component-profile-switcher-handler" onClick={handleProfile}/> */}
                <div className="component-card-avatar">
                     <img src={props.avatar ? url(props.avatar) : defaultAvatar} className="img" alt="" onError={(e) => {
                         e.target.src = defaultAvatar;
                     }} />
                </div>
            <p className="component-card-name">{props.name}</p>
        </div>
    );
};

UserLinkCard.propTypes = {
    userLink: PropTypes.string,
    avatar: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func,
    classNames: PropTypes.string,
    imageClassName: PropTypes.string,
};

export default UserLinkCard;