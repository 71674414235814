import React from "react";
import PropTypes from "prop-types";
import "./styles.sass";

const Button = ({
    onSubmit,
    text,
    className,
    wrapperClassname,
    onClick,
    type,
    disabled,
    children,
    ...otherProps
}) => {
    return (
        <div className={`component-card-button ${wrapperClassname}`}>
            <button
                {...otherProps}
                disabled={disabled}
                onClick={onClick}
                onSubmit={onSubmit}
                className={className}
                type={type}
            >
                {text !== undefined ? text : children}
            </button>
        </div>
    );
};
Button.propTypes = {
    text: PropTypes.string,
    onSubmit: PropTypes.func,
    className: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Button;
