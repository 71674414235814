import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import "dayjs/locale/en-gb";
import "./styles.sass";

export default function BasicDatePicker(props) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                renderInput={(props) => (
                    <TextField
                        {...props} 
                        size="small" 
                        helperText={null}
                    />
                )}
                disabled={props.disabled}
                value={props.value}
                onChange={props.onChange}
                className={props.className}
                format="DD/MM/YYYY"
                inputFormat="DD/MM/YYYY"
                slotProps={{
                    textField: {
                        helperText: "DD/MM/YYYY",
                    },
                }}
            />
        </LocalizationProvider>
    );
}

BasicDatePicker.propTypes = {
    onChange: PropTypes.func,
};
