import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { translate } from "../../translations/translate";
import listenForOutsideClicks from "../../utils/listen-for-outside-clicks";
import "./styles.sass";

const Action = (props) => {
    const {
        onClick,
        onClickView,
        openModal,
        view = true,
        edit = true,
        deleteAction = true
    } = props;
    const [open, setOpen] = useState(false);
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const handleOpen = () => {
        setOpen(!open);
    };
    const menuRef = useRef(null);
    const [listening, setListening] = useState(false);
    useEffect(
        listenForOutsideClicks(listening, setListening, menuRef, setOpen),
    );
    return (
        <div className="component component-ui-table-action">
            <div className="action-menu" onClick={handleOpen} ref={menuRef}>
                <i className="icon-more-vertical" />
            </div>
            {open && (
                <div className="drop-down-menu">
                    {view && (
                      <div className="menu-row" onClick={onClickView}>
                          <i className="icon-view"></i>
                          <span className="text">{__("View")}</span>
                      </div>
                    )}
                    {edit && (
                      <div className="menu-row"  onClick={onClick}>
                          <i className="icon-edit"></i>
                          <span className="text">{__("Edit")}</span>
                      </div>
                    )}
                    {deleteAction && (
                      <div className="menu-row" onClick={openModal}>
                          <i className="icon-delete"></i>
                          <span className="text text-margin">{__("Delete")}</span>
                      </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Action;
