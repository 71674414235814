// Packets
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

// Components
import HeaderNavigation from "../../components/LayoutMain/HeaderNavigation";
import SidebarNavigation from "../../components/LayoutMain/SidebarNavigation";

// Styles
import "./styles.sass";

// utils
import { isTokenExpired } from "../../utils/isTokenExpired";

const LayoutMain = ({ ...props }) => {
    const {token} = useSelector(state => state.authReducer);
    const location = useLocation();
    const navigate = useNavigate();

    // CHECKING TOKEN EXPIRATION
    useEffect(() => {
        if(isTokenExpired(token)) {
            navigate("/auth");
        }
    }, [location]);

    useEffect(() => {
        if (location.pathname === "/app" || location.pathname === "/app/")
            navigate("/app/dashboard");
    }, [location]);

    return (
        <div {...props} className="layout layout-main">
            <SidebarNavigation />
            <div className="layout-content">
                <HeaderNavigation />
                <Outlet />
            </div>
        </div>
    );
};

export default LayoutMain;
