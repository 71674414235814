import FilterItem from "./FilterItem";
import "./styles.sass";
import Button from "../global/Button";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { translate } from "../../translations/translate";

const Filters = (props) => {
    // Language
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const state = useSelector(state => state.filter);
    const navigate = useNavigate();

    const reset = function () {
        navigate(`/${props.page}`, {
            state: {}
        });
    };

    const apply = function () {
        navigate(`/${props.page}`, {
            state: { ...state, ...props.filters?.navigateState },
        });
    };

    return (
        <div className="filters">
            {props.filters?.isBranchFilter && <FilterItem filter={"branchFilter"} page={props.page} heading={__("Filter")} />}
            {props.filters?.isRole && <FilterItem filter={"role"} page={props.page} heading={__("Role")} />}
            {props.filters?.isStatus && <FilterItem filter={"status"} page={props.page} heading={__("Status")} />}
            {props.filters?.isCashdesk && <FilterItem filter={"cashdesk"} heading={__("Cashdesk")} />}
            {props.filters?.isApproved && <FilterItem filter={"approved"} heading={__("Approved")} />}
            {props.filters?.isBusinessType && <FilterItem filter={"businessType"} searchBar={true} heading={__("Type")} />}
            {props.filters?.isStaffBranch && <FilterItem filter={"staffBranch"} searchBar={true} heading={__("Branch")} />}
            {props.filters?.isProductType && <FilterItem filter={"productType"} heading={__("Type")} />}
            {props.filters?.isProductCategory && <FilterItem filter={"productCategory"} searchBar={true} heading={__("Category")} />}
            {props.filters?.isPriceRange && <FilterItem filter={"priceRange"} heading={__("Price Range")} />}
            {props.filters?.isCategoriesCategory && <FilterItem filter={"categoriesCategories"} heading={__("Category")} />}
            {props.filters?.isBusiness && <FilterItem filter={"businessName"} searchBar={true} heading={__("Business")} />}
            {props.filters?.isCashdeskBranch && <FilterItem filter={"cashdeskBranch"} searchBar={true} heading={__("Branch")} />}
            {props.filters?.isExpiresAt && <FilterItem filter={"expiresAt"} heading={__(`${props.filters?.dateHeading || "Expires At"}`)} />}
            {props.filters?.versions && <FilterItem filter={"cashdeskVersions"} heading={__("Versions")} />}
           {/* <div className="layout__2 layout__2-centered">
                <Button onClick={reset} className="btn sm outline" text={__("Reset")} />
                <Button onClick={apply} className="btn" text={__("Apply")} />
            </div> */}
        </div>
    );
};
export default Filters;
