import config from "../../config";
import useApiHook from "../../hooks/useApiHook";
import {capitalize} from "../capitalize";

const getUserRoles = () => {
    // Retrieve available User Roles
    const rolesURL = `${config.API_BASE_URL}/users-roles`;
    const {data: rolesData} = useApiHook(rolesURL);

    return rolesData?.data?.docs?.map((item) => {
        return {
            value: item._id,
            label: capitalize(item.title)
        };
    });
};

export default getUserRoles;