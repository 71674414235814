const moment = require("moment");

const getParsedDate = (timestamp, needsTime = false, needsOnlyTime = false) => {
    if (!timestamp) {
        return "";
    }

    const parsedDate = moment(timestamp);

    let datetime;

    if (needsTime) {
        const formatString = needsOnlyTime ? "HH:mm" : "DD.MM.YYYY HH:mm";
        datetime = parsedDate.format(formatString);
    } else {
        datetime = parsedDate.format("DD.MM.YYYY");
    }

    return datetime;
};

module.exports = { getParsedDate };