import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import CardVerificationInput from "../../../components/LayoutBasic/CardVerificationInput";
import Button from "../../../components/global/Button";
import Header from "../../../components/LayoutBasic/Header";
import Timer from "../../../components/LayoutBasic/Timer/config";
import { translate } from "../../../translations/translate";
import { authAction } from "../../../redux/actions/authAction";
import "./styles.sass";

const PageVerification = ({ ...props }) => {
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [filled, setFilled] = useState(0);
    const [isTimerCompleted, setIsTimerCompleted] = useState(false);

    const { state } = useLocation();
    const dispatch = useDispatch();

    useEffect(
        (e) => {
            if (filled === 5) {
                handleSubmit(e);
            }
        },
        [filled],
    );

    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const handleSubmit = (e) => {
        if (e) e.preventDefault();
        const body = {
            phone: state?.phone,
            password: state?.password,
            verificationToken: state.verificationToken,
            otp: otp.join(""),
        };

        dispatch(authAction(state.authLink, body));
    };

    const handleResendOTP = () => {
        const body = {
            phone: state?.phone,
            password: state?.password,
        };

        dispatch(authAction(state.authLink, body));
        setIsTimerCompleted(false);
    };

    const handleTimerFinish = () => {
        setIsTimerCompleted(true);
    };

    return (
        <div {...props} className="page page-verification">
            <Header />
            <div className="component-card-verification">
                <div className="card-inner">
                    <h1 className="component-heading">
                        {__("Enter the code from SMS")}
                    </h1>
                    <div className="component-card-text">
                        <p className="component-text">
                            {__(
                                "Please enter the verification code we sent to",
                            )}
                            {` ${state?.phone}`}
                        </p>
                    </div>
                    <form className="card-form" onSubmit={handleSubmit}>
                        <div className="inputs-block">
                            <CardVerificationInput
                                otp={otp}
                                setOtp={setOtp}
                                submit={handleSubmit}
                                setFilled={setFilled}
                            />
                        </div>
                        <Button
                          className="btn"
                          text={__("Submit")}
                          disabled={filled < 5}
                        />
                    </form>
                    {isTimerCompleted ? (
                      <Button
                        onClick={handleResendOTP}
                        className="button-clear"
                        type="button"
                      >
                          {__("Send the code again")}
                      </Button>
                    ) : (
                      <>
                          <Timer onFinish={handleTimerFinish} />
                          <Link className="verification-link" to="/">
                              <div className="verification-previous-page">
                                  <p className="verification-text">
                                      {__("Number entered incorrectly?")}
                                  </p>
                              </div>
                          </Link>
                      </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PageVerification;
