import { DEFAULT_LANGUAGE } from "../../config";

const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

const defaultState = {
    lang: DEFAULT_LANGUAGE,
};

export function languageReducer(state = defaultState, action) {
    switch (action.type) {
        case CHANGE_LANGUAGE:
            return { ...state, lang: action.payload };

        default:
            return state;
    }
}

export const changeLanguageAction = (payload) => ({
    type: CHANGE_LANGUAGE,
    payload,
});
