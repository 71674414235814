export const mockCartDataTeam = [
    {
        id: 1,
        owner: "Avatar",
        ownerName: "Gulsanam",
        businessName: "Tobacco shop",
        createdAt: "12/01/2022",
        cashDesk: "123456",
        status: "Active",
        action: "Delete",
    },
    {
        id: 2,
        owner: "Avatar",
        ownerName: "Gulsanam",
        businessName: "Tobacco shop",
        createdAt: "12/01/2022",
        cashDesk: "123456",
        status: "Active",
        action: "Delete",
    },
    {
        id: 3,
        owner: "Avatar",
        ownerName: "Gulsanam",
        businessName: "Tobacco shop",
        createdAt: "12/01/2022",
        cashDesk: "123456",
        status: "Active",
        action: "Delete",
    },
    {
        id: 4,
        owner: "Avatar",
        ownerName: "Gulsanam",
        businessName: "Tobacco shop",
        createdAt: "12/01/2022",
        cashDesk: "123456",
        status: "Active",
        action: "Delete",
    },
];