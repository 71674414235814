const fiscalLetter = [
    {
        name: "UZ",
        title: "UZ",
    },
    {
        name: "VG",
        title: "VG",
    },
    {
        name: "NA",
        title: "NA",
    },
    {
        name: "ZZ",
        title: "ZZ",
    },
    {
        name: "EP",
        title: "EP",
    },
    {
        name: "EZ",
        title: "EZ",
    },
    {
        name: "LG",
        title: "LG",
    },
    {
        name: "YZ",
        title: "YZ",
    },
];
export const fiscalPref = fiscalLetter.map((item) => {
    return { value: item.name, label: item.title };
});
