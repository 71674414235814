import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { translate } from "../../../translations/translate";
import { useLocation, useParams } from "react-router";
import PageHeader from "../../../components/PageHeader";
import BreadCrumbs from "../../../components/BreadCrumbs";

import "./styles.sass";

const PageCashdeskEPSView = ({ ...props }) => {
    const { state } = useLocation();
    const { id } = useParams();
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const eps = state?.eps;
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();
    const buttons = [
        {
            name: "Back",
            link: `/cashdesks/${state?.cashdesk?._id || id}`,
            state: {},
            className: "outline",
        },
        {
            name: "Edit",
            link: `/cashdesks/${
                state?.cashdesk?.uniqueID || state?.cashdesk?._id || id
            }/eps/edit`,
            state: {
                cashdesk: {
                    uniqueID: state?.cashdesk?.uniqueID,
                    _id: state?.cashdesk?._id,
                },
                eps: eps, // UNCOMMENT AFTER EPS DATA IS RETURNED FROM BACKEND
            },
            className: "outline",
        },
    ];

    const [obj, setObj] = useState({
        EPS: {
            click: {
                SERVICE_ID: null,
                MERCHANT_ID: null,
                SECRET_KEY: null,
                MERCHANT_USER_ID: null,
                USERNAME: null,
                PASSWORD: null,
            },
            uzum: {
                SERVICE_ID: null,
                MERCHANT_ID: null,
                SECRET_KEY: null,
                MERCHANT_USER_ID: null,
            },
            payme: {
                ID: null,
                Key: null,
            },
            anor: {
                BRANCH_ID: null,
                SECRET_KEY: null,
            },
        },
    });

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    { label: "Cashdesks", link: "/cashdesks" },
                    { label: "EPS" },
                ]}
            />
            <div {...props} className="page component-page-cashdesk-edit">
                <div className="page-inner">
                    <PageHeader name={__("Cashdesk EPS")} buttons={buttons} />
                    <div className="cards">
                        <div className="left-side-cards">
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <span className="uzum" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Uzum")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="MerchantID"
                                        >
                                            <input
                                                placeholder="Enter Merchant ID"
                                                id="MerchantID"
                                                className="input"
                                                type="text"
                                                defaultValue={
                                                    obj.EPS?.uzum
                                                        ?.MERCHANT_ID ||
                                                    eps?.uzum?.MERCHANT_ID ||
                                                    ""
                                                }
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Merchant ID")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="ServiceID"
                                        >
                                            <input
                                                placeholder="Enter Service ID"
                                                id="ServiceID"
                                                className="input"
                                                type="text"
                                                defaultValue={
                                                    obj.EPS?.uzum?.SERVICE_ID ||
                                                    eps?.uzum?.SERVICE_ID ||
                                                    ""
                                                }
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Service ID")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="MerchantUserID"
                                        >
                                            <input
                                                placeholder="Enter Merchant User ID"
                                                id="MerchantUserID"
                                                className="input"
                                                type="text"
                                                defaultValue={
                                                    obj.EPS?.uzum
                                                        ?.MERCHANT_USER_ID ||
                                                    eps?.uzum
                                                        ?.MERCHANT_USER_ID ||
                                                    ""
                                                }
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Merchant User ID")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="SecretKey"
                                        >
                                            <input
                                                placeholder="Enter Secret Key"
                                                id="SecretKey"
                                                className="input"
                                                type="text"
                                                defaultValue={
                                                    obj.EPS?.uzum?.SECRET_KEY ||
                                                    eps?.uzum?.SECRET_KEY ||
                                                    ""
                                                }
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Secret Key")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <span className="anor"></span>
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Anor")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="BranchIDAnor"
                                        >
                                            <input
                                                placeholder="Enter Branch ID"
                                                id="BranchIDAnor"
                                                className="input"
                                                type="text"
                                                defaultValue={
                                                    obj.EPS?.anor?.BRANCH_ID ||
                                                    eps?.anor?.BRANCH_ID ||
                                                    ""
                                                }
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Branch ID")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="SecretKeyAnor"
                                        >
                                            <input
                                                placeholder="Enter Service KEY"
                                                id="SecretKeyAnor"
                                                className="input"
                                                type="text"
                                                defaultValue={
                                                    obj.EPS?.anor?.SECRET_KEY ||
                                                    eps?.anor?.SECRET_KEY ||
                                                    ""
                                                }
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Secret KEY")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <span className="payme" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Payme")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="id"
                                        >
                                            <input
                                                placeholder="Enter ID"
                                                id="id"
                                                className="input"
                                                type="text"
                                                defaultValue={
                                                    obj.EPS?.payme?.ID ||
                                                    eps?.payme?.ID ||
                                                    ""
                                                }
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("ID")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="keyPayme"
                                        >
                                            <input
                                                placeholder="Enter KEY"
                                                id="keyPayme"
                                                className="input"
                                                type="text"
                                                defaultValue={
                                                    obj.EPS?.payme?.Key ||
                                                    eps?.payme?.Key ||
                                                    ""
                                                }
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Key")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="component-box-card">
                                <div className="card-header">
                                    <div className="card-info">
                                        <div className="card-icon">
                                            <span className="click" />
                                        </div>
                                        <div className="card-text">
                                            <p className="card-title">
                                                {__("Click")}
                                            </p>
                                            <p className="card-subtitle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="ServiceIDClick"
                                        >
                                            <input
                                                placeholder="Enter Service ID"
                                                id="ServiceIDClick"
                                                className="input"
                                                type="text"
                                                defaultValue={
                                                    obj.EPS?.click
                                                        ?.SERVICE_ID ||
                                                    eps?.click?.SERVICE_ID ||
                                                    ""
                                                }
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Service ID")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="MerchantIDClick"
                                        >
                                            <input
                                                placeholder="Enter Merchant ID"
                                                id="MerchantIDClick"
                                                className="input"
                                                type="text"
                                                defaultValue={
                                                    obj.EPS?.click
                                                        ?.MERCHANT_ID ||
                                                    eps?.click?.MERCHANT_ID ||
                                                    ""
                                                }
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Merchant ID")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="SecretKeyClick"
                                        >
                                            <input
                                                placeholder="Enter Secret KEY"
                                                id="SecretKeyClick"
                                                className="input"
                                                type="text"
                                                defaultValue={
                                                    obj.EPS?.click
                                                        ?.SECRET_KEY ||
                                                    eps?.click?.SECRET_KEY ||
                                                    ""
                                                }
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Secret KEY")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="MerchantUserIdClick"
                                        >
                                            <input
                                                placeholder="Enter Merchant User ID"
                                                id="MerchantUserIdClick"
                                                className="input"
                                                type="text"
                                                defaultValue={
                                                    obj.EPS?.click
                                                        ?.MERCHANT_USER_ID ||
                                                    eps?.click
                                                        ?.MERCHANT_USER_ID ||
                                                    ""
                                                }
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Merchant User ID")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="input-item">
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="UserName"
                                        >
                                            <input
                                                placeholder="Enter User Name"
                                                id="UserName"
                                                className="input"
                                                type="text"
                                                defaultValue={
                                                    obj.EPS?.click?.USERNAME ||
                                                    eps?.click?.USERNAME ||
                                                    ""
                                                }
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("User Name")}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="component-input">
                                        <label
                                            className="input-label"
                                            htmlFor="Password"
                                        >
                                            <input
                                                placeholder="Enter Password"
                                                id="Password"
                                                className="input"
                                                type="text"
                                                defaultValue={
                                                    obj.EPS?.click?.PASSWORD ||
                                                    eps?.click?.PASSWORD ||
                                                    ""
                                                }
                                                disabled
                                            />
                                            <span className="input-text">
                                                {__("Password")}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageCashdeskEPSView;
