import {
    SET_BUSINESS_NAME,
    SET_BUSINESS_TYPE,
    SET_CASHDESK_BRANCH,
    SET_CHECKED,
    SET_EXPIRATION,
    SET_PRICE_RANGE,
    SET_STATUSES,
    SET_UNCHECKED,
    SET_USER_ROLES,
    SET_VERSION,
    RESET_FILTERS
} from "../actionTypes/filtersActionTypes";

export const setCheckedAC = (filterItem, name) => ({type: SET_CHECKED, filterItem, name});
export const setUncheckedAC = (filterItem, name) => ({type: SET_UNCHECKED, filterItem, name});
export const setPriceRangeAC = (from, to) => ({type: SET_PRICE_RANGE, from, to});
export const setExpirationAC = (from, to) => ({type: SET_EXPIRATION, from, to});
export const setStatusesAC = (statuses) => ({type: SET_STATUSES, statuses});
export const setUserRolesAC = (roles) => ({type: SET_USER_ROLES, roles});
export const setBusinessTypeAC = (businessType) => ({type: SET_BUSINESS_TYPE, businessType});
export const setBusinessNameAC = (businessName) => ({type: SET_BUSINESS_NAME, businessName});
export const setCashdeskBranchAC = (branchName) => ({type: SET_CASHDESK_BRANCH, branchName});
export const setVersions = (versionsArray) => ({type: SET_VERSION, payload: versionsArray});
export const resetFilters = () => ({type: RESET_FILTERS});
