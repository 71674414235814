import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import { DataGrid } from "@mui/x-data-grid";
import { Box, colors, Typography } from "@mui/material";
import { ApiService } from "../../../utils/ApiService";
import { errorToast } from "../../../utils/toast";
import { updateTotalAction } from "../../../redux/reducers/paginationControllerReducer";
import ModalWindow from "../../../components/ModalWindow";
import Modal from "react-modal";
import StatusTag from "../../../components/StatusTag";
import Action from "../../../components/Action/index.jsx";
import BreadCrumbs from "../../../components/BreadCrumbs/index.jsx";
import Filter from "../../../components/Filter/index.jsx";
import useApiHook from "../../../hooks/useApiHook";
import Preloader from "../../../components/Preloader";
import config from "../../../config";
import "../../../assets/fonts/epos-icons/style.css";
import "./styles.sass";
import {getTokenData} from "../../../utils/helper";

Modal.setAppElement("#root");

const PageBranchTable = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const filters = {
        isBranchFilter: true,
        navigateState: {business: state?.business},
    };
    let page;
    const { token } = useSelector((state) => state.authReducer);
    const decodedToken = getTokenData(token);
    const params = useParams();
    const businessID = params.id;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [id, setId] = useState();
    const [searchInputValue, setSearchInputValue] = useState("");

    const storedUserData = useSelector((state) => state.authReducer);
    const isClient = storedUserData.role === "client";
    const isDealer = decodedToken?.role?.title === "Dealer";

    let urlBase = `${config.API_BASE_URL}/branches?`;
    if (businessID || state?.business) {
        page = `businesses/${businessID || state?.business}/branches`;
        urlBase += `businessID=${businessID || state?.business?._id}`;
    } else {
        page = "branches";
    }

    let [url, setUrl] = useState(urlBase);
    useEffect(() => {
        setUrl(urlBase);
    }, [businessID]);

    // useEffect(() => {
    //     if(state?.branchFilter?.some(filter => filter.isChecked)) {
    //
    //         let branchFilter = "";
    //         state?.branchFilter?.forEach(filter => {
    //             if(filter.isChecked) {
    //                 branchFilter += `&${filter.name.toLowerCase()}=${searchInputValue}`;
    //             }
    //         });
    //         const isBranchBusiness = state?.business?._id;
    //         setUrl(`${config.API_BASE_URL}/branches/search/branch?${isBranchBusiness ? `businessID=${state?.business?._id}` : ""}${branchFilter}`);
    //     }
    // }, [state?.branchFilter]);

    const { data, loading, error } = useApiHook(url);
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);
    const dispatch = useDispatch();
    const controller = useSelector(
        (state) => state.paginationControllerReducer,
    );
    const branches = data?.data?.docs;

    useEffect(() => {
        if (data) {
            dispatch(updateTotalAction(parseInt(data?.data?.totalDocs) || 0));
        }
    }, [data]);

    useEffect(() => {
        setUrl(
            `${urlBase}&page=${controller.page + 1}&limit=${
                controller.pageSize
            }`,
        );
    }, [controller.page, controller.pageSize]);

    // PREVIOUS IMPLEMENTATION
    useEffect(() => {
        if(searchInputValue) {
            let searchUrl = `${config.API_BASE_URL}/branches/search/branch?query=${searchInputValue}`;

            if(state?.business) {
                searchUrl += `&businessID=${state?.business?._id}`;
            }

            if (isDealer) {
                searchUrl += `&dealerID=${decodedToken?.ID}`;
            }

            setUrl(searchUrl);
        }
        else setUrl(urlBase);
    }, [searchInputValue]);

    if (loading)
        return (
            <div>
                <Preloader />
            </div>
        );
    if (error) return <div>{error}</div>;

    let branchList = [];

    branches?.map((item) => {
        let branchSingle = {
            name: item.name || "—",
            phone: item.phones || "—",
            responsiblePersonFirst:
                item.contacts?.responsiblePerson?.clientID?.displayName
                    ?.first ||
                item.contacts?.responsiblePerson?.clientID?.name?.first ||
                item.contacts?.responsiblePerson?.name?.first ||
                "—",
            responsiblePersonLast:
                item.contacts?.responsiblePerson?.clientID?.displayName?.last ||
                item.contacts?.responsiblePerson?.clientID?.name?.last ||
                item.contacts?.responsiblePerson?.name?.last ||
                "—",
            responsiblePersonAvatar:
                item.contacts?.responsiblePerson?.clientID?.avatar || "",
            managerFirst:
                item.contacts?.manager?.clientID?.displayName?.first ||
                item.contacts?.manager?.clientID?.name?.first ||
                item.contacts?.manager?.name?.first ||
                "—",
            managerLast:
                item.contacts?.manager?.clientID?.displayName?.last ||
                item.contacts?.manager?.clientID?.name?.last ||
                item.contacts?.manager?.name?.last ||
                "—",
            managerAvatar:
                item.contacts?.responsiblePerson?.clientID?.avatar || "",
            businessID: item.businessID?._id || "—",
            businessSlug: item.businessID?.slug || "",
            businessName: item.businessID?.name || "—",
            id: item._id,
            slug: item.slug || "",
            status: item.status,
        };
        branchList.push(branchSingle);
    });

    const openModals = (id) => {
        setModalIsOpen(true);
        setId(id);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };

    const removeUser = () => {
        ApiService.deleteEvent(`/branches/${id}`, token)
            .then((res) => {
                if (res.status === 200) {
                    window.location.reload();
                }
            })
            .catch((error) => {
                errorToast(error);
            });
    };
    const columns = [
        {
            field: "branchName",
            headerName: __("Branch"),
            flex: 1,
            cellClassName: "mane-column--cell",
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        onClick={() => {
                            navigate(`/branches/${ row.id}`, {
                                state: {
                                    branch: {
                                        id: row.id,
                                        slug: row.slug,
                                    },
                                },
                            });
                        }}
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={600}
                            fontSize={14}
                            color={colors.common["black"]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {row.name}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "branchPhone",
            headerName: __("Phone"),
            className: "table-phone",
            flex: 1,
            renderCell: ({ row: { phone } }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Typography
                            fontFamily="Manrope, sans-serif"
                            fontWeight={400}
                            fontSize={14}
                            color={colors.grey[500]}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            whiteSpace={"nowrap"}
                        >
                            {phone.at(-1)}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: "businessName",
            headerName: __("Business"),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        width={"100%"}
                    >
                        <Link
                            to={`/businesses/${
                                row.businessSlug || row.businessID
                            }`}
                            state={{
                                business: {
                                    id: row.businessID,
                                    slug: row.businessSlug,
                                },
                            }}
                            className="link"
                        >
                            <Typography
                                fontFamily="Manrope, sans-serif"
                                fontWeight={400}
                                fontSize={14}
                                color={colors.common["black"]}
                                textOverflow={"ellipsis"}
                                overflow={"hidden"}
                                whiteSpace={"nowrap"}
                            >
                                {row.businessName}
                            </Typography>
                        </Link>
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: __("Status"),
            type: "string",
            flex: 1,
            renderCell: (props) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <StatusTag className={"sm"} text={props.value} />
                    </Box>
                );
            },
        },
        {
            field: "action",
            headerName: __("Action"),
            type: "string",
            flex: 0,
            renderCell: ({ row }) => {
                return (
                    <Box display="flex" justifyContent="center">
                        <Action
                            onClick={() =>
                                navigate(
                                    `/branches/${row.slug || row.id}/edit`,
                                    {
                                        state: {
                                            branch: {
                                                id: row.id,
                                                slug: row.slug,
                                            },
                                        },
                                    },
                                )
                            }
                            onClickView={() =>
                                navigate(`/branches/${row.id}`, {
                                    state: {
                                        branch: {
                                            id: row.id,
                                            slug: row.slug,
                                        },
                                        business: {
                                            id: state?.business?._id,
                                            slug: state?.business?.slug,
                                        },
                                    },
                                })
                            }
                            openModal={() => openModals(row?.id)}
                        />
                    </Box>
                );
            },
        },
    ];

    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[{ label: "Branches", link: "/branches" }]}
            />
            <div
                className={`page page-table-branch ${isClient ? "table" : ""}`}
            >
                <Box>
                    <Filter
                        description={__("Branches")}
                        filters={filters}
                        page={page}
                        search={searchInputValue}
                        setSearch={setSearchInputValue}
                        buttons={
                            businessID || state?.business?._id
                                ? [
                                      {
                                          link: "/branches/create",
                                          text: __("Add Branch"),
                                          state: state?.business
                                              ? {
                                                    business: {
                                                        slug:
                                                            businessID ||
                                                            state.business?._id,
                                                        _id:
                                                            state?.business
                                                                ?._id ||
                                                            businessID,
                                                    },
                                                }
                                              : null,
                                      },
                                  ]
                                : []
                        }
                    />
                    <Box
                        sx={{
                            "&": {
                                margin: "0",
                            },
                            "& .name-column--cell": {
                                color: colors.green[300],
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                            },
                        }}
                    >
                        <DataGrid
                            rows={branchList}
                            rowCount={controller.total}
                            page={controller.page}
                            pageSize={controller.pageSize}
                            columns={columns}
                            {...config.TABLE_CONFIG}
                        />
                    </Box>
                </Box>
                <Modal
                    className="delete-modal"
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                >
                    <div className="modal-bottom">
                        <ModalWindow
                            title={__("Are you sure?")}
                            text={__(
                                "Are you sure you want to permanently delete this branch?",
                            )}
                            onClick={closeModal}
                            onClickDelete={removeUser}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default PageBranchTable;
