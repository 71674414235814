import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { translate } from "../../translations/translate";
import Button from "../../components/global/Button";
import Error403 from "../../assets/images/NotFound.webp";
import "./styles.sass";

const PageError403 = ({ ...props }) => {
    const lang = useSelector(state => state.languageReducer.lang);
    const __ = key => translate(lang, key);

    return (
        <div {...props} className="page page-error-403">
            <div className="page-error-403-main-content">
                <div className="error-403-left-side">
                    <h1 className="component-heading">403</h1>
                    <h2 className="component-error-heading">{__("You can not create a branch!")}</h2>
                    <div className="component-card-text">
                        <p className="component-text">
                            {__("You do not have permissions to create a branch!")}
                        </p>
                    </div>
                    <Link to="/">
                        <Button text={__("Back to home")} className="btn" />
                    </Link>
                </div>
                <div className="error-403-right-side">
                    <img
                        className="error-403-right-side-image"
                        src={Error403}
                        alt="error 403"
                    />
                </div>
            </div>
        </div>
    );
};

export default PageError403;
