import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const useApiHook = (url) => {
    if(!url)
        return { data: {}, loading: {}, error: ""};
    const [data, setdata] = useState(null);
    const [loading, setloading] = useState(true);
    const [error, seterror] = useState("");
    const { token } = useSelector((state) => state.authReducer);

    useEffect(() => {
        axios({
            url,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        }).then((data) => {
            seterror(data.error);
            setdata(data.data);
            setloading(false);
        }).catch(() => setloading(false));
    }, [url]);
    return { data, loading, error };
};
export default useApiHook;
