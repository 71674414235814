import {ApiService} from "../ApiService";

export const searchClients = async (query, token) => {
    try{
        const response = await ApiService.getResources(`/clients/search/clients?value=${query}`, token);
        if (!response.data || !Array.isArray(response.data)) {
            return [];
        } else {
            return response.data;
        }
    }
    catch (e){
        return [];
    }
};

export const searchClientsQuery = async (query, token) => {
    try{
        const response = await ApiService.getResources(`/clients/search/client?query=${query}`, token);
        if (!response.data || !Array.isArray(response.data.docs)) {
            return [];
        } else {
            return response.data.docs;
        }
    }
    catch (e){
        return [];
    }
};