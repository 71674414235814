import React, {useState, useEffect} from "react";
import {IMaskInput} from "react-imask";
import {useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router";
import {ApiService} from "../../../utils/ApiService";
import {errorToast, successToast} from "../../../utils/toast";
import {translate} from "../../../translations/translate";
import Button from "../../../components/global/Button";
import PageHeader from "../../../components/PageHeader";
import useApiHook from "../../../hooks/useApiHook";
import config from "../../../config";
import Preloader from "../../../components/Preloader";
import BreadCrumbs from "../../../components/BreadCrumbs";
import SelectComponent from "../../../components/Select";
import "./styles.sass";
import {searchStaff} from "../../../utils/globalAPIRequests/searchStaff";
import UserLinkCard from "../../../components/UserLinkCard";
import {Box} from "@mui/material";
import {stringWithoutSpaces} from "../../../utils/removeStrSpaces";
import UIAvatar from "../../../components/Avatar";
import DragAndDrop from "../../DragAndDrop";
import cleanseObject from "../../../utils/cleanseObject";
import {searchDealer} from "../../../utils/globalAPIRequests/searchDealer";
import {useDebounce} from "../../../hooks/useDebounce";
import {getTokenData} from "../../../utils/helper";
import {url as genURL} from "../../../utils/generalizeURL";
import TextArea from "../../../components/TextArea/TextArea";

const PageBusinessEdit = ({...props}) => {

    const storedUserData = useSelector((state) => state.authReducer);

    const navigate = useNavigate();

    const PhoneMask = "+998 (00) 000-00-00";
    const PinflMask = "00000000000000";
    const TinMask = "000000000";
    const tinMask = [
        {
            mask: TinMask,
        },
        {
            mask: PinflMask
        }
    ];
    const phoneEmailMask = [
        {
            mask: PhoneMask,
        },
    ];
    const lang = useSelector((state) => state.languageReducer.lang);
    const __ = (key) => translate(lang, key);

    const {
        data: businessTypeData,
    } = useApiHook(`${config.API_BASE_URL}/businesses/types/list`);
    const businessTypes = businessTypeData?.data;

    const businessTypesList = businessTypes ? businessTypes.map(item => {
        return {
            value: item.code.en,
            label: `${item.title[lang]} (${item.code[lang]})`
        };
    }) : [];

    const [businesses, setBusinesses] = useState(false);
    const [dealerSearch, setDealerSearch] = useState("");
    const [dealerSearchResult, setDealerSearchResult] = useState([]);
    const [dealer, setDealer] = useState(null);
    const dealerDebouncedSearch = useDebounce(dealerSearch);
    const [currentDocType, setCurrentDocType] = useState("");
    const [currentCustomDescription, setCurrentCustomDescription] = useState("");
    // FOR BUSINESS DOCUMENTS
    const [isDragNDropOpen, setIsDragNDropOpen] = useState(false);
    const handleDragNDropOpen = () => {
        setIsDragNDropOpen(!isDragNDropOpen);
    };

    const [documents, setDocuments] = useState([]);
    const handleSetDocument = (obj) => {
        let documentsTemp = documents;
        if (currentDocType === "custom") {
            const index = documentsTemp.length;
            const docObject = {
                index: index,
                documentType: {
                    key: currentDocType,
                    description: currentCustomDescription
                },
                id: obj.id,
                name: obj.name
            };
            if (Array.isArray(documentsTemp)) {
                documentsTemp.push(docObject);
            } else {
                documentsTemp = [];
                documentsTemp.push(docObject);
            }
            setCurrentCustomDescription("");
        } else {
            documentsTemp.forEach((doc, i, arr) => {
                if (doc.documentType.key === currentDocType) {
                    arr[i]["_id"] = obj.id;
                    arr[i]["name"] = obj.name;
                    arr[i]["url"] = obj.url;
                }
            });
        }
        setDocuments([...documentsTemp]);
        setCurrentDocType(null);
        setBusinessCurrent({
            ...businessCurrent,
            documents: documents,
        });
    };

    const compareObjectsByIdAndFields = (array1, array2) => {
        if (array1?.length !== array2?.length) {
            return false;
        }

        // comparing every object url field
        for (let i = 0; i < array1.length; i++) {
            const obj1 = array1[i];
            const obj2 = array2[i];

            if (obj1.url !== obj2.url) {
                return false;
            }
        }

        return true;
    };

    const handleCustomDocumentDescription = (index, description) => {
        const documentsTemp = documents;
        documentsTemp[index].documentType.description = description;
        setDocuments([...documentsTemp]);
        // setBusinessCurrent({
        //     ...businessCurrent,
        //     documents: documents,
        // });
    };
    const deleteDoc = (index) => {
        let documentsTemp = documents;
        if (documentsTemp[index].documentType.key === "custom") {
            if (index > -1) {
                documentsTemp.splice(index, 1);
            }
        } else {
            delete documentsTemp[index].url;
            delete documentsTemp[index]._id;
            delete documentsTemp[index].name;
        }
        setDocuments(documentsTemp);
        setBusinessCurrent({
            ...businessCurrent,
            documents: documents,
        });
    };

    const handleBusinesses = () => {
        setBusinesses(!businesses);
    };

    const handleDealerSearch = (e) => {
        setDealerSearch(e.target.value);
    };

    const handleClickDealer = (dealer) => {
        setDealer(dealer);
        setDealerSearch("");
        setDealerSearchResult([]);
    };

    document.body.addEventListener("click", (e) => {
        if (e.target.className !== "component-switcher-handler" && businesses)
            handleBusinesses();
    });

    const {state} = useLocation();
    const {id} = useParams();
    const url = `${config.API_BASE_URL}/businesses/${state?.business?.id || id
    }`;
    const {data, loading, error} = useApiHook(url);
    const [equal, setEqual] = useState(true);
    const [businessInitial, setBusinessInitial] = useState({});
    const [businessCurrent, setBusinessCurrent] = useState({});

    // SMART SEARCH
    const [respPersonSearch] = useState("");
    const [_, setRespPersonSearchResults] = useState([]);
    const [logoUrl, setLogoUrl] = useState("");


    // FOR BUSINESS LOGO
    const [isLogoDragNDropOpen, setIsLogoDragNDropOpen] = useState(false);
    const handleLogoDragNDropOpen = () => {
        setIsLogoDragNDropOpen(!isLogoDragNDropOpen);
    };
    const handleSetLogo = (url) => {
        setLogoUrl(url.url);
        setBusinessCurrent({
            ...businessCurrent,
            avatar: url.id,
        });
    };

    const removeDealerHandler = () => {
        ApiService.deleteEvent(`/users/${dealer?._id}/business/${state?.business?.id || id}`, token)
            .then((res) => {
                if (res.status === 200) {
                    successToast(__("Successfully updated"));
                    navigate(`/businesses/${state?.business?.id || id}`);
                }
            })
            .catch((error) => {
                errorToast(error);
            });
    };

    const {token} = useSelector((state) => state.authReducer);
    const decodedToken = getTokenData(token);
    // IS THE USER DEALER?
    const isDealer = decodedToken?.role?.title === "Dealer";

    useEffect(() => {
        if (data?.data?.docs) {
            data?.data?.docs?.map((item) => {
                if (!item.documents.length) {
                    setDocuments([{
                        index: 0,
                        documentType: {
                            key: "passportScan",
                            description: __("Owner's Passport Scan")
                        }
                    },
                    {
                        index: 1,
                        documentType: {
                            key: "companyCertificate",
                            description: __("Your Company Certificate")
                        }
                    },
                    {
                        index: 2,
                        documentType: {
                            key: "rentContract",
                            description: __("Rent Contract (will be used for the first Branch as well)")
                        }
                    },]);
                } else {
                    setDocuments(item.documents.map((document, index) => ({
                        index: index,
                        documentType: document.documentType,
                        _id: document.id,
                        name: document?.id?.fileInfo?.originalName,
                        url: document?.id?.fileInfo?.url,
                    })));
                }
                setLogoUrl(item?.avatar?.fileInfo?.url
                    ? item?.avatar?.fileInfo?.url
                    : "");
                setBusinessInitial({
                    tin: item.tin,
                    name: item.name,
                    avatar: item?.avatar?.id || null,
                    businessType: item.businessType,
                    phone: item.phone,
                    owner: item.owner?.name?.first,
                    ownerID: item.owner?._id,
                    legalState: item.address?.legal?.state,
                    legalRegion: item.address?.legal?.region,
                    legalCity: item.address?.legal?.city,
                    legalDistrict: item.address?.legal?.district,
                    legalStreet: item.address?.legal?.street,
                    legalHouse: item.address?.legal?.house,
                    legalZIPCode: item.address?.legal?.ZIPCode,
                    documents: item.documents.map((document, index) => ({
                        index: index,
                        documentType: document.documentType,
                        _id: document._id,
                        name: document?.id?.fileInfo?.originalName,
                        url: document?.id?.fileInfo?.url
                    })),
                    actualState: item.address?.actual?.state,
                    actualRegion: item.address?.actual?.region,
                    actualCity: item.address?.actual?.city,
                    actualDistrict: item.address?.actual?.district,
                    actualStreet: item.address?.actual?.street,
                    actualHouse: item.address?.actual?.house,
                    actualZIPCode: item.address?.actual?.ZIPCode,
                    responsiblePerson: {
                        name: {
                            first: item?.responsiblePerson?.name?.first,
                            last: item?.responsiblePerson?.name?.last,
                            middle: item?.responsiblePerson?.name?.middle,
                        },
                        phone: item?.responsiblePerson?.phone,
                        clientID: item?.responsiblePerson?.clientID?._id,
                    },
                    dealerId: item?.dealerID
                });
                setBusinessCurrent({
                    tin: item.tin,
                    name: item.name,
                    avatar: item?.avatar?.id || null,
                    businessType: item.businessType,
                    phone: item.phone,
                    owner: item.owner?.name?.first,
                    legalState: item.address?.legal?.state,
                    legalRegion: item.address?.legal?.region,
                    legalCity: item.address?.legal?.city,
                    legalDistrict: item.address?.legal?.district,
                    legalStreet: item.address?.legal?.street,
                    legalHouse: item.address?.legal?.house,
                    legalZIPCode: item.address?.legal?.ZIPCode,
                    documents: item.documents.map((document, index) => ({
                        index: index,
                        documentType: document.documentType,
                        _id: document._id,
                        name: document?.id?.fileInfo?.originalName,
                    })),
                    actualState: item.address?.actual?.state,
                    actualRegion: item.address?.actual?.region,
                    actualCity: item.address?.actual?.city,
                    actualDistrict: item.address?.actual?.district,
                    actualStreet: item.address?.actual?.street,
                    actualHouse: item.address?.actual?.house,
                    actualZIPCode: item.address?.actual?.ZIPCode,
                    responsiblePerson: {
                        name: {
                            first: item?.responsiblePerson?.name?.first,
                            last: item?.responsiblePerson?.name?.last,
                            middle: item?.responsiblePerson?.name?.middle,
                        },
                        phone: item?.responsiblePerson?.phone,
                        clientID: item?.responsiblePerson?.clientID?._id,
                    },
                });
                setDealer(item?.dealerID);
            });
        }
    }, [data]);

    useEffect(() => {
        if (
            businessInitial.tin === businessCurrent.tin &&
            businessInitial?.avatar === businessCurrent?.avatar &&
            businessInitial.pinfl === businessCurrent.pinfl &&
            businessInitial.name === businessCurrent.name?.trim() &&
            businessInitial.businessType === businessCurrent.businessType &&
            businessInitial.phone === businessCurrent.phone &&
            businessInitial.owner === businessCurrent.owner &&
            businessInitial.legalState === businessCurrent.legalState &&
            businessInitial.legalRegion === businessCurrent.legalRegion &&
            businessInitial.legalCity === businessCurrent.legalCity &&
            businessInitial.legalDistrict === businessCurrent.legalDistrict &&
            businessInitial.legalStreet === businessCurrent.legalStreet &&
            businessInitial.legalHouse === businessCurrent.legalHouse &&
            businessInitial.legalZIPCode === businessCurrent.legalZIPCode &&
            businessInitial.actualState === businessCurrent.actualState &&
            businessInitial.actualRegion === businessCurrent.actualRegion &&
            businessInitial.actualCity === businessCurrent.actualCity &&
            businessInitial.actualDistrict === businessCurrent.actualDistrict &&
            businessInitial.actualStreet === businessCurrent.actualStreet &&
            businessInitial.actualHouse === businessCurrent.actualHouse &&
            businessInitial.actualZIPCode === businessCurrent.actualZIPCode &&
            // businessInitial.responsiblePersonFirst ===
            // businessCurrent.responsiblePersonFirst &&
            // businessInitial.responsiblePersonLast ===
            // businessCurrent.responsiblePersonLast &&
            // businessInitial.responsiblePersonMiddle ===
            // businessCurrent.responsiblePersonMiddle &&
            // businessInitial.responsiblePersonPhone ===
            // businessCurrent.responsiblePersonPhone &&

            businessInitial.responsiblePerson?.name?.first === businessCurrent.responsiblePerson?.name?.first &&
            businessInitial.responsiblePerson?.name?.last === businessCurrent.responsiblePerson?.name?.last &&
            businessInitial.responsiblePerson?.name?.middle === businessCurrent.responsiblePerson?.name?.middle &&
            businessInitial.responsiblePerson?.phone === businessCurrent.responsiblePerson?.phone &&
            businessInitial.responsiblePerson?.clientID === businessCurrent.responsiblePerson?.clientID &&
            businessInitial.dealerId?._id === dealer?._id &&
            compareObjectsByIdAndFields(documents, businessInitial.documents)
        ) {
            setEqual(true);
        } else setEqual(false);
    }, [businessCurrent, dealer, documents]);

    useEffect(() => {
        if (dealerDebouncedSearch.length > 0) {
            (async () => {
                const result = await searchDealer(dealerDebouncedSearch, token);
                setDealerSearchResult(result);
            })();
        } else {
            setDealerSearchResult([]);
        }
    }, [dealerDebouncedSearch]);

    // QUERY TO RESPONSIBLE PERSON
    useEffect(() => {
        if (respPersonSearch.length > 0) {
            (async () => {
                return await searchStaff(state?.business?.id || id, respPersonSearch, token);
            })().then((result) => {
                setRespPersonSearchResults(result);
            });
        } else {
            setRespPersonSearchResults(null);
        }
    }, [respPersonSearch]);

    const onCancelClick = function () {
        navigate("/businesses");
    };

    const handleBusinessNameChange = (e) => {
        let value = e.target.value;

        // Convert the value to uppercase
        value = value.toUpperCase();

        // Remove unwanted symbols (single quote and double quote)
        value = value.replace(/['"]/g, "");

        // Update the state with the transformed value
        setBusinessCurrent({
            ...businessCurrent,
            name: value,
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const body = {
            role: businessCurrent?.role,
            address: {
                legal: {
                    ZIPCode: businessCurrent?.legalZIPCode,
                    city: businessCurrent?.legalCity,
                    district: businessCurrent?.legalDistrict,
                    house: businessCurrent?.legalHouse,
                    region: businessCurrent?.legalRegion,
                    state: businessCurrent?.legalState,
                    street: businessCurrent?.legalStreet,
                },
                actual: {
                    ZIPCode: businessCurrent?.actualZIPCode,
                    city: businessCurrent?.actualCity,
                    district: businessCurrent?.actualDistrict,
                    house: businessCurrent?.actualHouse,
                    region: businessCurrent?.actualRegion,
                    state: businessCurrent?.actualState,
                    street: businessCurrent?.actualStreet,
                },
            },
            businessType: businessCurrent?.businessType,
            name: businessCurrent?.name?.trim(),
            avatar: businessCurrent?.avatar,
            phone: businessCurrent?.phone,
            responsiblePerson: businessCurrent?.responsiblePerson,
            tin: businessCurrent?.tin,
            dealerID: dealer
        };

        body.documents = documents.map((document) => {
            if (document.url) {
                return {
                    documentType: document.documentType,
                    _id: document._id,
                };
            } else {
                return {
                    documentType: document.documentType,
                    _id: null,
                };
            }
        });

        ApiService.patchEvent(`/businesses/${state?.id || id}`, token, cleanseObject(body))
            .then((value) => {
                if (value.status === 200) {
                    successToast(__("Successfully updated"));
                    navigate(`/businesses/${state?.id || id}`);
                }
            })

            .catch((error) => {
                errorToast(error.message);
            });
    };

    if (loading)
        return (
            <div>
                <Preloader/>
            </div>
        );
    if (error) return <div>{error}</div>;
    const buttons = [
        {
            name: "Back",
            link: "/businesses",
            state: {},
            className: "outline",
        },
    ];
    return (
        <div>
            <BreadCrumbs
                breadcrumbs={[
                    {label: "Businesses", link: "/businesses"},
                    {label: "Edit"},
                ]}
            />
            <div {...props} className="page component-page-business-edit">
                <form onSubmit={onSubmit} className="page-inner">
                    <PageHeader name={__("Edit Business")} buttons={buttons}/>
                    <div className="cards">
                        <div className="left-side-cards">
                            {data?.data?.docs?.map((item, i) => (
                                <div key={i}>
                                    {/* Business Information */}
                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-information"/>
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__(
                                                            "Business Information",
                                                        )}
                                                    </p>
                                                    <p className="card-subtitle"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-column">
                                            <UIAvatar onClick={handleLogoDragNDropOpen} url={logoUrl || ""}
                                            />
                                            <div className="input-row">
                                                <div className="input-item">
                                                    {/* Business Name */}
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                            htmlFor="name"
                                                        >
                                                            <input
                                                                value={businessCurrent.name}
                                                                onChange={handleBusinessNameChange}
                                                                placeholder={__(
                                                                    "Enter your company name",
                                                                )}
                                                                id="name"
                                                                className="input"
                                                                type="text"
                                                            />
                                                            <span className="input-text">
                                                                {__("Business Name")}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    {/* TIN */}
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                            htmlFor="Tin"
                                                        >
                                                            <IMaskInput
                                                                mask={tinMask}
                                                                defaultValue={item.tin}
                                                                onChange={(e) =>
                                                                    setBusinessCurrent({
                                                                        ...businessCurrent,
                                                                        tin: stringWithoutSpaces(e.target
                                                                            .value),
                                                                    })
                                                                }
                                                                id="Tin"
                                                                className="input"
                                                                type="text"
                                                                minLength={9}
                                                                maxLength={17}
                                                                disabled
                                                            />
                                                            <span className="input-text">
                                                                {__("TIN / PINFL")}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* 2 */}
                                                <div className="input-item">
                                                    {/* Business Type */}
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                            htmlFor="position"
                                                        >
                                                            <span className="input-text">
                                                                {__("Business Type")}
                                                            </span>
                                                            <SelectComponent
                                                                value={businessTypesList.find(businessType => businessType.value === businessCurrent?.businessType)}
                                                                onChange={(e) =>
                                                                    setBusinessCurrent({
                                                                        ...businessCurrent,
                                                                        businessType:
                                                                        e.value,
                                                                    })
                                                                }
                                                                options={businessTypesList}
                                                            />
                                                        </label>
                                                    </div>
                                                    {/* Owner */}
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                            htmlFor="Owner"
                                                        >
                                                            <input
                                                                defaultValue={item.owner?.name?.first + " " + item.owner?.name?.last}
                                                                id="Owner"
                                                                className="input"
                                                                type="text"
                                                                disabled
                                                            />
                                                            <span className="input-text">
                                                                {__("Owner")}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* 3 */}
                                                <div className="input-item">
                                                    {/* Phone */}
                                                    <div className="component-input">
                                                        <label
                                                            className="input-label"
                                                            htmlFor="phone"
                                                        >
                                                            <IMaskInput
                                                                autoComplete="off"
                                                                id="phone"
                                                                htmlFor="phone"
                                                                type="tel"
                                                                className="mask-on-input card-input input"
                                                                placeholder="+(998) 99 222 33 44"
                                                                mask={phoneEmailMask}
                                                                value={
                                                                    businessCurrent?.phone
                                                                }
                                                                onAccept={(value) => {
                                                                    setBusinessCurrent({
                                                                        ...businessCurrent,
                                                                        phone: value
                                                                    });
                                                                }}
                                                                required
                                                            />
                                                            <span className="input-text">
                                                                {__("Phone")}
                                                            </span>
                                                        </label>
                                                    </div>
                                                    {/* Dealer */}
                                                    {!isDealer && (
                                                        <div className="component-input smart-search-container">
                                                            <label
                                                                className="input-label"
                                                                htmlFor="Dealer"
                                                            >
                                                                <input
                                                                    onChange={handleDealerSearch}
                                                                    value={
                                                                        dealerSearch
                                                                    }
                                                                    placeholder={__(
                                                                        "Start typing...",
                                                                    )}
                                                                    id="Dealer"
                                                                    className="input"
                                                                    type="text"
                                                                />
                                                                <span className="input-text">
                                                            {__("Dealer Search")}
                                                        </span>
                                                            </label>
                                                            {dealerSearchResult?.length > 0 && (
                                                                <div className="smart-search-list">
                                                                    {dealerSearchResult?.map((item, i) => (
                                                                            <Box
                                                                                display="flex"
                                                                                justifyContent="center"
                                                                                key={i}
                                                                            >
                                                                                <UserLinkCard
                                                                                    avatar={
                                                                                        dealer?.avatar || ""
                                                                                    }
                                                                                    name={`${item.name.first} ${item.name.last}`}
                                                                                    classNames={"with-padding bordered hoverable"}
                                                                                    onClick={() => handleClickDealer(item)}
                                                                                />
                                                                            </Box>
                                                                        )
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                {dealer && (
                                                    <div className="component-input no-input">
                                                        <div className="label-container">
                                                            <label className="input-label">
                                                        <span className="input-text">
                                                            {__("Dealer")}
                                                        </span>
                                                                <UserLinkCard
                                                                    avatar={dealer?.avatar || ""}
                                                                    name={`${dealer?.name?.first} ${dealer?.name?.last}`}
                                                                    classNames={
                                                                        "with-padding input input-view"
                                                                    }
                                                                />
                                                            </label>
                                                            {!isDealer && (
                                                                <button
                                                                    type="button"
                                                                    className="input-label__delete-btn"
                                                                    onClick={removeDealerHandler}
                                                                >
                                                                    <i className="icon-delete"></i>
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/* 1 */}
                                    </div>
                                    {/* Business Address */}
                                    {
                                        documents.length ? (
                                                <div className="component-box-card">
                                                    <div className="card-header">
                                                        <div className="card-info">
                                                            <div className="card-icon">
                                                                <i className="icon-tie"></i>
                                                            </div>
                                                            <div className="card-text">
                                                                <p className="card-title">
                                                                    {__("Documents")}
                                                                </p>
                                                                <p className="card-subtitle">{__("Upload the required documents")}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="files-block">
                                                        {Array.isArray(documents) && documents.map((doc, index) => (
                                                            <div className="file-row" key={doc.index}>
                                                                <div className="file-card disabled">
                                                                    {"" &&
                                                                        <img src="" alt="." className="icon"/>
                                                                    }
                                                                    <p className="text">{doc?.name || ""}</p>
                                                                    <p className="label">{__(doc?.documentType?.description)}</p>
                                                                    {genURL(doc?.url) && doc?.id &&
                                                                        <div className="action">
                                                                            <a className="icon"
                                                                               href={genURL(doc.url)}
                                                                               target="_blank"
                                                                               rel="noreferrer"
                                                                            >
                                                                                <i className="icon-view"/>
                                                                            </a>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div
                                                                    className={`file-card ${(doc?.documentType?.key === "custom") ? "" : "disabled"}`}>
                                                                    <input
                                                                        value={__(doc?.documentType?.description) || ""}
                                                                        disabled={!(doc?.documentType?.key === "custom")}
                                                                        onChange={(e) => {
                                                                            handleCustomDocumentDescription(index, e.target.value);
                                                                        }}
                                                                        type="text"
                                                                        className="text"
                                                                    />
                                                                </div>
                                                                <div className="component-card-button">
                                                                    {doc.url && doc._id
                                                                        ? (
                                                                            <button
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    deleteDoc(index);
                                                                                }}
                                                                                className="btn squared error"
                                                                            >
                                                                                <i className="icon-delete"></i>
                                                                            </button>
                                                                        )
                                                                        : (
                                                                            <button
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    setCurrentDocType(doc?.documentType?.key);
                                                                                    handleDragNDropOpen();
                                                                                }}
                                                                                className="btn squared"
                                                                            >
                                                                                <i className="icon-upload"></i>
                                                                            </button>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))
                                                        }
                                                    </div>
                                                </div>
                                            )
                                            : null
                                    }
                                    <div className="component-box-card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <div className="card-icon">
                                                    <i className="icon-business-building-location"/>
                                                </div>
                                                <div className="card-text">
                                                    <p className="card-title">
                                                        {__("Legal Address")}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 24 }}>
                                            <TextArea
                                                label="Address"
                                                defaultValue={item.address?.legal?.region}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="component box-save">
                        <div className="not-save-item">
                            <div className="not-save-icon">
                                <i className="icon-saved"></i>
                            </div>
                            <div className="not-save-text">
                                <p className="save-text">
                                    {__("Not Saved Yet")}
                                </p>
                            </div>
                        </div>
                        <div className="not-save-button">
                            <Button
                              className="btn outline sm"
                              text={__("Cancel")}
                              onClick={onCancelClick}
                              type="button"
                            />
                            <Button
                              disabled={equal}
                              className="btn sm"
                              text={__("Save")}
                              type="submit"
                            />
                            {
                              isLogoDragNDropOpen && (
                                <DragAndDrop
                                  setter={handleSetLogo}
                                  closeDragNDrop={handleLogoDragNDropOpen}
                                  uploadType={"document"}
                                  docModel={businessInitial?.owner ? "Client" : "User"}
                                  ownerID={businessInitial?.ownerID || storedUserData._id}
                                  isUpload={"true"}
                                  isImage={true}
                                />
                              )
                            }

                            {isDragNDropOpen && (
                              <DragAndDrop
                                setter={handleSetDocument}
                                closeDragNDrop={handleDragNDropOpen}
                                uploadType={"document"}
                                docModel={businessInitial?.owner ? "Client" : "User"}
                                ownerID={businessInitial?.ownerID || storedUserData.id}
                                isUpload={"true"}
                              />
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PageBusinessEdit;
