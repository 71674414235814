export const mockCartViewDataTeam = [
    {
        id: 1,
        productName: "Tobacco shop",
        productPrice: "0",
        category: "Online shop",
        vat: "0",
        status: "Active",
    },
    {
        id: 2,
        productName: "Tim's Coffe and Roastbery",
        productPrice: "0",
        category: "Online shop",
        vat: "0",
        status: "Active",
    },
    {
        id: 3,
        productName: "Epos",
        productPrice: "0",
        category: "Online shop",
        vat: "0",
        status: "Active",
    },
    {
        id: 4,
        productName: "Epos",
        productPrice: "0",
        category: "Online shop",
        vat: "0",
        status: "Active",
    },
    {
        id: 5,
        productName: "Epos",
        productPrice: "0",
        category: "Online shop",
        vat: "0",
        status: "Active",
    },
    {
        id: 6,
        productName: "Epos",
        productPrice: "0",
        category: "Online shop",
        vat: "0",
        status: "Active",
    }
];