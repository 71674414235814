import React from "react";
import { useSelector } from "react-redux";
import { translate } from "../../../translations/translate";
import PageHeader from "../../../components/PageHeader";
import avatar from "../../../assets/images/avatar.png";
import { IMaskInput } from "react-imask";
import "../../../assets/fonts/epos-icons/style.css";
import "./styles.sass";

const PageReportView = ({ ...props }) => {
    const lang = useSelector(state => state.languageReducer.lang);
    const __ = key => translate(lang, key);

    const number = "00.000,00";

    const numberMask = [
        {
            mask: number,
        },
    ];
    return (
        <div {...props} className="page component-page-report-view">
            <form className="page-inner">
                <PageHeader name={__("View")} text={__("Back")} link="/report/table" />
                <div className="cards">
                    <div className="left-side-cards">
                        <div className="component-box-card">
                            <div className="card-info">
                                <div className="card-icon">
                                    <i className="icon-document-file" />
                                </div>
                                <div className="card-text">
                                    <p className="card-title">
                                        {__("Report Information")}
                                    </p>
                                    <p className="card-subtitle" />
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="Product"
                                    >
                                        <input
                                            defaultValue="0"
                                            id="Product"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Report Number")}
                                        </span>
                                    </label>
                                </div>
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="closedAt"
                                    >
                                        <input
                                            defaultValue="23/02/2025"
                                            id="closedAt"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Closed At")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <div className="component-input-user">
                                        <div className="component-switcher">
                                            <div className="component-switcher-handler" />
                                            <span className="label-name">
                                                {__("Owner")}
                                            </span>
                                            <div className="component-items-bar">
                                                <img
                                                    className="user-icon"
                                                    src={avatar}
                                                    alt="avatar"
                                                    width="21px"
                                                />
                                                <p className="component-item-name">
                                                    Alex Florense
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="name"
                                    >
                                        <input
                                            defaultValue="12345678910"
                                            id="name"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Cashdesk")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="firstChequeNumber"
                                    >
                                        <input
                                            defaultValue="23"
                                            id="firstChequeNumber"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("First Cheque Number")}
                                        </span>
                                    </label>
                                </div>
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="lastChequeNumber"
                                    >
                                        <input
                                            defaultValue="025"
                                            id="lastChequeNumber"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Last Cheque Number")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="chequesQuantity"
                                    >
                                        <input
                                            defaultValue="223"
                                            id="chequesQuantity"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Cheques Quantity")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="component-box-card">
                            <div className="card-info">
                                <div className="card-icon">
                                    <i className="icon-document-file" />
                                </div>
                                <div className="card-text">
                                    <p className="card-title">
                                        {__("Total Information")}
                                    </p>
                                    <p className="card-subtitle">{__("Sale")}</p>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="VAT"
                                    >
                                        <input
                                            defaultValue="24877"
                                            id="VAT"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">{__("VAT")}</span>
                                    </label>
                                </div>
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="cash"
                                    >
                                        <IMaskInput
                                            defaultValue="0"
                                            className="mask-on-input card-input input"
                                            id="cash"
                                            type="tel"
                                            mask={numberMask}
                                            disabled
                                        />
                                        <span className="input-text">{__("Cash")}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="card"
                                    >
                                        <IMaskInput
                                            defaultValue="0"
                                            className="mask-on-input card-input input"
                                            id="card"
                                            type="tel"
                                            mask={numberMask}
                                            disabled
                                        />
                                        <span className="input-text">{__("Card")}</span>
                                    </label>
                                </div>
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="E-cash"
                                    >
                                        <IMaskInput
                                            defaultValue="0"
                                            className="mask-on-input card-input input"
                                            id="E-cash"
                                            type="tel"
                                            mask={numberMask}
                                            disabled
                                        />
                                        <span className="input-text">
                                        {__("E-Cash")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="total"
                                    >
                                        <IMaskInput
                                            defaultValue="0"
                                            className="mask-on-input card-input input"
                                            id="total"
                                            type="tel"
                                            mask={numberMask}
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Total")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="card-info report-text">
                                <div className="card-icon">
                                    <i className="icon-document-file"></i>
                                </div>
                                <div className="card-text">
                                    <p className="card-title">
                                    {__("Total Information")}
                                    </p>
                                    <p className="card-subtitle">{__("Refund")}</p>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="VATRefund"
                                    >
                                        <input
                                            defaultValue="24877"
                                            id="VATRefund"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">{__("VAT")}</span>
                                    </label>
                                </div>
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="cashRefund"
                                    >
                                        <IMaskInput
                                            defaultValue="0"
                                            className="mask-on-input card-input input"
                                            id="cashRefund"
                                            type="tel"
                                            mask={numberMask}
                                            disabled
                                        />
                                        <span className="input-text">{__("Cash")}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="cardRefund"
                                    >
                                        <IMaskInput
                                            defaultValue="0"
                                            className="mask-on-input card-input input"
                                            id="cardRefund"
                                            type="tel"
                                            mask={numberMask}
                                            disabled
                                        />
                                        <span className="input-text">{__("Card")}</span>
                                    </label>
                                </div>
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="E-cashRefund"
                                    >
                                        <IMaskInput
                                            defaultValue="0"
                                            className="mask-on-input card-input input"
                                            id="E-cashRefund"
                                            type="tel"
                                            mask={numberMask}
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("E-Cash")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="totalRefund"
                                    >
                                        <IMaskInput
                                            defaultValue="0"
                                            className="mask-on-input card-input input"
                                            id="totalRefund"
                                            type="tel"
                                            mask={numberMask}
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Total")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="component-box-card">
                            <div className="card-info">
                                <div className="card-icon">
                                    <i className="icon-document-file" />
                                </div>
                                <div className="card-text">
                                    <p className="card-title">
                                        {__("Fiscal Information")}
                                    </p>
                                    <p className="card-subtitle" />
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="fiscalModuleNumber"
                                    >
                                        <input
                                            defaultValue="123456789012"
                                            id="fiscalModuleNumber"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Fiscal Module Number")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="createdAt"
                                    >
                                        <input
                                            defaultValue="21/12/2003"
                                            id="createdAt"
                                            className="input input-disabled"
                                            type="Card"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Created At")}
                                        </span>
                                    </label>
                                </div>
                                <div className="component-input">
                                    <label
                                        className="input-label"
                                        htmlFor="updatedAt"
                                    >
                                        <input
                                            defaultValue="21/08/2021"
                                            id="updatedAt"
                                            className="input input-disabled"
                                            type="text"
                                            disabled
                                        />
                                        <span className="input-text">
                                            {__("Updated At")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PageReportView;
