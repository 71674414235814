import React, { useState, useEffect } from "react";
import { translate } from "../../translations/translate";
import { url } from "../../utils/generalizeURL";
import "./styles.sass";
import { useSelector } from "react-redux";
import config from "../../config";

const UIAvatar = ({...props}) => {
    const [avatar, setAvatar] = useState();
    const lang = useSelector(state => state.languageReducer.lang);
    const __ = key => translate(lang, key);

    useEffect(() => {
        return () => {
            avatar && URL.revokeObjectURL(avatar.preview);
        };
    }, [avatar]);

    const handlePreviewAvatar = (e) => {
        const file = e.target.files[0];

        file.preview = URL.createObjectURL(file);

        setAvatar(file);
    };

    return (
        <div className="avatar-img" onClick={(e) => {
            e.preventDefault();
            props.onClick();
        }} >
            <div className="avatar-preview">
                <label className="avatar-upload" htmlFor="avatar"/>
                <input
                    className="avatar-type-file"
                    id="avatar"
                    type="file"
                    onChange={handlePreviewAvatar}
                />
                <span className="input-text">{__("Your Picture")}</span>
            </div>
            {props.url &&
                (
                    <div className="img-container">
                        <img className="img" src={`${config.API_HOST}/${props.url}`} alt="" />
                    </div>
                )
            }
        </div>
    );
};
export default UIAvatar;
