import React from "react";
import { useSelector } from "react-redux";
import { translate } from "../../translations/translate";
import CheckBox from "../../components/CheckBox";
import Button from "../../components/global/Button";
import Input from "../../components/LayoutBasic/Input";
import PageHeader from "../../components/PageHeader";
import "./styles.sass";



const Role = () => {
    const lang = useSelector(state => state.languageReducer.lang);
    const __ = key => translate(lang, key);
    
    const arr = [
        { id: 1, title: __("Retrieve Users Info"), isChecked: false },
        { id: 2, title: __("Update Users Info"), isChecked: true },
        { id: 3, title: __("Create Users Info"), isChecked: false },
        { id: 4, title: __("Delete Users Info"), isChecked: true },
    ];


    return (
        <div className="page component-page-view">
            <div className="page-view-inner">
                <PageHeader name={__("Create Role")} link="/user/role" text={__("Back")} />
                <div className="cards-inner-view">
                    <div className="component-title">{__("Title")}</div>
                    <Input className="card-input" placeholder={__("Role Title")} />
                    <div className="component-title">{__("Permissions")}</div>

                    {arr.map((item) => (
                        <div className="component-wrap" key={item.id}>
                            <CheckBox id={item.id} isChecked={item.isChecked} />
                            <div className="component-text">{item.title}</div>
                        </div>
                    ))}

                    <div className="some">
                        <Button
                            text={__("Submit")}
                            className="btn lg"
                            type="button"
                            onClick={() => console.log("")}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Role;
