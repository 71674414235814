import { SET_WAREHOUSE_STATUS } from "../actionTypes/warehouseActionTypes";

const initialState = {
    warehouseStatus: false
};
const warehouseReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_WAREHOUSE_STATUS:
            return {...state, warehouseStatus: action.payload};
        default:
            return state;
    }
};
export default warehouseReducer;
