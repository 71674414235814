export const mockReportDataTeam = [
    {
        id: 1,
        ReportNumber: "0",
        chequesQuantity: "0",
        cashdeskId: "uz12345678901012",
        closedAt: "21/01/2000",
        totalDebited: "0",
        totalRefund: "0",
        owner: "Avatar",
        ownerName: "Gulsanam",
        status: "Active",
        action: "Delete",
    },
    {
        id: 2,
        ReportNumber: "0",
        chequesQuantity: "0",
        cashdeskId: "uz12345678901012",
        closedAt: "21/01/2000",
        totalDebited: "0",
        totalRefund: "0",
        owner: "Avatar",
        ownerName: "Gulsanam",
        status: "Active",
        action: "Delete",
    },
    {
        id: 3,
        ReportNumber: "0",
        chequesQuantity: "0",
        cashdeskId: "uz12345678901012",
        closedAt: "21/01/2000",
        totalDebited: "0",
        totalRefund: "0",
        owner: "Avatar",
        ownerName: "Gulsanam",
        status: "Active",
        action: "Delete",
    },
    {
        id: 4,
        ReportNumber: "0",
        chequesQuantity: "0",
        cashdeskId: "uz12345678901012",
        closedAt: "21/01/2000",
        totalDebited: "0",
        totalRefund: "0",
        owner: "Avatar",
        ownerName: "Gulsanam",
        status: "Active",
        action: "Delete",
    },
    {
        id: 5,
        ReportNumber: "0",
        chequesQuantity: "0",
        cashdeskId: "uz12345678901012",
        closedAt: "21/01/2000",
        totalDebited: "0",
        totalRefund: "0",
        owner: "Avatar",
        ownerName: "Gulsanam",
        status: "Active",
        action: "Delete",
    },
];