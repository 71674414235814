import { Outlet } from "react-router-dom";
import "./styles.sass";

const LayoutEndpoint = ( { ...props } ) => {
    return (
        <div { ...props } className="layout layout-endpoint">
            <Outlet />
        </div>
    );
};

export default LayoutEndpoint;