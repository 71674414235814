import jwt_decode from "jwt-decode";
export function isTokenExpired(token) {
      let decodedToken;
      if(token) {
        decodedToken = jwt_decode(token);
        
        const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds

        return decodedToken.exp < currentTime;
      } else {
        return true;
      }
}