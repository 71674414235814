import {
    CREATE_USER_FAIL,
    CREATE_USER_START,
    CREATE_USER_SUCCESS,
} from "../actionTypes/createUserActionTypes";
import { createReducer } from "../store/helper";

const initialState = {
    data: null,
    error: null,
    loading: false,
};

const createUserReducer = {
    [CREATE_USER_SUCCESS]: (state = initialState, action) => {
        return {
            ...state,
            data: action.payload,
            error: null,
            loading: false,
        };
    },

    [CREATE_USER_FAIL]: (state = initialState, action) => {
        return {
            ...state,
            error: action.payload,
            loading: false,
            data: null,
        };
    },
    [CREATE_USER_START]: (state = initialState) => {
        return { ...state, data: null, error: null, loading: true };
    },
};

export default createReducer(initialState, createUserReducer);
